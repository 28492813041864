import {EMPTY_ERROR_MESSAGE, GENERAL_ERROR_MESSAGES} from '../../../constants/errors';
import {ISourceCode} from '../../../store/objects/sourceCodes/sourceCodesTypes';
import {
    setSourceCodeNumberRowsError,
    setSourceCodeInfSystemError,
    setSourceNameError,
} from '../../../store/objects/objectsSlice';

/**
 * return is the data valid
 * @param {ISourceCode} object  Validation data
 * @param {any} dispatch        Dispatcher to change values in the store
 * @return {boolean}            Is the data valid
 */
export const validateSourceCode = (
    object: ISourceCode,
    dispatch: any,
): boolean => {
    let isNumberRowsCorrect;

    const isInfSystemError = object.inf_system?.id != '';
    const isNameCorrect = object.name !== '';

    if(isNameCorrect) {
        dispatch(setSourceNameError(''));
    }else {
        dispatch(setSourceNameError(GENERAL_ERROR_MESSAGES.FIELD_EMPTY));
    }


    if(isInfSystemError){
        dispatch(setSourceCodeInfSystemError(''));
    }else {
        dispatch(setSourceCodeInfSystemError(GENERAL_ERROR_MESSAGES.FIELD_EMPTY));
    }

    if (object.number_rows && object.number_rows < 0) {
        dispatch(setSourceCodeNumberRowsError(GENERAL_ERROR_MESSAGES.NUMBER_NEGATIVE));

        isNumberRowsCorrect = false;
    } else {
        dispatch(setSourceCodeNumberRowsError(EMPTY_ERROR_MESSAGE));

        isNumberRowsCorrect = true;
    }

    return (isNumberRowsCorrect && isInfSystemError && isNameCorrect);
};
