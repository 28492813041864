import {FC, useEffect, useState} from 'react';

import classNames from 'classnames';

import {useParams} from 'react-router-dom';

import Modal from '../Modal';
import {IModalProps} from '../ModalTypes';
import InputForm from '../../inputForm/InputForm';
import styles from '../Modal.module.scss';
import Button from '../../button/Button';
import {InputTypeEnum} from '../../input/InputTypes';
import {useShowPopup} from '../../../hooks/useShowPopup';
import {useAppDispatch} from '../../../hooks/useAppDispatch';
import {useAppSelector} from '../../../hooks/useAppSelector';
import ConfirmModal from '../general/confirm/ConfirmModal';
import {ModalTypes} from '../general/confirm/ConfirmTypes';
import {ButtonTypeEnum} from '../../button/ButtonTypes';
import {IInfSystem} from '../../../store/infSystems/infSystemsTypes';
import {createInfSystem, getAllInfSystems} from '../../../store/infSystems/infSystemsAsync';


import {infSystemSecurityLevelPopupItems, prepareSecurityLevelToEng} from '../../../constants/infSystems';
import {validateInfSystem} from '../../../utils/validate/groups/validateInfSystem';
import {selectInfSystemErrors, selectInfSystems} from '../../../store/infSystems/infSystemsSelectors';

import {localization} from '../../../localization/localization';

import {IPopupItem} from '../../popup/PopupTypes';

import {resetInfSystemData} from './utils/resetInfSystemData';

const CreateInfSystem: FC<IModalProps> = ({ isModalVisible, setModalVisible }) => {
    const dispatch = useAppDispatch();

    const { customerId } = useParams();

    const { allInfSystems } = useAppSelector(selectInfSystems);

    const { showPopupHandler } = useShowPopup();

    const {
        inf_system_name_error,
        web_interface_address_error,
        inf_system_security_level_error,
        product_error,
        product_manager_error,
        inf_system_contact_person_error,
    } = useAppSelector(selectInfSystemErrors);

    const [infSystem, setInfSystem] = useState<IInfSystem>({
        additional_info: '',
        availability_interface: false,
        inf_system_contact_person: '',
        name: '',
        security_level: '',
        product: '',
        product_manager: '',
        web_interface_address: '',
    });

    const [isCreateInfSystemModal, setCreateInfSystemModal] = useState<boolean>(false);
    const [isResetInfSystemDataModal, setResetInfSystemDataModal] = useState<boolean>(false);

    useEffect(() => {
        if (customerId && allInfSystems.length === 0) {
            dispatch(getAllInfSystems({ id: customerId }));
        }
    }, [dispatch, customerId, allInfSystems.length]);

    const addInfSystemHandler = () => {
        const isValidate = validateInfSystem(infSystem, dispatch, allInfSystems);

        if (isValidate && customerId) {
            infSystem.security_level = prepareSecurityLevelToEng[infSystem.security_level];

            dispatch(createInfSystem({ infSystem, customerId }));

            resetInfSystemData(dispatch, setInfSystem);

            setModalVisible(false);
        }
    };

    const resetInfSystemDataHandler = () => resetInfSystemData(dispatch, setInfSystem);

    const onSecurityLevelChange = (item: IPopupItem) => setInfSystem({ ...infSystem, security_level: item.text || '' });
    const onConfirmCreateModalHandler = () => setCreateInfSystemModal(prevState => !prevState);
    const onConfirmResetModalHandler = () => setResetInfSystemDataModal(prevState => !prevState);

    return (
        <>
            <Modal
                isModalVisible={isModalVisible}
                setModalVisible={setModalVisible}
                title={localization.modals.infSystem.createTitle}
                isFullScreen={true}
            >
                <div className={styles['modal-inputs']}>
                    <InputForm
                        text={localization.modals.infSystem.nameText}
                        placeholder={localization.modals.infSystem.namePlaceholder}
                        errorMessage={inf_system_name_error}
                        value={infSystem.name}
                        onChange={(event) => {
                            setInfSystem({...infSystem, name: event.target.value});
                        }}
                        required
                    />
                    <InputForm
                        text={localization.modals.infSystem.webInterfaceText}
                        placeholder={localization.modals.infSystem.webInterfacePlaceholder}
                        errorMessage={web_interface_address_error}
                        value={infSystem.web_interface_address}
                        onChange={(event) => {
                            setInfSystem({...infSystem, web_interface_address: event.target.value});
                        }}
                        required
                    />

                    <InputForm
                        text={localization.modals.infSystem.productText}
                        placeholder={localization.modals.infSystem.productPlaceholder}
                        errorMessage={product_error}
                        value={infSystem.product}
                        onChange={(event) => {
                            setInfSystem({...infSystem, product: event.target.value});
                        }}
                        required
                    />
                    <InputForm
                        text={localization.modals.infSystem.productManagerText}
                        placeholder={localization.modals.infSystem.productManagerPlaceholder}
                        errorMessage={product_manager_error}
                        value={infSystem.product_manager}
                        onChange={(event) => {
                            setInfSystem({...infSystem, product_manager: event.target.value});
                        }}
            
                    />
                    <InputForm
                        text={localization.modals.infSystem.contactPersonText}
                        placeholder={localization.modals.infSystem.contactPersonPlaceholder}
                        errorMessage={inf_system_contact_person_error}
                        value={infSystem.inf_system_contact_person}
                        onChange={(event) => {
                            setInfSystem({...infSystem, inf_system_contact_person: event.target.value});
                        }}
            
                    />
                    <InputForm
                        text={localization.modals.additionalInfoText}
                        placeholder={localization.modals.additionalInfoPlaceholder}
                        value={infSystem.additional_info}
                        onTextareaChange={(event) => {
                            setInfSystem({...infSystem, additional_info: event.target.value});
                        }}
                        textarea
                    />

                </div>
                <div className={classNames(styles['modal-buttons'], styles['modal-buttons_between'])}>
                    <Button buttonText={localization.modals.createButtonText} onClick={onConfirmCreateModalHandler} />
                    <Button
                        type={ButtonTypeEnum.Red}
                        buttonText={localization.modals.resetButtonText}
                        onClick={onConfirmResetModalHandler}
                    />
                </div>
            </Modal>
            <ConfirmModal
                isModalVisible={isCreateInfSystemModal}
                setModalVisible={setCreateInfSystemModal}
                text={localization.infSystem.confirmText}
                onConfirmClick={addInfSystemHandler}
                type={ModalTypes.Create}
            />
            <ConfirmModal
                isModalVisible={isResetInfSystemDataModal}
                setModalVisible={setResetInfSystemDataModal}
                text={localization.infSystem.confirmDataText}
                onConfirmClick={resetInfSystemDataHandler}
                type={ModalTypes.Reset}
            />
        </>
    );
};

export default CreateInfSystem;
