import cnb from 'classnames/bind';
import { ComponentProps, Dispatch, FC, SetStateAction, useEffect, useState } from 'react';

import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { addVulnInKillChainAsync, getKillchainList } from '../../../store/killchains/killchainsAsync';
import { selectKillchain } from '../../../store/killchains/killchainsSelectors';
import Button from '../../button/Button';
import Modal from '../Modal';

import style from './AddVulnInKillChain.module.scss';


interface IAddVulnInKillChainProps extends ComponentProps<'div'> {
  isModalVisible: boolean;
  setModalVisible: Dispatch<SetStateAction<boolean>>
  projectId: string;
  vulnId: string;
  killChainId?: string;
}

export const AddVulnInKillChainModal: FC<IAddVulnInKillChainProps> = (props) => {
  const {
    isModalVisible,
    setModalVisible,
    projectId,
    killChainId,
    vulnId,
    ...otherProps
  } = props;


  const dispatch = useAppDispatch();


  const cx = cnb.bind(style);


  const { killChainList } = useAppSelector(selectKillchain);


  const [activeId, setActiveId] = useState('');

  useEffect(() => {
    killChainId && setActiveId(killChainId);
  }, [killChainId]);

  useEffect(() => {
    if (projectId) dispatch(getKillchainList({ project_id: projectId }));
  }, [dispatch, projectId]);


  const onClickHandler = (id: string) => {
    setActiveId(id);
  };

  const onClickSubmitHandler = () => {
    dispatch(addVulnInKillChainAsync({
      projectId,
      killChainId: activeId,
      vulnerabilitysIds: [vulnId],
    }));
    setModalVisible(false);
  };

  return (
    <Modal
      title="Добавить уязвимость в killChain"
      isModalVisible={isModalVisible}
      setModalVisible={setModalVisible}
    >
      <div className={cx('items-group')} {...otherProps}>
        {

          killChainList?.data.map((killChain) => (
            <button
              key={killChain.id}
              className={
                cx(
                  'item',
                  { 'item-active': activeId === killChain.id },
                )
              }
              onClick={() => {
                onClickHandler(killChain.id);
              }}
            >
              {killChain.name}
            </button>
          ))
        }
        {

        }
      </div>
      <div className={cx('button-group')}>
        <Button buttonText={'Сброс'} onClick={() =>
          setActiveId('')
        } />
        <Button buttonText={'Добавить'} onClick={onClickSubmitHandler} dissable={activeId === ''} />
      </div>


    </Modal>
  );
};
