import cn from 'classnames';

import style from './Spinner.module.scss';

export const  Spinner = () => {
    return (
        <div>
            <div className={cn(style['Spinner'])}></div>
        </div>
    );
};
