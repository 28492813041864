import { createAsyncThunk } from '@reduxjs/toolkit';

import { IEntityResponse, IGetOptionalRequest, IThunkApiConfigProps } from '../storeTypes';

import { CUSTOMER_ROUTES } from '../../constants/customer';

import {
  CustomerTypes,
  ICustomer,
  ICustomerRequest,
  ICustomerResponse,
  IDeleteCustomerResponse,
  IGetCustomerResponse,
} from './customersTypes';

export const getCustomers = createAsyncThunk<IGetCustomerResponse, IGetOptionalRequest, IThunkApiConfigProps>(
  CustomerTypes.GET,
  async ({ filters, pagination, sortParams }, { rejectWithValue, extra }) => {
    try {
      const paginationString = pagination ? `offset=${pagination.offset}&limit=${pagination.limit}` : '';
      const response = await extra.api.get(
        `${CUSTOMER_ROUTES.FULL_URL}/?${paginationString}${filters ? `&${filters}` : ''}&${sortParams ? sortParams : ''}`,
      );

      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.status);
    }
  },
);

export const getAllCustomers = createAsyncThunk<IGetCustomerResponse, IGetOptionalRequest, IThunkApiConfigProps>(
  CustomerTypes.GET_ALL,
  async ({ filters }, { rejectWithValue, extra }) => {
    try {
      const response = await extra.api.get(
        `${CUSTOMER_ROUTES.FULL_URL}/${filters ? `?${filters}` : ''}`,
      );

      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.status);
    }
  },
);

export const getCustomerById = createAsyncThunk<ICustomer, string, IThunkApiConfigProps>(
  CustomerTypes.GET_ONE,
  async (id, { rejectWithValue, extra }) => {
    try {
      const response = await extra.api.get(`${CUSTOMER_ROUTES.FULL_URL}/${id}`,
      );

      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.status);
    }
  },
);

export const restoreCustomer = createAsyncThunk<IEntityResponse, string, IThunkApiConfigProps>(
  CustomerTypes.RESTORE,
  async (id, { rejectWithValue, extra }) => {
    try {
      const response = await extra.api.get(
        `${CUSTOMER_ROUTES.FULL_URL}/${id}/${CUSTOMER_ROUTES.RESTORE}`,
      );

      return { status: response.status };
    } catch (error: any) {
      return rejectWithValue(error.response.status);
    }
  },
);

export const createCustomer = createAsyncThunk<ICustomerResponse, ICustomer, IThunkApiConfigProps>(
  CustomerTypes.POST,
  async (customer: ICustomer, { rejectWithValue, extra }) => {
    try {
      const response = await extra.api.post(`${CUSTOMER_ROUTES.FULL_URL}/`, customer);

      return { customer: response.data, status: response.status };
    } catch (error: any) {
      return rejectWithValue(error.response.status);
    }
  },
);

export const deleteCustomer = createAsyncThunk<IDeleteCustomerResponse, string, IThunkApiConfigProps>(
  CustomerTypes.DELETE,
  async (id: string, { rejectWithValue, extra }) => {
    try {
      const response = await extra.api.delete(`${CUSTOMER_ROUTES.FULL_URL}/${id}`);

      return { id, status: response.status };
    } catch (error: any) {
      return rejectWithValue(error.response.status);
    }
  },
);

export const changeCustomer = createAsyncThunk<ICustomerResponse, ICustomerRequest, IThunkApiConfigProps>(
  CustomerTypes.PATCH,
  async ({ customer, customerId }, { rejectWithValue, extra }) => {
    try {
      const response = await extra.api.patch(
        `${CUSTOMER_ROUTES.FULL_URL}/${customerId}`, customer);

      return { customer: response.data, status: response.status };
    } catch (error: any) {
      return rejectWithValue(error.response.status);
    }
  },
);
