import {IBaseObject, IUrlData} from './locationPathTypes';

export  const parseUrl = (
    location: string,
    projectName?: string,
    base?: IBaseObject[],
    vulnName?: string | null,
    officeName?: string,
    customerName?: string,
    infSystemName?: string,
):IUrlData[]  => {

    const url = new URL(location);
    const urlArr = url.pathname.split('/').filter((path) => path != '');
    const result: any[] = [];
    let currentUrl = '';
    let i = 0;
    while (i < urlArr.length){
        if(urlArr[i] === 'projects'){
            currentUrl += '/' + urlArr[i];
            result.push({
                name: 'Проекты',
                value: urlArr[i],
                url: currentUrl,
            });
            if(urlArr[i+1] !== undefined){
                currentUrl += '/' + urlArr[i+1];
                result.push({
                    name: `Проект:  (${projectName ? projectName : urlArr[i+1]})`,
                    value: urlArr[i+1],
                    url: currentUrl,
                });
                result.push();
                i +=  2;
            }
            else {
                i += 1;
            }

        }
        else if (urlArr[i] === 'objects'){
            currentUrl += '/' + urlArr[i];
            result.push({
                name: 'Объекты',
                value: urlArr[i],
                url: currentUrl,
            });
            if(urlArr[i+2] !== undefined){
                const currentObject = base?.filter(obj => obj.id === urlArr[i+2])[0];
                const objectIdentifier = currentObject?.object_identifier ? currentObject?.object_identifier : '-';
                const objectIdentifierAdditional = currentObject?.object_identifier_additional ? currentObject?.object_identifier_additional : '-';
                const objectName =  `Объект: (${objectIdentifier} | ${objectIdentifierAdditional})`;
                currentUrl += '/' + urlArr[i+1];
                currentUrl += '/' + urlArr[i+2];
                result.push({
                    name: objectName,
                    value: urlArr[i+2],
                    url: currentUrl,
                });
                result.push();
                i +=  3;
            }
            else {
                i += 1;
            }
        }
        else if (urlArr[i] === 'vulns'){
            currentUrl += '/' + urlArr[i];
            result.push({
                name: 'Уязвимости',
                value: urlArr[i],
                url: currentUrl,
            });

            if(urlArr[i+1] !== undefined){
                currentUrl += '/' + urlArr[i+1];
                result.push({
                    name: `Уязвимость: (${vulnName ? vulnName: urlArr[i+1]})`,
                    value: urlArr[i+1],
                    url: currentUrl,
                });
                result.push();
                i +=  2;
            }
            else {
                i += 1;
            }
        }
        else if (urlArr[i] === 'customers'){
            currentUrl += '/' + urlArr[i];
            result.push({
                name: 'Заказчики',
                value: urlArr[i],
                url: currentUrl,
            });

            if(urlArr[i+1] !== undefined){
                currentUrl += '/' + urlArr[i+1];
                result.push({
                    name: `Заказчик: (${customerName ? customerName: urlArr[i+1]})`,
                    value: urlArr[i+1],
                    url: currentUrl,
                });
                result.push();
                i +=  2;
            }

            else {
                i += 1;
            }
        }
        else if (urlArr[i] === 'infSystems'){
            currentUrl += '/' + urlArr[i];
            result.push({
                name: 'Инф. системы',
                value: urlArr[i],
                url: currentUrl,
            });

            if(urlArr[i+1] !== undefined){
                currentUrl += '/' + urlArr[i+1];
                result.push({
                    name: `Инф. система: (${infSystemName ? infSystemName: urlArr[i+1]})`,
                    value: urlArr[i+1],
                    url: currentUrl,
                });
                result.push();
                i +=  2;
            }

            else {
                i += 1;
            }
        }
        else if (urlArr[i] === 'offices'){
            currentUrl += '/' + urlArr[i];
            result.push({
                name: 'Офисы',
                value: urlArr[i],
                url: currentUrl,
            });

            if(urlArr[i+1] !== undefined){
                currentUrl += '/' + urlArr[i+1];
                result.push({
                    name: `Офис: (${officeName ? officeName: urlArr[i+1]})`,
                    value: urlArr[i+1],
                    url: currentUrl,
                });
                result.push();
                i +=  2;
            }

            else {
                i += 1;
            }
        }
        else {i++;}
    }
    return result;
};
