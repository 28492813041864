import { FC, useState } from 'react';

import classNames from 'classnames';

import Text from '../text/Text';
import { TextVariantEnum } from '../text/TextTypes';

import { IItemProps } from './PageItemTypes';
import styles from './PageItem.module.scss';

const PageItem: FC<IItemProps> = ({
  title,
  isTextCropped,
  textSize,
  text,
  isFirst,
  className,
  classNameTitle,
  classNameText,
}) => {
  const [isHidden, setIsHidden] = useState<boolean>(true);

  return (
    <div className={classNames(styles['project-item'], className, { [styles['project-item_active']]: isFirst })}>
      <Text variant={TextVariantEnum.XS} className={classNames(styles['project-item-title'], classNameTitle)}>
        {title}
      </Text>
      <Text variant={TextVariantEnum.M} className={classNames(styles['project-item-text'], classNameText)}>
        {isTextCropped && typeof text === 'string' && textSize && text.split(' ').length > textSize
          ? (isHidden
            ? text.split(' ').slice(0, textSize).join(' ') + '. . .'
            : text
          )
          : text
        }
      </Text>
      {
        typeof text === 'string' && isTextCropped && textSize && text.split(' ').length > textSize &&
        <button className={styles['project-item-button']} onClick={() => setIsHidden(!isHidden)}>
          {isHidden ? 'Показать полностью' : 'Скрыть'}
        </button>
      }
    </div>
  );
};

export default PageItem;
