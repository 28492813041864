import { createSlice } from "@reduxjs/toolkit"

import { isError } from "../storeHelpers"
import { ROUTES } from "../../router/routes"


import {
  getLoginHistory,
  getReportHistory,
  getUserInfo,
  login,
  logout,
} from "./authAsync"
import { IAuthState } from "./authTypes"

const access_token = localStorage.getItem("token") || null

const initialState: IAuthState = {
  authData: {
    access_token: access_token,
    token_type: null,
    isLoading: false,
    error: null,
    status: null,
  },
  profileData: {
    id: "",
    email: "",
    is_active: false,
    is_superuser: false,
    is_verified: false,
    first_name: "",
    role: "",
  },
  loginHistory: {
    data: [],
    count: 0,
    isLoaded: false,
  },
  reportHistory: {
    data: [],
    count: 0,
    isLoaded: false,
  },
  errors: {
    username_error: "",
    password_error: "",
  },
  isAuth: false,
}

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUsernameError(state, action) {
      state.errors.username_error = action.payload
    },
    setPasswordError(state, action) {
      state.errors.password_error = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.fulfilled, (state, action) => {
        state.authData.isLoading = false
        state.authData.access_token = action.payload.access_token
        state.authData.token_type = action.payload.token_type
        state.authData.status = 203

        localStorage.setItem("token", action.payload.access_token!)
        window.location.pathname = ROUTES.COMMON
      })
      .addCase(login.pending, (state) => {
        state.authData.isLoading = true
        state.authData.error = null
        state.authData.status = null
      })
      .addCase(logout.fulfilled, (state) => {
        state.authData.isLoading = false
        state.authData.access_token = null
        state.authData.status = 205
        localStorage.removeItem("token")
      })
      .addCase(logout.pending, (state) => {
        state.authData.isLoading = true
        state.authData.error = null
        state.authData.status = null
      })
      .addCase(getUserInfo.fulfilled, (state, action) => {
        state.profileData = action.payload
      })
      .addCase(getLoginHistory.pending, (state) => {
        state.loginHistory = {
          data: [],
          count: 0,
          isLoaded: false,
        }
      })
      .addCase(getLoginHistory.fulfilled, (state, action) => {
        state.loginHistory.data = action.payload.data.map((el) => {
          return {
            ip_address: el.ip_address,
            status: el.status ? "Успешно" : "Неуспешно",
            created_at: el.created_at.toLocaleString(),
          }
        })
        state.loginHistory.count = action.payload.count
        state.loginHistory.isLoaded = true
      })
      .addCase(getReportHistory.pending, (state) => {
        state.reportHistory = {
          data: [],
          count: 0,
          isLoaded: false,
        }
      })
      .addCase(getReportHistory.fulfilled, (state, action) => {
        state.reportHistory.data = action.payload.data
        state.reportHistory.count = action.payload.count
        state.reportHistory.isLoaded = true
      })
      .addMatcher(isError, (state, action) => {
        state.authData.error = action.payload
        state.authData.status = action.payload
        state.authData.isLoading = false
      })
  },
})

export const { setUsernameError, setPasswordError } = authSlice.actions

export default authSlice.reducer
