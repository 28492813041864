import { ComponentProps, FC, useState } from "react"

import styles from "./CollapseText.module.scss"

import cnb from "classnames/bind"

interface ICollapseTextProps extends ComponentProps<"p"> {
  visibleText: string
  hiddenText: string
  children: string
}

export const CollapseText: FC<ICollapseTextProps> = (props) => {
  const { children, title, hiddenText, visibleText, ...otherProps } = props
  const [isTextHidden, setIsTextHidden] = useState(true)

  const cx = cnb.bind(styles)

  return (
    <p onClick={() => setIsTextHidden(current => !current)} {...otherProps}>
      {
        isTextHidden
          ? <span className={cx("link-text")}> {hiddenText}</span>
          : <>
            <span>{children}</span>
            <br />
            <span className={cx("link-text")}>{visibleText}</span>
          </>}
    </p >
  )
}