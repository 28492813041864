import { ChangeEvent, FC, useEffect, useState } from 'react';

import classNames from 'classnames';


import Modal from '../Modal';
import { IModalProps } from '../ModalTypes';
import InputForm from '../../inputForm/InputForm';
import styles from '../Modal.module.scss';
import Button from '../../button/Button';
import { InputTypeEnum } from '../../input/InputTypes';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { IPopupItem } from '../../popup/PopupTypes';
import { selectVulnErrors, selectVulns } from '../../../store/vulns/vulnsSelectors';
import { useShowPopup } from '../../../hooks/useShowPopup';
import { ICreateVulnSendData, IVuln } from '../../../store/vulns/vulnsTypes';
import { validateVuln } from '../../../utils/validate/validateVuln';
import { createVuln } from '../../../store/vulns/vulnsAsync';
import {
  attackComplexityPopupItems,
  attackVectorPopupItems,
  availabilityPopupItems,
  confidentialityPopupItems,
  integrityPopupItems,
  negativeConsequencesTypes,
  prepareRiskLevelToRu,
  privilegesRequiredPopupItems,
  scopePopupItems,
  userInteractionPopupItems,
} from '../../../constants/vulns';
import ConfirmModal from '../general/confirm/ConfirmModal';
import { ModalTypes } from '../general/confirm/ConfirmTypes';
import { ButtonTypeEnum } from '../../button/ButtonTypes';

import Text from '../../text/Text';
import Title from '../../title/Title';

import { TextVariantEnum } from '../../text/TextTypes';

import { TitleVariantEnum } from '../../title/TitleTypes';

import { localization } from '../../../localization/localization';

import { resetVulnData } from './utils/resetVulnData';

import { calculateCvss } from './utils/calculateCvss';
import cn from "classnames";
import style from "../applyTemplates/ApplyTemplates.module.scss";
import { NavButton } from "../../../shared";
import { ImageCard } from "../../../entities/ui/imageCard";
import {
  deleteScreenshot,
  setScreenshotBase64Data,
  setScreenshotDescription,
  setTemplateScreenshot,
  resetTemplateScreenshots,
  setScreenshotSequence
} from "../../../store/vulns/vulnsSlice";
import { IScreenshotDescriptions } from "../applyTemplates/applyTemplatesTypes";




interface IModalVulnProps extends IModalProps {
  objectType?: string;
  isCreateKillChain?: boolean;
  projectId?: string;
  objectId?: string;
}


const CreateVuln: FC<IModalVulnProps> = ({
  isModalVisible,
  setModalVisible,
  objectType,
  isCreateKillChain,
  objectId = 'killchain',
  projectId,
}) => {
  const dispatch = useAppDispatch();

  const { allVulns, vulns, newVulnId, templateScreenshots } = useAppSelector(selectVulns);

  const { showPopupHandler } = useShowPopup();
  const {
    location_error,
    vulnerability_name_error,
    description_error,
    cause_of_occurrence_error,
    procedure_exploiting_error,
    recommendation_error,
    negative_consequences_error,
    cve_id_error,

    attack_vector_error,
    attack_complexity_error,
    privileges_required_error,
    user_interaction_error,
    integrity_error,
    availability_error,
    confidentiality_error,
    scope_error,
  } = useAppSelector(selectVulnErrors);


  const [vuln, setVuln] = useState<IVuln>({
    additional_info: '',
    cvss_score: 0,
    cvss_vector: '',
    description: '',
    cause_of_occurrence: '',
    location: '',
    name: '',
    negative_consequences: [],
    procedure_exploiting: '',
    recommendations: '',
    risk_level: '',
  });

  const [cvssAV, setCvssAV] = useState<IPopupItem>({
    text: '',
    value: '',
  });
  const [cvssAC, setCvssAC] = useState<IPopupItem>({
    text: '',
    value: '',
  });
  const [cvssPR, setCvssPR] = useState<IPopupItem>({
    text: '',
    value: '',
  });
  const [cvssUI, setCvssUI] = useState<IPopupItem>({
    text: '',
    value: '',
  });
  const [cvssS, setCvssS] = useState<IPopupItem>({
    text: '',
    value: '',
  });
  const [cvssC, setCvssC] = useState<IPopupItem>({
    text: '',
    value: '',
  });
  const [cvssI, setCvssI] = useState<IPopupItem>({
    text: '',
    value: '',
  });
  const [cvssA, setCvssA] = useState<IPopupItem>({
    text: '',
    value: '',
  });

  const [cveId, setCveId] = useState<string>('');
  const [cweId, setCweId] = useState<string>('');

  const [screenshots, setScreenshots] = useState<FileList | any[]>();
  const [selectedNegativeOption, setSelectedNegativeOption] = useState<any>(null);

  const [isCreateVulnModal, setCreateVulnModal] = useState<boolean>(false);
  const [isResetVulnDataModal, setResetVulnDataModal] = useState<boolean>(false);
  const [isVulnsLoading] = useState<boolean>(false);
  const [isModalCorrect, setIsModalCorrect] = useState<boolean>(false);
  const [isActiveTemplateTab, setIsActiveTemplateTab] = useState(true);

  useEffect(() => {

    if (screenshots && isModalCorrect && projectId && objectId && newVulnId !== '') {
      const data = new FormData();

      Array.from(screenshots).forEach((screenshot) => {
        data.append('files', screenshot, screenshot.name);

      });
      //dispatch(uploadVulnScreenshots({ files: data, vulnId: newVulnId, projectId, objectId, objectType: prepareObjectType }));

      setVuln({
        ...vuln,
        name: '',
      });
      setScreenshots([]);

      setIsModalCorrect(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, objectId, projectId, vulns, isVulnsLoading]);

  useEffect(() => {
    calculateCvss(vuln, setVuln, cvssAV, cvssAC, cvssPR, cvssUI, cvssS, cvssC, cvssI, cvssA);
  }, [cvssAV, cvssAC, cvssPR, cvssUI, cvssS, cvssC, cvssI, cvssA]);
  const addVulnHandler = () => {
    const cvssItems = [cvssAV, cvssAC, cvssPR, cvssUI, cvssS, cvssC, cvssI, cvssA];

    const isValidate = validateVuln(vuln, dispatch, cvssItems, allVulns, cveId, cweId);

    if (isValidate) {
      const screenshotsData: any[] = []
      if (Object.keys(templateScreenshots).length > 0) {
        for (const screenName in templateScreenshots[objectId]) {
          screenshotsData.push({
            description: templateScreenshots[objectId][screenName].description,
            imgbase64: templateScreenshots[objectId][screenName].base64data.split(',')[1],
            sequence: templateScreenshots[objectId][screenName].sequence
          })
        }
      }
      setIsModalCorrect(true);
      const sendVulnData: ICreateVulnSendData = {
        additional_info: vuln.additional_info || "",
        cause_of_occurrence: vuln.cause_of_occurrence || "",
        cvss_score: typeof vuln.cvss_score === 'number' ? vuln.cvss_score : 0,
        cvss_vector: vuln.cvss_vector || "",
        description: vuln.description || "",
        location: vuln.location || "",
        negative_consequences: vuln.negative_consequences?.join(',') || '',
        procedure_exploiting: vuln.procedure_exploiting || "",
        recommendations: vuln.recommendations || "",
        risk_level: vuln.risk_level || "",
        screenshots: screenshotsData || [],
        status: "new",
        name: vuln.name || '',
        cve_id: cveId.split(', ').map(id => id.trim()),
        cwe_id: cweId.split(', ').map(id => id.trim()),
      };
      if (!isCreateKillChain) {
        console.log('KILLCHAIN CREATED')
      }
      if (objectType && objectId && projectId) {
        dispatch(createVuln({ objectId, projectId, objectType: objectType || '', vuln: sendVulnData }));
      }

      if (!screenshots) {
        setVuln({
          ...vuln,
          additional_info: '',
          cvss_score: 0,
          cvss_vector: '',
          description: '',
          cause_of_occurrence: '',
          location: '',
          negative_consequences: [],
          procedure_exploiting: '',
          recommendations: '',
          risk_level: '',
          name: '',
        });
      } else if (screenshots) {
        setVuln({
          ...vuln,
          additional_info: '',
          cvss_score: 0,
          cvss_vector: '',
          description: '',
          cause_of_occurrence: '',
          location: '',
          negative_consequences: [],
          procedure_exploiting: '',
          recommendations: '',
          risk_level: '',
        });
      }

      setCveId('');
      setCweId('');

      setCvssAV({
        text: '',
        value: '',
      });
      setCvssAC({
        text: '',
        value: '',
      });
      setCvssPR({
        text: '',
        value: '',
      });
      setCvssUI({
        text: '',
        value: '',
      });
      setCvssS({
        text: '',
        value: '',
      });
      setCvssC({
        text: '',
        value: '',
      });
      setCvssI({
        text: '',
        value: '',
      });
      setCvssA({
        text: '',
        value: '',
      });

      setModalVisible(false);
    }
  };

  const resetVulnDataHandler = () => {
    resetVulnData(
      dispatch,
      setVuln,
      setCvssAV,
      setCvssAC,
      setCvssPR,
      setCvssUI,
      setCvssS,
      setCvssC,
      setCvssI,
      setCvssA,
      setCveId,
      setCweId,
    );
  };

  const onCvssAVPopupItemChange = (item: IPopupItem) => setCvssAV(item);
  const onCvssACPopupItemChange = (item: IPopupItem) => setCvssAC(item);
  const onCvssPRPopupItemChange = (item: IPopupItem) => setCvssPR(item);
  const onCvssUIPopupItemChange = (item: IPopupItem) => setCvssUI(item);
  const onCvssSPopupItemChange = (item: IPopupItem) => setCvssS(item);
  const onCvssCPopupItemChange = (item: IPopupItem) => setCvssC(item);
  const onCvssIPopupItemChange = (item: IPopupItem) => setCvssI(item);
  const onCvssAPopupItemChange = (item: IPopupItem) => setCvssA(item);

  const onNegativeConsequencesChange = (data: any) => {
    const prepareNegativeConsequences = data.map(({ value }: any) => value);

    setVuln({ ...vuln, negative_consequences: prepareNegativeConsequences });
    setSelectedNegativeOption(data);
  };


  const onConfirmCreateModalHandler = () => setCreateVulnModal(prevState => !prevState);
  const onConfirmResetModalHandler = () => setResetVulnDataModal(prevState => !prevState);

  const handleScreenshotDescription = (e: ChangeEvent<HTMLTextAreaElement>, fileName: string | null) => {
    if (fileName !== null && objectId) {
      dispatch(setScreenshotDescription({ description: e.target.value, fileName, objectId: objectId }));
    }
  };

  const getObjectScreenshotData = (files: FileList) => {
    const screenshotsList = [];
    const descriptions: IScreenshotDescriptions = {};
    for (let i = 0; i < files.length; i++) {
      const file = files.item(i);
      const fileName = file?.name ? Date.now() + '_' + file.name : '';
      const currentScrennshotObject = { file: file, fileName: fileName };
      screenshotsList.push(currentScrennshotObject);
      descriptions[fileName] = ' ';
    }
    return {
      screenshotsList: screenshotsList,
      descriptions: descriptions,
    };
  };
  const handleDeleteScreenshot = (fileName: string) => {
    dispatch(deleteScreenshot({ objectId: objectId, fileName }));
  };

  function readFile(file: File, objectId: string, fileName: string) {

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function() {

      const dataBase64 = reader.result;
      if (typeof dataBase64 === 'string') {
        dispatch(setScreenshotBase64Data({
          objectId: objectId,
          fileName: fileName,
          base64Data: dataBase64,
        }));
      }
    };

    reader.onerror = function() {

    };

  }
  useEffect(() => {
    if (!isModalVisible) {
      dispatch(resetTemplateScreenshots());
    }
  }, [isModalVisible])

  useEffect(() => {
    if (objectId && templateScreenshots[objectId]) {
      Object.keys(templateScreenshots[objectId]).forEach(filename => {
        const file = templateScreenshots[objectId][filename].file;
        readFile(file, objectId, filename);
      });
    }

  }, [templateScreenshots, objectId]);
  const handlerPaste = (event: ClipboardEventInit) => {
    const files = event.clipboardData?.files;
    if (files != undefined) {
      const screenshotsData = getObjectScreenshotData(files);
      screenshotsData.screenshotsList.forEach(
        fileData => {
          if (fileData.file !== null && objectId) {
            dispatch(setTemplateScreenshot(
              { objectId: objectId, fileName: fileData.fileName, image: fileData.file },
            ));
          }
        });



    }
  };

  const handleFileInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.currentTarget.files;
    if (files !== null) {
      for (let i = 0; i < files.length; i++) {
        const file = files.item(i);
        const fileName = file?.name ? Date.now() + '_' + file.name : '';
        if (file !== null && objectId) {
          dispatch(setTemplateScreenshot({ objectId: objectId, fileName, image: file }));
          readFile(file, objectId, fileName);
        }

      }

      e.currentTarget.value = '';
    }
  };

  const handleScreenshotSequence = (e: ChangeEvent<HTMLInputElement>, fileName: string | null) => {
    dispatch(setScreenshotSequence({ objectId: objectId, fileName, sequence: e.target.value }))
  }
  return (
    <>
      <Modal
        isModalVisible={isModalVisible}
        setModalVisible={setModalVisible}
        title={localization.modals.vuln.createTitle}
        onPaste={handlerPaste}
        isFullScreen={true}
        isNavBar={true}
      >

        <div className={cn(style['applyTemplatesModal_nav'])}>
          <NavButton
            text="Уязвимость"
            isActive={isActiveTemplateTab}
            onClick={() => setIsActiveTemplateTab(true)}
            buttonType="nav"
          />
          <NavButton
            text="Скриншоты"
            isActive={!isActiveTemplateTab}
            onClick={() => {
              setIsActiveTemplateTab(false);
            }}
            buttonType="nav"
          />
        </div>
        {isActiveTemplateTab ?
          <div className={styles['modal-inputs']}>
            <InputForm
              text={localization.modals.vuln.nameText}
              placeholder={localization.modals.vuln.namePlaceholder}
              value={vuln.name}
              errorMessage={vulnerability_name_error}
              onChange={(event) => setVuln({ ...vuln, name: event.target.value })}
              required
            />
            <InputForm
              text={localization.modals.vuln.cveIdText}
              placeholder={localization.modals.vuln.cveIdPlaceholder}
              errorMessage={cve_id_error}
              value={cveId}
              onChange={(event) => setCveId(event.target.value)}
            />
            <InputForm
              text={localization.modals.vuln.cweIdText}
              placeholder={localization.modals.vuln.cweIdPlaceholder}
              value={cweId}
              onChange={(event) => setCweId(event.target.value)}
            />
            <InputForm
              text={localization.modals.vuln.locationText}
              placeholder={localization.modals.vuln.locationPlaceholder}
              value={vuln.location}
              errorMessage={location_error}
              onChange={(event) => {
                setVuln({ ...vuln, location: event.target.value });
              }}
              required
            />
            <InputForm
              text={localization.modals.vuln.descriptionText}
              placeholder={localization.modals.vuln.descriptionPlaceholder}
              value={vuln.description}
              errorMessage={description_error}
              onTextareaChange={(event) => {
                setVuln({ ...vuln, description: event.target.value });
              }}
              textarea
              required
            />
            <InputForm
              text={localization.modals.vuln.causeOccurrenceText}
              placeholder={localization.modals.vuln.causeOccurrencePlaceholder}
              value={vuln.cause_of_occurrence}
              errorMessage={cause_of_occurrence_error}
              onTextareaChange={(event) => {
                setVuln({ ...vuln, cause_of_occurrence: event.target.value });
              }}
              textarea
              required
            />
            <InputForm
              text={localization.modals.vuln.negativeConsequencesText}
              placeholder={localization.modals.vuln.negativeConsequencesPlaceholder}
              errorMessage={negative_consequences_error}
              value={selectedNegativeOption}
              onSelectChange={onNegativeConsequencesChange}
              options={negativeConsequencesTypes}
              isMulti
              required
            />
            <div className={styles['modal-calculator-wrapper']}>
              <div className={styles['modal-calculator-panel']}>
                <InputForm
                  text={localization.modals.vuln.attackVectorText}
                  placeholder={localization.modals.vuln.attackVectorPlaceholder}
                  errorMessage={attack_vector_error}
                  value={cvssAV.text || ''}
                  popupItems={attackVectorPopupItems}
                  onClick={showPopupHandler}
                  onPopupChange={onCvssAVPopupItemChange}
                  onChange={(event) => {
                    setCvssAV({ text: event.target.value, value: '' });
                  }}
                  disabled
                  required
                />
                <InputForm
                  text={localization.modals.vuln.attackComplexityText}
                  placeholder={localization.modals.vuln.attackComplexityPlaceholder}
                  errorMessage={attack_complexity_error}
                  value={cvssAC.text || ''}
                  popupItems={attackComplexityPopupItems}
                  onClick={showPopupHandler}
                  onPopupChange={onCvssACPopupItemChange}
                  onChange={(event) => {
                    setCvssAC({ text: event.target.value, value: '' });
                  }}
                  disabled
                  required
                />
                <InputForm
                  text={localization.modals.vuln.privilegesRequiredText}
                  placeholder={localization.modals.vuln.privilegesRequiredPlaceholder}
                  errorMessage={privileges_required_error}
                  value={cvssPR.text || ''}
                  popupItems={privilegesRequiredPopupItems}
                  onClick={showPopupHandler}
                  onPopupChange={onCvssPRPopupItemChange}
                  onChange={(event) => {
                    setCvssPR({ text: event.target.value, value: '' });
                  }}
                  disabled
                  required
                />
                <InputForm
                  text={localization.modals.vuln.userInteractionText}
                  placeholder={localization.modals.vuln.userInteractionPlaceholder}
                  errorMessage={user_interaction_error}
                  value={cvssUI.text || ''}
                  popupItems={userInteractionPopupItems}
                  onClick={showPopupHandler}
                  onPopupChange={onCvssUIPopupItemChange}
                  onChange={(event) => {
                    setCvssUI({ text: event.target.value, value: '' });
                  }}
                  disabled
                  required
                />
              </div>
              <div className={styles['modal-calculator-panel']}>
                <InputForm
                  text={localization.modals.vuln.scopeText}
                  placeholder={localization.modals.vuln.scopePlaceholder}
                  errorMessage={scope_error}
                  value={cvssS.text || ''}
                  popupItems={scopePopupItems}
                  onClick={showPopupHandler}
                  onPopupChange={onCvssSPopupItemChange}
                  onChange={(event) => {
                    setCvssS({ text: event.target.value, value: '' });
                  }}
                  disabled
                  required
                />
                <InputForm
                  text={localization.modals.vuln.confidentialityText}
                  placeholder={localization.modals.vuln.confidentialityPlaceholder}
                  errorMessage={confidentiality_error}
                  value={cvssC.text || ''}
                  popupItems={confidentialityPopupItems}
                  onClick={showPopupHandler}
                  onPopupChange={onCvssCPopupItemChange}
                  onChange={(event) => {
                    setCvssC({ text: event.target.value, value: '' });
                  }}
                  disabled
                  required
                />
                <InputForm
                  text={localization.modals.vuln.integrityText}
                  placeholder={localization.modals.vuln.integrityPlaceholder}
                  errorMessage={integrity_error}
                  value={cvssI.text || ''}
                  popupItems={integrityPopupItems}
                  onClick={showPopupHandler}
                  onPopupChange={onCvssIPopupItemChange}
                  onChange={(event) => {
                    setCvssI({ text: event.target.value, value: '' });
                  }}
                  disabled
                  required
                />
                <InputForm
                  text={localization.modals.vuln.availabilityText}
                  placeholder={localization.modals.vuln.availabilityPlaceholder}
                  errorMessage={availability_error}
                  value={cvssA.text || ''}
                  popupItems={availabilityPopupItems}
                  onClick={showPopupHandler}
                  onPopupChange={onCvssAPopupItemChange}
                  onChange={(event) => {
                    setCvssA({ text: event.target.value, value: '' });
                  }}
                  disabled
                  required
                />
              </div>
            </div>
            <div className={styles['modal-calculator-info']}>
              <Text className={styles['modal-calculator-info-title']}>
                {
                  `${localization.modals.vuln.vectorString} - ${vuln.cvss_vector ?
                    vuln.cvss_vector : localization.modals.vuln.vector}`
                }
              </Text>
              {vuln.risk_level && (
                <div
                  className={classNames(
                    styles['modal-calculator-info-score'], styles[`modal-calculator-info-score_${vuln.risk_level}`])}
                >
                  <Title variant={TitleVariantEnum.H1}>
                    {(vuln.cvss_score !== null && vuln.cvss_score !== undefined) ? vuln.cvss_score.toString() : '-'}
                  </Title>
                  <Text className={styles['modal-calculator-info-risk-level']}
                    variant={TextVariantEnum.L}>
                    {`(${prepareRiskLevelToRu[vuln.risk_level]})`}
                  </Text>
                </div>
              )}
            </div>
            <InputForm
              text={localization.modals.vuln.procedureExploitingText}
              placeholder={localization.modals.vuln.procedureExploitingPlaceholder}
              value={vuln.procedure_exploiting}
              errorMessage={procedure_exploiting_error}
              onTextareaChange={(event) => {
                setVuln({ ...vuln, procedure_exploiting: event.target.value });
              }}
              textarea
              required
            />
            <InputForm
              text={localization.modals.vuln.recommendationsText}
              placeholder={localization.modals.vuln.recommendationsPlaceholder}
              value={vuln.recommendations}
              errorMessage={recommendation_error}
              onTextareaChange={(event) => {
                setVuln({ ...vuln, recommendations: event.target.value });
              }}
              textarea
              required
            />
            <InputForm
              text={localization.modals.additionalInfoText}
              placeholder={localization.modals.additionalInfoPlaceholder}
              value={vuln.additional_info}
              onTextareaChange={(event) => {
                setVuln({ ...vuln, additional_info: event.target.value });
              }}
              textarea
            />

          </div>
          : <div style={{ overflow: 'scroll' }}>
            {objectId &&
              templateScreenshots[objectId] &&
              Object.keys(templateScreenshots[objectId]).map(fileName => (
                <ImageCard
                  imageSrc={
                    templateScreenshots[objectId][fileName].base64data
                  }
                  imageName={
                    fileName
                  }
                  sequence={templateScreenshots[objectId][fileName].sequence}
                  onSequenceChange={e => handleScreenshotSequence(e, fileName)}
                  textDescription={
                    templateScreenshots[objectId][fileName].description
                  }
                  onDeleteHandler={() => {
                    handleDeleteScreenshot(fileName);
                  }
                  }
                  onTextAreaChange={(event) => {
                    handleScreenshotDescription(event, fileName);
                  }} />
              ))
            }
          </div>
        }
        <div className={classNames(styles['modal-buttons'], styles['modal-buttons_between'])}>
          {!isActiveTemplateTab &&
            <InputForm
              text={''}
              placeholder={localization.modals.vuln.screenshotsPlaceholder}
              type={InputTypeEnum.File}
              onChange={handleFileInputChange}
              multiple={true}
              isMulti={true}
            />
          }
          <div style={{ display: "flex", gap: '20px' }}>

            <Button
              buttonText={localization.modals.createButtonText}
              onClick={onConfirmCreateModalHandler}
            />
            <Button
              type={ButtonTypeEnum.Red}
              buttonText={localization.modals.resetButtonText}
              onClick={onConfirmResetModalHandler}
            />
          </div>
        </div>
      </Modal>
      <ConfirmModal
        isModalVisible={isCreateVulnModal}
        setModalVisible={setCreateVulnModal}
        text={localization.vuln.confirmVulnText}
        onConfirmClick={addVulnHandler}
        type={ModalTypes.Create}
      />
      <ConfirmModal
        isModalVisible={isResetVulnDataModal}
        setModalVisible={setResetVulnDataModal}
        text={localization.vuln.confirmVulnDataText}
        onConfirmClick={resetVulnDataHandler}
        type={ModalTypes.Reset}
      />
    </>
  );
};

export default CreateVuln;
