import React, { ChangeEvent, FC, useEffect, useState } from "react"

import cn from "classnames"

import Select from "react-select"

import Modal from "../Modal"
import { NavButton, ProgressUpload } from "../../../shared"
import Input from "../../input/Input"
import { InputTypeEnum } from "../../input/InputTypes"
import Button from "../../button/Button"
import {
  getVulnTemplate,
  getVulnTemplates,
} from "../../../store/vulns/vulnsAsync"
import { useAppDispatch } from "../../../hooks/useAppDispatch"
import { useAppSelector } from "../../../hooks/useAppSelector"
import { selectVulns } from "../../../store/vulns/vulnsSelectors"
import {
  IGetVulnTemplateResponse,
  IVuln,
} from "../../../store/vulns/vulnsTypes"
import InputForm from "../../inputForm/InputForm"

import {
  clearVulnIsAdded,
  deleteScreenshot,
  setScreenshotDescription,
  setScreenshotBase64Data,
  setTemplateScreenshot,
  setScreenshotSequence,
} from "../../../store/vulns/vulnsSlice"
import { ImageCard } from "../../../entities/ui/imageCard"
import { localization } from "../../../localization/localization"
import { negativeConsequencesTypes } from "../../../constants/vulns"

import { objectTypeList } from "../../../constants/objects"

import { createVulnsForObjects } from "../../../store/objects/objectsAsync"

import { selectObjects } from "../../../store/objects/objectsSelectors"

import style from "./ApplyTemplates.module.scss"
import {
  IScreenshotDescriptions,
  IScreenshotList,
  applyTemplatesProps,
} from "./applyTemplatesTypes"

export const ApplyTemplates: FC<applyTemplatesProps> = (props) => {
  const { isModalVisible, setModalVisible, activeRowIds, base, projectId } =
    props

  const dispatch = useAppDispatch()
  const { templates, vulnTemplate, vulnsSendStatus, templateScreenshots } =
    useAppSelector(selectVulns)
  const { status } = useAppSelector(selectObjects)

  const [isActiveTemplateTab, setIsActiveTemplateTab] = useState(true)
  const [currentTemplate, setCurrentTemplate] = useState({
    id: "",
    tpl_name: "",
  })
  const [filterTemplates, setFilterTemplates] = useState(templates)
  const [currentObjectId, setCurrentObjectId] = useState("")
  const [currentTemplateValues, setCurrentTemplateValues] =
    useState<IGetVulnTemplateResponse | null>(null)
  const [screenshots, setScreenshots] = useState<IScreenshotList>({})
  const [localizationModal, setLocalizationModal] = useState({
    headerLeftSide: "Шаблоны:",
    headerRightSide: "Шаблон:",
    nextButton: "Добавить скриншот",
  })
  const [activeRows, setActiveRows] = useState(base)
  const [isSendData, setIsSendData] = useState(false)
  const [selectedNegativeOption, setSelectedNegativeOption] =
    useState<any>(null)
  const [path, setPath] = useState("")
  const [objectTypeFilterValue, setObjectTypeFilterValue] = useState("")

  useEffect(() => {
    dispatch(getVulnTemplates(objectTypeFilterValue))
  }, [dispatch, objectTypeFilterValue])
  useEffect(() => {
    currentTemplate.id !== "" && dispatch(getVulnTemplate(currentTemplate.id))
  }, [dispatch, currentTemplate])
  useEffect(() => {
    const negativeConsequences =
      vulnTemplate?.negative_consequences &&
      vulnTemplate?.negative_consequences?.split(",")
    if (negativeConsequences) {
      const negativeConsequencesOptions = negativeConsequences.map((el) => {
        return {
          value: el,
          label: el[0].toUpperCase() + el.slice(1),
        }
      })
      setSelectedNegativeOption(negativeConsequencesOptions)
    } else {
      setSelectedNegativeOption(null)
    }
    vulnTemplate && setCurrentTemplateValues(vulnTemplate)
  }, [vulnTemplate])
  useEffect(() => {
    setFilterTemplates(templates)
  }, [templates])
  useEffect(() => {
    setCurrentObjectId(activeRowIds[0])
  }, [activeRowIds])
  useEffect(() => {
    status === 201 && setModalVisible(false)
  }, [status])
  useEffect(() => {
    if (isActiveTemplateTab) {
      setLocalizationModal({
        headerLeftSide: "Шаблоны:",
        headerRightSide: "Шаблон:",
        nextButton: isSendData ? "Готово" : "Добавить скриншоты",
      })
    } else {
      setLocalizationModal({
        headerLeftSide: "Объект:",
        headerRightSide: "Скриншоты:",
        nextButton: isSendData ? "Готово" : "Применить",
      })
    }
  }, [isActiveTemplateTab, isSendData])

  useEffect(() => {
    if (base !== null && activeRowIds !== null) {
      const activeRowsTemp = base.filter((row) => activeRowIds.includes(row.id))
      setActiveRows(activeRowsTemp)
    }
  }, [base, activeRowIds])

  useEffect(() => {
    if (templateScreenshots[currentObjectId]) {
      Object.keys(templateScreenshots[currentObjectId]).forEach((filename) => {
        const file = templateScreenshots[currentObjectId][filename].file
        readFile(file, currentObjectId, filename)
      })
    }
  }, [templateScreenshots, currentObjectId])

  const resetModalData = () => {
    setCurrentTemplate({ id: "", tpl_name: "" })
    setIsActiveTemplateTab(true)
    setFilterTemplates(templates)
    setCurrentTemplateValues(null)
    setCurrentObjectId(activeRowIds[0])
    window.removeEventListener("paste", handlerPaste)
    setModalVisible(false)
    setIsSendData(false)
    setScreenshots({})
    dispatch(clearVulnIsAdded())
  }

  const parseIds = (ids: string | null): string[] | null => {
    if (!ids) return null
    else {
      return ids.split(",").map((r) => r.trim())
    }
  }

  const handleClickNextButton = () => {
    if (isActiveTemplateTab && !isSendData) {
      setIsActiveTemplateTab(false)
    } else if (!isActiveTemplateTab && !isSendData) {
      setIsSendData(true)
      if (currentTemplateValues !== null) {
        const cve_id = parseIds(currentTemplateValues.cve_id)
        const cwe_id = parseIds(currentTemplateValues.cwe_id)

        const template: IVuln = {
          ...currentTemplateValues,
          cve_id,
          cwe_id,
          template_id: currentTemplate.id,
          negative_consequences: currentTemplateValues.negative_consequences
            ? currentTemplateValues.negative_consequences?.split(",")
            : [],
          path: path === "" ? null : path,
        }
        delete template["id"]
        delete template["status"]
        const objectsData = activeRows.map((obj) => ({
          id: obj.id,
          type: obj.object_type,
        }))

        dispatch(
          createVulnsForObjects({
            dataValue: template,
            projectId,
            screenshots: templateScreenshots,
            objectsList: objectsData,
          })
        )
      }
    } else {
      resetModalData()
    }
  }

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const currentValue = e.currentTarget.value.toLowerCase()
    setFilterTemplates(
      templates.filter(
        (t) => t.tpl_name.toLowerCase().indexOf(currentValue) >= 0
      )
    )
  }

  const handleOnClickCancel = () => {
    resetModalData()
  }

  useEffect(() => {
    if (!isModalVisible) {
      resetModalData()
    }
  }, [isModalVisible])

  function readFile(file: File, objectId: string, fileName: string) {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = function () {
      const dataBase64 = reader.result
      if (typeof dataBase64 === "string") {
        dispatch(
          setScreenshotBase64Data({
            objectId: objectId,
            fileName: fileName,
            base64Data: dataBase64,
          })
        )
      }
    }

    reader.onerror = function () {}
  }

  const getObjectScreenshotData = (files: FileList) => {
    const screenshotsList = []
    const descriptions: IScreenshotDescriptions = {}
    for (let i = 0; i < files.length; i++) {
      const file = files.item(i)
      const fileName = file?.name ? Date.now() + "_" + file.name : ""
      const currentScreenshotObject = { file: file, fileName: fileName }
      screenshotsList.push(currentScreenshotObject)
      descriptions[fileName] = " "
    }
    return {
      screenshotsList: screenshotsList,
      descriptions: descriptions,
    }
  }

  const handlerPaste = (event: ClipboardEventInit) => {
    const files = event.clipboardData?.files
    if (files !== undefined) {
      const screenshotsData = getObjectScreenshotData(files)
      screenshotsData.screenshotsList.forEach((fileData) => {
        if (fileData.file !== null) {
          dispatch(
            setTemplateScreenshot({
              objectId: currentObjectId,
              fileName: fileData.fileName,
              image: fileData.file,
            })
          )
        }
      })
    }
  }

  const onNegativeConsequencesChange = (
    data: { value: string; label: string }[]
  ) => {
    const prepareNegativeConsequences = data
      .map(({ value }: any) => value)
      .join(",")
    // @ts-ignore
    setCurrentTemplateValues({
      ...currentTemplateValues,
      negative_consequences: prepareNegativeConsequences,
    })
    setSelectedNegativeOption(data)
  }

  const handleFileInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.currentTarget.files
    if (files !== null) {
      for (let i = 0; i < files.length; i++) {
        const file = files.item(i)
        const fileName = file?.name ? Date.now() + "_" + file.name : ""
        if (file !== null) {
          dispatch(
            setTemplateScreenshot({
              objectId: currentObjectId,
              fileName,
              image: file,
            })
          )
          readFile(file, currentObjectId, fileName)
        }
      }

      e.currentTarget.value = ""
    }
  }

  const handleScreenshotDescription = (
    e: ChangeEvent<HTMLTextAreaElement>,
    fileName: string | null
  ) => {
    if (fileName !== null) {
      dispatch(
        setScreenshotDescription({
          description: e.target.value,
          fileName,
          objectId: currentObjectId,
        })
      )
    }
  }

  const handleDeleteScreenshot = (fileName: string) => {
    dispatch(deleteScreenshot({ objectId: currentObjectId, fileName }))
  }
  const handleScreenshotSequence = (
    e: ChangeEvent<HTMLInputElement>,
    fileName: string | null
  ) => {
    dispatch(
      setScreenshotSequence({
        objectId: currentObjectId,
        fileName,
        sequence: e.target.value,
      })
    )
  }
  return (
    <>
      <Modal
        onPaste={handlerPaste}
        isModalVisible={isModalVisible}
        setModalVisible={setModalVisible}
        title={"Применить шаблон"}
        isFullScreen={true}
        isNavBar={true}
      >
        <>
          <div className={cn(style["applyTemplatesModal_nav"])}>
            <NavButton
              text="Шаблоны"
              isActive={isActiveTemplateTab}
              onClick={() => setIsActiveTemplateTab(true)}
              buttonType="nav"
            />
            <NavButton
              text="Скриншоты"
              isActive={!isActiveTemplateTab}
              onClick={() => {
                setIsActiveTemplateTab(false)
              }}
              buttonType="nav"
            />
          </div>
          <div className={cn(style["content"])}>
            <div className={cn(style["applyTemplatesModal_main"])}>
              <h3>{localizationModal.headerLeftSide}</h3>
              {isActiveTemplateTab ? (
                <>
                  <Input
                    placeholder="Поиск по шаблонам..."
                    imageUrl="/assets/icons/search.svg"
                    onChange={(e) => handleChange(e)}
                  />
                  <h3>Тип объекта: </h3>

                  <Select
                    placeholder="Тип объекта..."
                    onChange={(e) => {
                      e && setObjectTypeFilterValue(e.value)
                    }}
                    options={[{ label: "Все шаблоны", value: "" }].concat(
                      objectTypeList
                    )}
                  />

                  <div
                    className={cn(style["applyTemplatesModal_main-content"])}
                  >
                    {filterTemplates.map((el) => (
                      <NavButton
                        key={el.id}
                        text={el.tpl_name}
                        isActive={el.id === currentTemplate.id}
                        onClick={() => {
                          setCurrentTemplate({
                            id: el.id,
                            tpl_name: el.tpl_name,
                          })
                        }}
                        buttonType="list"
                      />
                    ))}
                  </div>
                </>
              ) : (
                <div
                  className={cn(
                    style["applyTemplatesModal_main-content"],
                    style["applyTemplatesModal_main__screenshots"]
                  )}
                >
                  {activeRows.map((row) => (
                    <div
                      className={cn(
                        style["applyTemplatesModal_main-object_item"]
                      )}
                    >
                      <NavButton
                        key={row.id}
                        text={`${row.object_identifier || "-"} || ${row.object_identifier_additional || "-"}`}
                        isComplite={screenshots[row.id]?.length > 0}
                        isActive={row.id === currentObjectId}
                        onClick={() => setCurrentObjectId(row.id)}
                        buttonType="list"
                      />
                      <ProgressUpload
                        isHidden={vulnsSendStatus === null}
                        isLoad={vulnsSendStatus === "pending"}
                        isComplete={vulnsSendStatus === "resolve"}
                      />
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div className={cn(style["applyTemplatesModal_main"])}>
              {isActiveTemplateTab ? (
                <>
                  <h3>{"{$PATH} ="}</h3>

                  <InputForm
                    text=""
                    placeholder="Path..."
                    value={path}
                    errorMessage=""
                    onChange={(event) => {
                      setPath(event.target.value)
                    }}
                  />
                  <div className={cn(style["info-image"])}>
                    <img src="/assets/icons/info.svg" alt="info" />
                  </div>
                  <p className={cn(style["text-info"])}>
                    {"Формат переменных: {$IP}, {$URL}, {$PATH}. "}
                    IP и URL заполняются автоматически из объекта при наличии,
                    иначе будет подставлена пустая строка.
                  </p>
                  <div
                    className={cn(style["applyTemplatesModal_main-content"])}
                  >
                    {currentTemplateValues === null ? (
                      ""
                    ) : (
                      <>
                        <InputForm
                          text="Название: "
                          placeholder=""
                          value={currentTemplateValues.name || ""}
                          errorMessage=""
                          onTextareaChange={(event) => {
                            setCurrentTemplateValues({
                              ...currentTemplateValues,
                              name: event.target.value,
                            })
                          }}
                          textarea
                          required
                        />
                        <InputForm
                          text="CVE ID (через запятую, например: CVE-1111-1111, CVE-2222-2222): "
                          placeholder=""
                          value={currentTemplateValues.cve_id || ""}
                          errorMessage=""
                          onTextareaChange={(event) => {
                            setCurrentTemplateValues({
                              ...currentTemplateValues,
                              cve_id: event.target.value,
                            })
                          }}
                          textarea
                        />
                        <InputForm
                          text="CWE ID (через запятую, например: CWE-1, CWE-2): "
                          placeholder=""
                          value={currentTemplateValues.cwe_id || ""}
                          errorMessage=""
                          onTextareaChange={(event) => {
                            setCurrentTemplateValues({
                              ...currentTemplateValues,
                              cwe_id: event.target.value,
                            })
                          }}
                          textarea
                        />
                        <InputForm
                          text="URL+PORT: "
                          placeholder=""
                          value={currentTemplateValues.location || ""}
                          errorMessage=""
                          onChange={(event) => {
                            setCurrentTemplateValues({
                              ...currentTemplateValues,
                              location: event.target.value,
                            })
                          }}
                          required
                        />
                        <InputForm
                          text="Описание: "
                          placeholder=""
                          value={currentTemplateValues.description || ""}
                          errorMessage=""
                          onTextareaChange={(event) => {
                            setCurrentTemplateValues({
                              ...currentTemplateValues,
                              description: event.target.value,
                            })
                          }}
                          textarea
                          required
                        />
                        <InputForm
                          text="Причина возникновения: "
                          placeholder=""
                          value={
                            currentTemplateValues.cause_of_occurrence || ""
                          }
                          errorMessage=""
                          onTextareaChange={(event) => {
                            setCurrentTemplateValues({
                              ...currentTemplateValues,
                              cause_of_occurrence: event.target.value,
                            })
                          }}
                          textarea
                          required
                        />
                        <InputForm
                          text="Рекомендации: "
                          placeholder=""
                          value={currentTemplateValues.recommendations || ""}
                          errorMessage=""
                          onTextareaChange={(event) => {
                            setCurrentTemplateValues({
                              ...currentTemplateValues,
                              recommendations: event.target.value,
                            })
                          }}
                          textarea
                          required
                        />
                        <InputForm
                          text="Порядок эксплуатации: "
                          placeholder=""
                          value={
                            currentTemplateValues.procedure_exploiting || ""
                          }
                          errorMessage=""
                          onTextareaChange={(event) => {
                            setCurrentTemplateValues({
                              ...currentTemplateValues,
                              procedure_exploiting: event.target.value,
                            })
                          }}
                          textarea
                          required
                        />

                        <InputForm
                          text={
                            localization.modals.vuln.negativeConsequencesText
                          }
                          placeholder={
                            localization.modals.vuln
                              .negativeConsequencesPlaceholder
                          }
                          errorMessage={""}
                          value={selectedNegativeOption}
                          onSelectChange={onNegativeConsequencesChange}
                          options={negativeConsequencesTypes}
                          isMulti
                          required
                        />

                        <InputForm
                          text="CVSS: "
                          placeholder=""
                          value={currentTemplateValues.cvss_score || 0}
                          errorMessage=""
                          onChange={(event) => {
                            setCurrentTemplateValues({
                              ...currentTemplateValues,
                              cvss_score: event.target.value,
                            })
                          }}
                        />
                        <InputForm
                          text="CVSS вектор: "
                          placeholder=""
                          value={currentTemplateValues.cvss_vector || ""}
                          errorMessage=""
                          onTextareaChange={(event) => {
                            setCurrentTemplateValues({
                              ...currentTemplateValues,
                              cvss_vector: event.target.value,
                            })
                          }}
                        />
                        <InputForm
                          text="Дополнительная информация: "
                          placeholder=""
                          value={currentTemplateValues.additional_info || ""}
                          errorMessage=""
                          onTextareaChange={(event) => {
                            setCurrentTemplateValues({
                              ...currentTemplateValues,
                              additional_info: event.target.value,
                            })
                          }}
                          textarea
                        />
                        <InputForm
                          text="Статус: "
                          placeholder=""
                          value={currentTemplateValues.status || ""}
                          errorMessage=""
                          onChange={(event) => {
                            setCurrentTemplateValues({
                              ...currentTemplateValues,
                              status: event.target.value,
                            })
                          }}
                          disabled
                        />
                      </>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <Input
                    type={InputTypeEnum.File}
                    onChange={handleFileInputChange}
                    multiple={true}
                  />
                  <div
                    className={cn(
                      style["applyTemplatesModal_main-content"],
                      style["applyTemplatesModal_main__screenshots"]
                    )}
                  >
                    {templateScreenshots[currentObjectId] &&
                      Object.keys(templateScreenshots[currentObjectId]).map(
                        (fileName) => (
                          <ImageCard
                            imageSrc={
                              templateScreenshots[currentObjectId][fileName]
                                .base64data
                            }
                            imageName={fileName}
                            textDescription={
                              templateScreenshots[currentObjectId][fileName]
                                .description
                            }
                            sequence={
                              templateScreenshots[currentObjectId][fileName]
                                .sequence
                            }
                            onSequenceChange={(e) =>
                              handleScreenshotSequence(e, fileName)
                            }
                            onDeleteHandler={() => {
                              handleDeleteScreenshot(fileName)
                            }}
                            onTextAreaChange={(event) => {
                              handleScreenshotDescription(event, fileName)
                            }}
                          />
                        )
                      )}
                  </div>
                </>
              )}
            </div>
          </div>
          <div className={cn(style["applyTemplatesModal_buttons"])}>
            <Button buttonText="Отмена" onClick={handleOnClickCancel} />
            <Button
              buttonText={localizationModal.nextButton}
              onClick={handleClickNextButton}
            />
          </div>
        </>
      </Modal>
    </>
  )
}
