import { IEntityResponse, IState } from '../storeTypes';

export enum AuthTypes {
  LOGIN = 'auth/login',
  LOGOUT = 'auth/logout',
  USER_INFO = 'auth/getInfoUser',
  LOGIN_HISTORY = 'auth/loginHistory',
  REPORT_HISTORY = 'auth/reportHistory',
}

export interface IUser {
  id: string;
  email: string;
  first_name: string;
  is_active?: boolean;
  is_superuser?: boolean;
  is_verified?: boolean;
  role?: string;
  role_description?: string;
}

export interface ILoginRequest {
  username: string;
  password: string;
  scope?: string;
  client_id?: string;
  client_secret?: string;
  grant_type?: string;
}

export interface ILoginResponse extends IEntityResponse {
  access_token: string | null;
  token_type: string | null;
}

export interface IAuthDataProps extends ILoginResponse, Omit<IState, 'count'>, IEntityResponse { }

export interface IAuthErrorsProps {
  username_error: string;
  password_error: string;
}

export interface IAuthState {
  authData: IAuthDataProps;
  profileData: IUser;
  errors: IAuthErrorsProps;
  isAuth: boolean;
  loginHistory: { isLoaded: boolean } & ILoginHistoryResponse;
  reportHistory: { isLoaded: boolean } & IReportHistoryResponse;
}

export interface ILoginHistory {
  created_at: Date | string;
  ip_address: string | null;
  status: boolean | string;
}

export interface IReportHistory {
  id: string;
  file_extension: string | null;
  dir_path: string | null;
  is_approved: boolean | null;
  project: {
    id: string;
    name: string;
  } | null;
  query_params: string | null;
  report_type: "project" | "vulnerability";
  created_at: Date | string;
}


export interface ILoginHistoryResponse {
  data: ILoginHistory[];
  count: number;
}

export interface IReportHistoryResponse {
  data: IReportHistory[];
  count: number;
}

export interface ILoginHistoryRequest {
  offset: number;
  limit: number;
  sort_by?: keyof ILoginHistory | string;
}

