import { createAsyncThunk } from "@reduxjs/toolkit"
import { create } from "lodash"

import { AUTH_ROUTES } from "../../constants/auth"
import { IThunkApiConfigProps } from "../storeTypes"

import {
  AuthTypes,
  ILoginHistoryRequest,
  ILoginHistoryResponse,
  ILoginResponse,
  IReportHistoryResponse,
  IUser,
} from "./authTypes"

export const login = createAsyncThunk<
  ILoginResponse,
  { username: string; password: string },
  IThunkApiConfigProps
>(AuthTypes.LOGIN, async (account, { rejectWithValue, extra }) => {
  try {
    const formData: FormData = new FormData()
    formData.append("username", account.username)
    formData.append("password", account.password)

    const response = await extra.api.post(AUTH_ROUTES.LOGIN, formData)

    return {
      access_token: response.data.access_token,
      token_type: response.data.token_type,
      status: response.status,
    }
  } catch (error: any) {
    return rejectWithValue(error.response.status)
  }
})

export const logout = createAsyncThunk<string, undefined, IThunkApiConfigProps>(
  AuthTypes.LOGOUT,
  async (_, { rejectWithValue, extra }) => {
    try {
      const response = await extra.api.post(AUTH_ROUTES.LOGOUT)
      return response.data
    } catch (error: any) {
      rejectWithValue(error.response.status)
    }
  },
)

export const getUserInfo = createAsyncThunk<
  IUser,
  undefined,
  IThunkApiConfigProps
>(AuthTypes.USER_INFO, async (_, { rejectWithValue, extra }) => {
  try {
    // @ts-ignore
    const response = await extra.api.get(AUTH_ROUTES.USER_INFO)

    return response.data
  } catch (error: any) {
    rejectWithValue(error.response.status)
  }
})

export const getLoginHistory = createAsyncThunk<
  ILoginHistoryResponse,
  ILoginHistoryRequest,
  IThunkApiConfigProps
>(
  AuthTypes.LOGIN_HISTORY,
  async ({ offset, limit, sort_by }, { rejectWithValue, extra }) => {
    try {
      const pagination = `?offset=${offset}&limit=${limit ? limit : 100}`
      const sort = sort_by ? `&${sort_by}` : ""
      const response = await extra.api.get(
        AUTH_ROUTES.LOGIN_HISTORY + pagination + sort,
      )
      return response.data
    } catch (error: any) {
      rejectWithValue(error.response.status)
    }
  },
)

export const getReportHistory = createAsyncThunk<
  IReportHistoryResponse,
  ILoginHistoryRequest,
  IThunkApiConfigProps
>(
  AuthTypes.REPORT_HISTORY,
  async ({ offset, limit, sort_by }, { rejectWithValue, extra }) => {
    try {
      const pagination = `?offset=${offset}&limit=${limit ? limit : 100}`
      const sort = sort_by ? `&${sort_by}` : ""
      const response = await extra.api.get(
        AUTH_ROUTES.REPORT_HISTORY + pagination + sort,
      )
      return response.data
    } catch (error: any) {
      rejectWithValue(error.response.status)
    }
  },
)