import React, { FC, ComponentProps, useState, useEffect, useRef } from 'react';

import cn from 'classnames';

import style from './NotificationPopUp.module.scss';
interface INotificationPopUpProps extends ComponentProps<'div'> {
  text: string;
  isSuccess: boolean;
  isActive: boolean;
}
export const NotificationPopUp: FC<INotificationPopUpProps> = (props) => {
  const { text, isSuccess, isActive, ...otherProps } = props;
  const [isVisible, setIsVisible] = useState(false);
  const timer = useRef<NodeJS.Timer | null>(null);

  interface test {
    name: string;
    age: number;
  }

  useEffect(() => {
    if (isActive)
      setIsVisible(true);
  }, [isActive]);

  useEffect(() => {
    if (isVisible) {
      timer.current = setTimeout(() => {
        setIsVisible(false);
      }, 3000);
    }

  }, [isVisible]);

  return (
    <div {...otherProps}
      className={
        cn(
          isVisible ? style['notification-popup__active'] : style['notification-popup__hidden'],
          isSuccess ? style['notification-popup__success'] : style['notification-popup__error'],
          style['notification-popup'],
        )
      }
    >
      {text}
    </div>
  );
};
