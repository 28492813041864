import { createAsyncThunk } from '@reduxjs/toolkit';

import { IEntityResponse, IThunkApiConfigProps } from '../storeTypes';

import { ANALYTIC_ROUTES } from '../../constants/analytics';

import { AnalyticsTypes, IGetProjectReportRequest, IReportResponse } from './analyticsTypes';

export const getVulnsReport = createAsyncThunk<IReportResponse, string, IThunkApiConfigProps>(
  AnalyticsTypes.GET_VULNS_REPORT,
  async (prepareVulnsReport, { rejectWithValue, extra }) => {
    try {
      const response = await extra.api.get(
        `${ANALYTIC_ROUTES.VULNS_REPORT}/?${prepareVulnsReport}`);
      return { report: response.data, status: response.status };
    } catch (error: any) {
      return rejectWithValue(error.response.status);
    }
  },
);

export const getProjectReport = createAsyncThunk<IReportResponse, IGetProjectReportRequest, IThunkApiConfigProps>(
  AnalyticsTypes.GET_PROJECT_REPORT,
  async ({ name }, { rejectWithValue, extra }) => {
    try {
      const response = await extra.api.get(
        `${ANALYTIC_ROUTES.PROJECT_REPORT}/?name=${name}`);

      return { report: response.data, status: response.status };
    } catch (error: any) {
      return rejectWithValue(error.response.status);
    }
  },
);

export const uploadExcel = createAsyncThunk<IEntityResponse, FormData, IThunkApiConfigProps>(
  AnalyticsTypes.UPLOAD_EXCEL,
  async (files, { rejectWithValue, extra }) => {
    try {
      const response = await extra.api.post(ANALYTIC_ROUTES.UPLOAD_EXCEL, files);

      return { status: response.status };
    } catch (error: any) {
      return rejectWithValue(error.response.status);
    }
  },
);

export const uploadWord = createAsyncThunk<IEntityResponse, { customer_id: string; files: FormData }, IThunkApiConfigProps>(
  AnalyticsTypes.UPLOAD_WORD,
  async ({ customer_id, files }, { rejectWithValue, extra }) => {
    try {
      const response = await extra.api.post(ANALYTIC_ROUTES.UPLOAD_WORD + `?customer_id=${customer_id}`, files);

      return { status: response.status };
    } catch (error: any) {
      return rejectWithValue(error.response.status);
    }
  },
);
