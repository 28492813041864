import {createAsyncThunk} from '@reduxjs/toolkit';

import {IGetOptionalRequest, IThunkApiConfigProps} from '../storeTypes';
import {CUSTOMER_ROUTES} from '../../constants/customer';
import {OFFICES_URL} from '../../constants/offices';

import {
    IChangeOfficeRequest,
    ICreateOfficeRequest,
    IDeleteOfficeResponse,
    IGetOfficeResponse,
    IOffice,
    IOfficeByIdRequest,
    IOfficeResponse,
    OfficesTypes,
} from './officesTypes';

export const getOffices = createAsyncThunk<IGetOfficeResponse, IGetOptionalRequest, IThunkApiConfigProps>(
    OfficesTypes.GET,
    async ({ id, filters, pagination, sortParams }, { rejectWithValue, extra }) => {
        try {
            const paginationString = pagination ? `offset=${pagination.offset}&limit=${pagination.limit}` : '';
            const response = await extra.api.get(
                `${CUSTOMER_ROUTES.FULL_URL}/${id}/${OFFICES_URL}/?${paginationString}${filters ? `&${filters}` : ''}${sortParams ? '&' + sortParams : ''}`);

            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.status);
        }
    },
);

export const getAllOffices = createAsyncThunk<IGetOfficeResponse, IGetOptionalRequest, IThunkApiConfigProps>(
    OfficesTypes.GET_ALL,
    async ({ id, filters }, { rejectWithValue, extra }) => {
        try {
            const response = await extra.api.get(
                `${CUSTOMER_ROUTES.FULL_URL}/${id}/${OFFICES_URL}/${filters ? `?${filters}` : ''}`);

            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.status);
        }
    },
);

export const getOfficeById = createAsyncThunk<IOffice, IOfficeByIdRequest, IThunkApiConfigProps>(
    OfficesTypes.GET_ONE,
    async ({ customerId, officeId }, { rejectWithValue, extra }) => {
        try {
            const response = await extra.api.get(
                `${CUSTOMER_ROUTES.FULL_URL}/${customerId}/${OFFICES_URL}/${officeId}`);

            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.status);
        }
    },
);

export const createOffice = createAsyncThunk<IOfficeResponse, ICreateOfficeRequest, IThunkApiConfigProps>(
    OfficesTypes.POST,
    async ({ customerId, office }, { rejectWithValue, extra }) => {
        try {
            const response = await extra.api.post(
                `${CUSTOMER_ROUTES.FULL_URL}/${customerId}/${OFFICES_URL}/`,
                office);

            return { office: response.data, status: response.status };
        } catch (error: any) {
            return rejectWithValue(error.response.status);
        }
    },
);

export const deleteOffice = createAsyncThunk<IDeleteOfficeResponse, IOfficeByIdRequest, IThunkApiConfigProps>(
    OfficesTypes.DELETE,
    async ({ customerId, officeId }, { rejectWithValue, extra }) => {
        try {
            const response = await extra.api.delete(
                `${CUSTOMER_ROUTES.FULL_URL}/${customerId}/${OFFICES_URL}/${officeId}`);

            return { id: officeId, office: response.data, status: response.status };
        } catch (error: any) {
            return rejectWithValue(error.response.status);
        }
    },
);

export const changeOffice = createAsyncThunk<IOfficeResponse, IChangeOfficeRequest, IThunkApiConfigProps>(
    OfficesTypes.PATCH,
    async ({ customerId, officeId, office }, { rejectWithValue, extra }) => {
        try {
            const response = await extra.api.patch(
                `${CUSTOMER_ROUTES.FULL_URL}/${customerId}/${OFFICES_URL}/${officeId}`,
                office);

            return { office: response.data, status: response.status };
        } catch (error: any) {
            return rejectWithValue(error.response.status);
        }
    },
);
