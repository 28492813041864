import {createAsyncThunk} from '@reduxjs/toolkit';

import {AxiosResponse} from 'axios';

import {IThunkApiConfigProps} from '../storeTypes';

import {
    IGetTemplateRequest,
    ITemplateById,
    ITemplatesListItem,
    IUploadTemplateResponse,
    templateStatusType,
} from './templatesTypes';

export const getTemplates = createAsyncThunk<{data: ITemplatesListItem[]},  IGetTemplateRequest, IThunkApiConfigProps>(
    'getTemplates',
    async ( {objectType, templateStatus} , {rejectWithValue, extra}) => {
        try {
            const response: AxiosResponse<{data: ITemplatesListItem[]}> = await extra.api.get(
                `${process.env.REACT_APP_API_URI}/api/v1/vuln_templates/?sort_by=tpl_name${objectType ? '&object_type=' + objectType : ''}${templateStatus ?'&'+templateStatus : '' }`);

            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.status);
        }
    },
);
export const getTemplateById = createAsyncThunk<ITemplateById, string, IThunkApiConfigProps>(
    'getTemplatesById',
    async ( id , {rejectWithValue, extra}) => {
        try {
            const response = await extra.api.get(
                `${process.env.REACT_APP_API_URI}/api/v1/vuln_templates/${id}`);

            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.status);
        }
    },
);

export const createTemplate = createAsyncThunk<ITemplateById, ITemplateById, IThunkApiConfigProps>(
    'createTemplate',
    async (data, {rejectWithValue, extra}) => {
        try {
            const response = await  extra.api.post(
                `${process.env.REACT_APP_API_URI}/api/v1/vuln_templates/`,
                data,
            );
            return response.data;
        }
        catch (error: any){
            return rejectWithValue(error.response.status);
        }
    },
);
export const updateTemplate = createAsyncThunk<ITemplateById, ITemplateById, IThunkApiConfigProps>(
    'updateTemplate',
    async (data, {rejectWithValue, extra}) => {
        try {
            const response = await  extra.api.patch(
                `${process.env.REACT_APP_API_URI}/api/v1/vuln_templates/${data.id}`,
                data,
            );
            return response.data;
        }
        catch (error: any){
            return rejectWithValue(error.response.status);
        }
    },
);
export const deleteTemplate  = createAsyncThunk<{id: string; status: number}, string , IThunkApiConfigProps>(
    'deleteTemplate',
    async (id, {rejectWithValue, extra}) => {
        try {
            const response = await  extra.api.delete(
                `${process.env.REACT_APP_API_URI}/api/v1/vuln_templates/${id}/`,
            );
            return {
                id,
                status: response.status,
            };
        }
        catch (error: any){
            return rejectWithValue(error.response.status);
        }
    },
);
export const changeStatusTemplate = createAsyncThunk<ITemplateById, {id: string; status: templateStatusType} , IThunkApiConfigProps>(
    'changeStatusTemplate',
    async ({id, status}, {rejectWithValue, extra}) => {
        try {
            const response = await  extra.api.get(
                `${process.env.REACT_APP_API_URI}/api/v1/vuln_templates/${id}/change_status?status=${status}`,
            );
            return response.data;
        }
        catch (error: any){
            return rejectWithValue(error.response.status);
        }
    },
);



export const uploadTemplateFromFile = createAsyncThunk<IUploadTemplateResponse, File, IThunkApiConfigProps>(
    'uploadTemplateFromFile',
    async (file, {rejectWithValue, extra}) => {
        try {
            const formData = new FormData();
            formData.append('file', file);
            const response = await extra.api.post(
                `${process.env.REACT_APP_API_URI}/api/v1/vuln_templates/upload`,
                formData,
            );
            return  response.data;
        }
        catch (error: any){
            return rejectWithValue(error.response.status);
        }
    },
);
