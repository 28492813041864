import { ChangeEvent, FC, useEffect, useState } from 'react';

import { useLocation, useNavigate, useParams } from 'react-router-dom';

import Navbar from '../../../components/navbar/Navbar';
import Pagination from '../../../components/pagination/Pagination';
import Button from '../../../components/button/Button';
import { TextVariantEnum } from '../../../components/text/TextTypes';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../hooks/useAppSelector';
import Loader from '../../../components/loader/Loader';
import Tabs from '../../../components/tabs/Tabs';
import { ILink } from '../../../components/tabs/TabsTypes';
import Filters from '../../../components/filters/Filters';
import {
  negativeConsequencesTypes,
  prepareVulnType,
  riskLevelList,
  vulnHeaderTable,
} from '../../../constants/vulns';
import styles from '../../Items.module.scss';
import { IFilter } from '../../../components/filters/FiltersTypes';
import { InputTypeEnum } from '../../../components/input/InputTypes';
import { IVuln } from '../../../store/vulns/vulnsTypes';
import {
  deleteObjectsOrVulns,
  getActivePentesters,
  getProjectById,
} from '../../../store/projects/projectsAsync';
import {
  selectProjectById,
  selectProjects,
} from '../../../store/projects/projectsSelectors';
import { selectProfileData } from '../../../store/auth/authSelectors';

import { selectWebAppById } from '../../../store/objects/webApps/webAppsSelectors';
import { selectApiById } from '../../../store/objects/api/apiSelectors';
import { selectMobileAppById } from '../../../store/objects/mobileApps/mobileAppsSelectors';
import { selectNetworkDeviceById } from '../../../store/objects/networkDevices/networkDevicesSelectors';
import { selectServerById } from '../../../store/objects/servers/serversSelectors';
import { selectArmById } from '../../../store/objects/arm/armSelectors';
import { selectWifiById } from '../../../store/objects/wifies/wifiesSelectors';
import { selectSocialEngineeringById } from '../../../store/objects/socialEngineering/socialEngineeringSelectors';
import { selectDesktopAppById } from '../../../store/objects/desktopApps/desktopAppsSelectors';
import { selectSourceCodeById } from '../../../store/objects/sourceCodes/sourceCodesSelectors';
import { selectExternalById } from '../../../store/objects/external/externalSelectors';
import { selectInternalById } from '../../../store/objects/internal/internalSelectors';
import { selectOtherById } from '../../../store/objects/other/otherSelectors';
import { IUser } from '../../../store/auth/authTypes';
import { setVulnSelectTab } from '../../../store/vulns/vulnsSlice';
import { filterDateOrNumber } from '../../../utils/prepare/filterDateOrNumber';
import { localization } from '../../../localization/localization';

import { DEFAULT_OFFSET_PAGINATION } from '../../../constants/pages';
import {
  getNextSortParam,
  getSortString,
  sortListType,
  TableComponent,
} from '../../../shared';
import { getDefaultSortParams } from '../../../shared/lib';

import { selectKillchain } from '../../../store/killchains/killchainsSelectors';
import { getKillchainList } from '../../../store/killchains/killchainsAsync';
import CreateKillChain from '../../../components/modals/vulns/CreateKillchain';
import { NotificationPopUp } from '../../../shared/NotificationPopUp/ui/NotificationPopUp';
import { resetSendStatus } from '../../../store/killchains/killchainsSlice';
import ConfirmModal from '../../../components/modals/general/confirm/ConfirmModal';
import { IDeleteObjectsOrProjectsRequestData } from '../../../store/projects/projectsTypes';

let filters = '';

/**
 * Component for displaying information on vulns page.
 *
 */
const KillChainsPage: FC = () => {
  const dispatch = useAppDispatch();

  const projects = useAppSelector(selectProjects);
  const { projectId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const objectTypePathname = location.pathname.split('/')[4];

  const { customer, teamlead } = useAppSelector(selectProjectById);
  const { role, id } = useAppSelector(selectProfileData);

  const { pentesters: webAppPentesters } = useAppSelector(selectWebAppById);
  const { pentesters: apiPentesters } = useAppSelector(selectApiById);
  const { pentesters: mobileAppPentesters } =
    useAppSelector(selectMobileAppById);
  const { pentesters: networkDevicePentesters } = useAppSelector(
    selectNetworkDeviceById,
  );
  const { pentesters: serverPentesters } = useAppSelector(selectServerById);

  const { pentesters: armPentesters } = useAppSelector(selectArmById);
  const { pentesters: wifiPentesters } = useAppSelector(selectWifiById);
  const { pentesters: socialEngineeringPentesters } = useAppSelector(
    selectSocialEngineeringById,
  );
  const { pentesters: desktopAppPentesters } =
    useAppSelector(selectDesktopAppById);
  const { pentesters: sourceCodePentesters } =
    useAppSelector(selectSourceCodeById);

  const { pentesters: externalPentesters } =
    useAppSelector(selectExternalById);
  const { pentesters: internalPentesters } =
    useAppSelector(selectInternalById);
  const { pentesters: otherPentesters } = useAppSelector(selectOtherById);

  const killchainsHeaderTable = {
    name: { label: 'Название' },
    description: { label: 'Описание' },
    owner: { label: 'Пентестер' },
    created_at: { label: 'Дата создания' },
  };

  const { killChainList, sendStatus } = useAppSelector(selectKillchain);

  const { activePentesters } = useAppSelector(selectProjects);

  interface filtersParamType extends IVuln {
    pentester_id: string;
  }

  const [vulnFilters, setVulnFilters] = useState<filtersParamType>({
    cvss_score: null,
    cvss_vector: '',
    description: '',
    location: '',
    name: '',
    negative_consequences: [],
    procedure_exploiting: '',
    recommendations: '',
    risk_level: '',
    is_delete: false,
    pentester_id: '',
  });

  const [isCreateModal, setCreateModal] = useState<boolean>(false);
  const [isExportModal, setExportModal] = useState<boolean>(false);
  const [selectTab, setSelectTab] = useState<string>('all');

  const [negativeConsequences, setNegativeConsequences] = useState<any>(null);
  const [riskLevel, setRiskLevel] = useState<any>(null);
  const [secondarySvssScore, setSecondarySvssScore] = useState<any>(null);
  const [pentesterFilter, setPentesterFilter] = useState<any>(null);

  const [pentesters, setPentesters] = useState<IUser[]>([]);
  const [hasTeamleadAccess, setTeamleadAccess] = useState<boolean>(false);
  const [hasPentesterAccess, setPentesterAccess] = useState<boolean>(false);
  const [pagination, setPagination] = useState({
    offset: 0,
    limit: DEFAULT_OFFSET_PAGINATION,
  });
  const [sortParams, setSortParams] = useState<sortListType>(
    getDefaultSortParams(vulnHeaderTable),
  );
  const [filterString, setFilterString] = useState<string>('');

  useEffect(() => {
    if (projectId) {
      dispatch(
        getKillchainList({
          project_id: projectId,
          sort_by: getSortString(sortParams),
          filter: filterString,
          ...pagination,
        }),
      );
      dispatch(getActivePentesters(projectId));
    }
    return () => {
      dispatch(resetSendStatus());
    };
  }, [projects.status]);

  useEffect(() => {
    if (projectId && !customer) {
      dispatch(getProjectById(projectId));
    }

    const selectObjectType = prepareVulnType[objectTypePathname];
    dispatch(setVulnSelectTab(selectObjectType));

    if (projectId && !isCreateModal) {
      dispatch(
        getKillchainList({
          project_id: projectId,
          sort_by: getSortString(sortParams),
          filter: filterString,
          ...pagination,
        }),
      );
    }
  }, [
    dispatch,
    projectId,
    customer,
    isCreateModal,
    pagination,
    sortParams,
    filterString,
  ]);


  const [isDeleteObjectModal, setIsDeleteIbjectModal] = useState<boolean>(false);
  const [activeRowIds, setActiveRowIds] = useState<string[]>([]);
  const [activeRowId, setActiveRowId] = useState<{ [key: string]: boolean }>({});

  useEffect(() => {
    const allId: string[] = [];
    for (const id in activeRowId) {
      if (activeRowId[id]) allId.push(id);
    }

    setActiveRowIds(allId);
  }, [activeRowId]);

  useEffect(() => {
    const pentester = pentesters.find((pentester) => pentester.id === id);

    setPentesterAccess(!!pentester);
    setTeamleadAccess(teamlead?.id === id);
  }, [pentesters, teamlead, id]);

  const onNegativeConsequencesChange = (data: any) => {
    const prepareNegativeConsequences = data.map(({ value }: any) => value);

    setNegativeConsequences(data);
    setVulnFilters({
      ...vulnFilters,
      negative_consequences: prepareNegativeConsequences,
    });
  };

  const onRiskLevelChange = (data: any) => {
    const prepareRiskLevel = data.map(({ value }: any) => value);

    setRiskLevel(data);
    setVulnFilters({ ...vulnFilters, risk_level: prepareRiskLevel });
  };

  const onPentestersChange = (data: any) => {
    if (data) {
      setPentesterFilter(data);
      setVulnFilters({ ...vulnFilters, pentester_id: data.value });
    }
  };

  const onTabClick = (name: string) => setSelectTab(name);
  const onBackNavigate = () => {
    navigate(-1);
  };

  const onCreateProjectHandler = () =>
    setCreateModal((prevState) => !prevState);
  const onExportProjectHandler = () =>
    setExportModal((prevState) => !prevState);


  const onSearchButtonClick = (reset: boolean) => {
    filters = '';

    Object.entries(vulnFilters).forEach((vulnValue: any) => {
      const isFilterItemArray = Array.isArray(vulnValue[1]);
      const isFilterArrayEmpty =
        isFilterItemArray && vulnValue[1].length > 0;

      if ((vulnValue[1] && !isFilterItemArray) || vulnValue[1] === 0) {
        filters += `${filters ? '&' : ''}${vulnValue[0]}=${vulnValue[1]
        }`;
      } else if (isFilterArrayEmpty) {
        vulnValue[1].forEach((filter: string) => {
          filters += `${filters ? '&' : ''}${vulnValue[0]}=${filter}`;
        });
      }
    });

    filters = filterDateOrNumber(
      filters,
      vulnFilters,
      secondarySvssScore,
      'cvss_score',
    );

    if (reset) {
      setVulnFilters({
        cvss_score: NaN,
        cvss_vector: '',
        description: '',
        location: '',
        name: '',
        negative_consequences: [],
        procedure_exploiting: '',
        recommendations: '',
        risk_level: '',
        my_scope: false,
        is_delete: false,
        pentester_id: '',
      });
      setPentesterFilter(null);
      setRiskLevel(null);
      setSecondarySvssScore(NaN);
      setNegativeConsequences(null);

      if (projectId) {
        setFilterString('');
      }
    }

    if (projectId && filters && !reset) {
      setFilterString(filters);
    }

  };

  const onPageClick = (offset: number, limit: number) => {
    setPagination({ offset, limit });
  };

  const vulnsTabLinks: ILink[] = [
    {
      name: localization.vuln.tabs.allTitle,
      count: killChainList?.count ? killChainList.count : 0,
      tabId: 'all',
    },
  ];

  const filterItems: IFilter[] = [
    {
      id: 1,
      text: localization.vuln.filters.nameText,
      placeholder: localization.vuln.filters.namePlaceholder,
      type: InputTypeEnum.Text,
      value: vulnFilters.name,
      onChange: (event: ChangeEvent<HTMLInputElement>) =>
        setVulnFilters({
          ...vulnFilters,
          name: event.target.value,
        }),
    },
    {
      id: 2,
      text: localization.vuln.filters.locationText,
      placeholder: localization.vuln.filters.locationPlaceholder,
      type: InputTypeEnum.Text,
      value: vulnFilters.location,
      onChange: (event: ChangeEvent<HTMLInputElement>) =>
        setVulnFilters({
          ...vulnFilters,
          location: event.target.value,
        }),
    },
    {
      id: 3,
      text: localization.vuln.filters.negativeConsequencesText,
      placeholder:
        localization.vuln.filters.negativeConsequencesPlaceholder,
      value: negativeConsequences,
      onSelectChange: onNegativeConsequencesChange,
      options: negativeConsequencesTypes,
      isMulti: true,
    },
    {
      id: 4,
      text: localization.vuln.filters.cvssScoreText,
      placeholder: localization.vuln.filters.cvssScorePlaceholder,
      type: InputTypeEnum.Number,
      value: vulnFilters.cvss_score,
      onChange: (event: ChangeEvent<HTMLInputElement>) =>
        setVulnFilters({
          ...vulnFilters,
          cvss_score: parseInt(event.target.value),
        }),
      secondaryValue: secondarySvssScore,
      onSecondaryChange: (event: ChangeEvent<HTMLInputElement>) => {
        setSecondarySvssScore(parseInt(event.target.value));
      },
      primaryText: localization.common.primaryNumberTextHelper,
      secondaryText: localization.common.secondaryNumberTextHelper,
    },
    {
      id: 5,
      text: localization.vuln.filters.vectorText,
      placeholder: localization.vuln.filters.vectorPlaceholder,
      type: InputTypeEnum.Text,
      value: vulnFilters.cvss_vector,
      onChange: (event: ChangeEvent<HTMLInputElement>) =>
        setVulnFilters({
          ...vulnFilters,
          cvss_vector: event.target.value,
        }),
    },
    {
      id: 6,
      text: localization.vuln.filters.riskLevelText,
      placeholder: localization.vuln.filters.riskLevelPlaceholder,
      value: riskLevel,
      onSelectChange: onRiskLevelChange,
      options: riskLevelList,
      isMulti: true,
    },
    {
      id: 7,
      text: localization.vuln.filters.procedureExploitingText,
      placeholder:
        localization.vuln.filters.procedureExploitingPlaceholder,
      type: InputTypeEnum.Text,
      value: vulnFilters.procedure_exploiting,
      onChange: (event: ChangeEvent<HTMLInputElement>) =>
        setVulnFilters({
          ...vulnFilters,
          procedure_exploiting: event.target.value,
        }),
    },
    {
      id: 8,
      text: localization.common.pentesters + ':',
      placeholder: 'Поиск по пентестерам...',
      value: pentesterFilter,
      onSelectChange: onPentestersChange,
      options: activePentesters,
    },
    {
      id: 9,
      text: localization.common.deleteFilterText,
      type: InputTypeEnum.Checkbox,
      value: vulnFilters.is_delete,
      onChange: (event: ChangeEvent<HTMLInputElement>) =>
        setVulnFilters({
          ...vulnFilters,
          is_delete: event.target.checked,
        }),
    },
    {
      id: 10,
      text: localization.common.myScopeFilterText,
      type: InputTypeEnum.Checkbox,
      value: vulnFilters.my_scope,
      onChange: (event: ChangeEvent<HTMLInputElement>) =>
        setVulnFilters({
          ...vulnFilters,
          my_scope: event.target.checked,
        }),
    },
  ];

  filterItems.length =
    role === 'manager' || role === 'chief'
      ? filterItems.length - 1
      : filterItems.length;

  const handleClickSort = (param: string) => {
    setSortParams(getNextSortParam(param, sortParams));
  };
  const [bodyRows, setBodyRows] = useState<any>([]);
  const handleSelectRowId = (action: 'del' | 'add', rowId?: string) => {
    if (action === 'del') {
      if (rowId) {
        setActiveRowId(prev => ({ ...prev, [rowId]: false }));
      } else {
        setActiveRowId({});
      }
    } else {
      if (rowId) {
        setActiveRowId(prev => ({ ...prev, [rowId]: true }));
      } else {
        const allId: string[] = bodyRows.map((el: any) => el.id);
        const newSelectRowIds: { [id: string]: boolean } = {};
        for (const id of allId) {
          newSelectRowIds[id] = true;
        }
        setActiveRowId(newSelectRowIds);
      }
    }
  };
  const onChangeObjectsDeleteHandler = () => {
    const data: IDeleteObjectsOrProjectsRequestData = { category: 'killchain', ids: activeRowIds };
    projectId &&
      dispatch(deleteObjectsOrVulns({ projectId, data }));

  };

  return (
    <>
      <Navbar />
      <div className={styles.items}>
        <div className={styles['items-content']}>
          <Filters
            filters={filterItems}
            onSearchButtonClick={onSearchButtonClick}
          />
          <Tabs
            links={vulnsTabLinks}
            tabActive={selectTab}
            onClick={onTabClick}
          />
          <div className={styles['items-departures']}>
            <Button
              onClick={onBackNavigate}
              buttonText={localization.common.backButtonText}
              typeButtonText={TextVariantEnum.S}
            />
            {(role === 'admin' || role === 'teamlead' || role === 'chief' || role === 'manager' || role === 'pentester') && (
              <>
                <Button
                  onClick={() => { setCreateModal(true); }}
                  buttonText="Добавить killChain"
                  typeButtonText={TextVariantEnum.S}
                />
                <Button
                  onClick={() => setIsDeleteIbjectModal(true)}
                  buttonText={localization.vuln.deleteButtonText}
                  typeButtonText={TextVariantEnum.S}
                  dissable={activeRowIds.length === 0}
                />
              </>
            )}
          </div>
        </div>
        {!killChainList ? (
          <Loader />
        ) : (
          <div className={styles['items-table-wrapper']}>
            {killChainList && (
              <TableComponent
                sortParams={sortParams}
                headersRow={killchainsHeaderTable}
                handleClickSort={handleClickSort}
                isCheckbox={true}
                selectedRows={activeRowId}
                setSelectedRows={handleSelectRowId}

                dataList={killChainList?.data.map((vuln) => ({
                  ...vuln,
                  id: vuln.id,
                  name: vuln.name,
                  description: vuln.description,
                  owner: vuln.owner.email,
                  created_at: vuln.created_at,
                }))}
              />
            )}
          </div>
        )}
        <Pagination
          count={killChainList?.count || 0}
          onPageClick={onPageClick}
          startLimit={pagination.limit}
        />
        {(role === 'admin' ||
          role === 'teamlead' ||
          role === 'chief' ||
          role === 'manager' ||
          role === 'pentester') && (
          <>
            <CreateKillChain
              isModalVisible={isCreateModal}
              setModalVisible={setCreateModal}
              isCreateKillChain={true}
              projectId={projectId}
            />
            <ConfirmModal
              text={'выбранные уязвимости'}
              onConfirmClick={onChangeObjectsDeleteHandler}
              isModalVisible={isDeleteObjectModal}
              setModalVisible={setIsDeleteIbjectModal}
            />
          </>
        )}
        <NotificationPopUp
          isActive={sendStatus.popupIsActive}
          text={sendStatus.message}
          isSuccess={sendStatus.isSuccess}
        />

        <NotificationPopUp isActive={projects.status !== null} text={projects.notificationTitle} isSuccess={projects.error === null} />
      </div>
    </>
  );
};

export default KillChainsPage;
