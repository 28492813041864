import {FC} from 'react';

import cn from 'classnames';

import Text from '../text/Text';

import styles from './Button.module.scss';
import {ButtonTypeEnum, IButtonProps} from './ButtonTypes';

const Button: FC<IButtonProps> = ({
    buttonText,
    className,
    classNameText,
    onClick,
    type = ButtonTypeEnum.Blue,
    typeButtonText,
    dissable,
}) => {

	

    return (
        <button 
            disabled={dissable} 
            onClick={onClick} 
            className={cn(className, styles.button, dissable ?  styles['button-dissable'] : styles[`button_${type}`]    )}
        >
    	<Text className={cn(classNameText, styles['button-text']  )} variant={typeButtonText}>{buttonText}</Text>
        </button>
    );
};

export default Button;
