import {createSlice} from '@reduxjs/toolkit';

import {
    getObjectsForUniqueVulnsAsync,
    getUniqueVulnByIdAsync,
    getUniqueVulnsAsync,
    updateUniqueVulnByIdAsync,
} from './uniqueVulnsAsync';
import {IUniqueStoreType} from './uniqueVulnsTypes';

const initialState: IUniqueStoreType = {
    uniqueVulnList: null,
    objectsForUniqueVuln: null,
    uniqueVuln: null,
    count: null,
    isLoading: true,
    sendDataStatus: {
        isCompleted: false,
        isSuccess: false,
    },

};

export const uniqueVulnsSlice = createSlice(
    {
        name: 'uniqueVulns',
        initialState,
        reducers:{

        },
        extraReducers: builder => {
            builder
                .addCase(getUniqueVulnsAsync.pending, (state, action) =>{

                })
                .addCase(getUniqueVulnsAsync.fulfilled, (state, action) =>{
                    state.uniqueVulnList = action.payload.data;
                    state.count = action.payload.count;
                })
                .addCase(getObjectsForUniqueVulnsAsync.pending, (state, action) =>{

                })
                .addCase(getObjectsForUniqueVulnsAsync.fulfilled, (state, action) =>{
                    state.objectsForUniqueVuln = action.payload.data;
                    state.count = action.payload.count;
                })
                .addCase(getUniqueVulnByIdAsync.pending, (state, action) =>{

                })
                .addCase(getUniqueVulnByIdAsync.fulfilled, (state, action) =>{
                    state.uniqueVuln = action.payload;
                })
                .addCase(updateUniqueVulnByIdAsync.pending, (state, action) =>{
                    state.sendDataStatus.isCompleted = false;
                    state.sendDataStatus.isSuccess = false;
                })
                .addCase(updateUniqueVulnByIdAsync.rejected, (state, action) =>{
                    state.sendDataStatus.isCompleted = true;
                    state.sendDataStatus.isSuccess = false;
                })
                .addCase(updateUniqueVulnByIdAsync.fulfilled, (state, action) =>{
                    state.uniqueVuln = action.payload;
                    state.sendDataStatus.isCompleted = true;
                    state.sendDataStatus.isSuccess = true;
                });
        },
    },
);

export {};

export default uniqueVulnsSlice.reducer;