import { ChangeEvent, FC, useEffect, useState } from 'react';

import classNames from 'classnames';


import cn from 'classnames';

import Modal from '../Modal';
import { IModalProps } from '../ModalTypes';
import InputForm from '../../inputForm/InputForm';
import styles from '../Modal.module.scss';
import Button from '../../button/Button';
import { InputTypeEnum } from '../../input/InputTypes';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { selectVulnErrors, selectVulns } from '../../../store/vulns/vulnsSelectors';
import {
  negativeConsequencesTypes,
  prepareRiskLevelToRu,
} from '../../../constants/vulns';
import ConfirmModal from '../general/confirm/ConfirmModal';
import { ModalTypes } from '../general/confirm/ConfirmTypes';
import { ButtonTypeEnum } from '../../button/ButtonTypes';

import Text from '../../text/Text';
import Title from '../../title/Title';

import { TextVariantEnum } from '../../text/TextTypes';

import { TitleVariantEnum } from '../../title/TitleTypes';

import { localization } from '../../../localization/localization';


import style from '../applyTemplates/ApplyTemplates.module.scss';
import { NavButton } from '../../../shared';
import { ImageCard } from '../../../entities/ui/imageCard';
import {
  deleteScreenshot,
  setScreenshotBase64Data,
  setScreenshotDescription,
  setTemplateScreenshot,
  resetTemplateScreenshots,
  setScreenshotSequence,
} from '../../../store/vulns/vulnsSlice';
import { IScreenshotDescriptions } from '../applyTemplates/applyTemplatesTypes';
import { IKillchainAddData } from '../../../store/killchains/killchainsType';
import { CVSSDataType } from '../../../entities/CVSSCalculator/lib/CVSSCalculator.types';
import { CvssCalculator } from '../../../entities';
import { addKillChainAsync } from '../../../store/killchains/killchainsAsync';




interface IModalVulnProps extends IModalProps {
  objectType?: string;
  isCreateKillChain?: boolean;
  projectId?: string;
  objectId?: string;
}


const CreateKillChain: FC<IModalVulnProps> = ({
  isModalVisible,
  setModalVisible,
  objectId = 'killchain',
  projectId,
}) => {
  const dispatch = useAppDispatch();

  const { vulns, newVulnId, templateScreenshots } = useAppSelector(selectVulns);

  const {
    location_error,
    vulnerability_name_error,
    description_error,
    cause_of_occurrence_error,
    procedure_exploiting_error,
    recommendation_error,
    negative_consequences_error,
  } = useAppSelector(selectVulnErrors);


  const [vuln, setVuln] = useState<IKillchainAddData>({
    name: '',
    location: '',
    description: '',
    cause_of_occurrence: null,
    negative_consequences: null,
    cvss_score: null,
    cvss_vector: null,
    risk_level: '',
    procedure_exploiting: null,
    recommendations: null,
    additional_info: null,
    screenshots: null,

  });

  const [screenshots, setScreenshots] = useState<FileList | any[]>();
  const [selectedNegativeOption, setSelectedNegativeOption] = useState<any>(null);

  const [isCreateVulnModal, setCreateVulnModal] = useState<boolean>(false);
  const [isResetVulnDataModal, setResetVulnDataModal] = useState<boolean>(false);
  const [isVulnsLoading] = useState<boolean>(false);
  const [isModalCorrect, setIsModalCorrect] = useState<boolean>(false);
  const [isActiveTemplateTab, setIsActiveTemplateTab] = useState(true);

  useEffect(() => {

    if (screenshots && isModalCorrect && projectId && objectId && newVulnId !== '') {
      const data = new FormData();

      Array.from(screenshots).forEach((screenshot) => {
        data.append('files', screenshot, screenshot.name);

      });

      setVuln({
        ...vuln,
        name: '',
      });
      setScreenshots([]);

      setIsModalCorrect(false);
    }

  }, [dispatch, objectId, projectId, vulns, isVulnsLoading]);

  const addVulnHandler = () => {

    const isValidate = true;

    if (isValidate && projectId) {
      const screenshotsData: any[] = [];
      if (Object.keys(templateScreenshots).length > 0) {
        for (const screenName in templateScreenshots[objectId]) {
          screenshotsData.push({
            description: templateScreenshots[objectId][screenName].description,
            imgbase64: templateScreenshots[objectId][screenName].base64data.split(',')[1],
            sequence: templateScreenshots[objectId][screenName].sequence,
          });
        }
      }
      setIsModalCorrect(true);
      const sendVulnData: IKillchainAddData = {
        additional_info: vuln.additional_info || '',
        cause_of_occurrence: vuln.cause_of_occurrence || '',
        cvss_score: typeof vuln.cvss_score === 'number' ? vuln.cvss_score : 0,
        cvss_vector: vuln.cvss_vector || '',
        description: vuln.description || '',
        location: vuln.location || '',
        negative_consequences: vuln.negative_consequences || '',
        procedure_exploiting: vuln.procedure_exploiting || '',
        recommendations: vuln.recommendations || '',
        risk_level: vuln.risk_level || '',
        screenshots: screenshotsData || [],
        name: vuln.name || '',
      };
      dispatch(addKillChainAsync({ projectId, data: sendVulnData }));

      if (!screenshots) {
        setVuln({
          ...vuln,
          additional_info: '',
          cvss_score: 0,
          cvss_vector: '',
          description: '',
          cause_of_occurrence: '',
          location: '',
          procedure_exploiting: '',
          recommendations: '',
          risk_level: '',
          name: '',
        });
      } else if (screenshots) {
        setVuln({
          ...vuln,
          additional_info: '',
          cvss_score: 0,
          cvss_vector: '',
          description: '',
          cause_of_occurrence: '',
          location: '',
          procedure_exploiting: '',
          recommendations: '',
          risk_level: '',
        });
      }
      setModalVisible(false);
    }
  };

  const resetVulnDataHandler = () => {
    setVuln(
      {
        name: '',
        location: '',
        description: '',
        cause_of_occurrence: '',
        negative_consequences: null,
        cvss_score: null,
        cvss_vector: null,
        risk_level: '',
        procedure_exploiting: '',
        recommendations: '',
        additional_info: '',
        screenshots: null,

      },
    );
    setSelectedNegativeOption([]);
  };


  const onNegativeConsequencesChange = (data: any) => {
    const prepareNegativeConsequences = data.map(({ value }: any) => value);

    setVuln({ ...vuln, negative_consequences: prepareNegativeConsequences });
    setSelectedNegativeOption(data);
  };


  const onConfirmCreateModalHandler = () => setCreateVulnModal(prevState => !prevState);
  const onConfirmResetModalHandler = () => setResetVulnDataModal(prevState => !prevState);

  const handleScreenshotDescription = (e: ChangeEvent<HTMLTextAreaElement>, fileName: string | null) => {
    if (fileName !== null && objectId) {
      dispatch(setScreenshotDescription({ description: e.target.value, fileName, objectId: objectId }));
    }
  };

  const getObjectScreenshotData = (files: FileList) => {
    const screenshotsList = [];
    const descriptions: IScreenshotDescriptions = {};
    for (let i = 0; i < files.length; i++) {
      const file = files.item(i);
      const fileName = file?.name ? Date.now() + '_' + file.name : '';
      const currentScrennshotObject = { file: file, fileName: fileName };
      screenshotsList.push(currentScrennshotObject);
      descriptions[fileName] = ' ';
    }
    return {
      screenshotsList: screenshotsList,
      descriptions: descriptions,
    };
  };
  const handleDeleteScreenshot = (fileName: string) => {
    dispatch(deleteScreenshot({ objectId: objectId, fileName }));
  };

  function readFile(file: File, objectId: string, fileName: string) {

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function() {

      const dataBase64 = reader.result;
      if (typeof dataBase64 === 'string') {
        dispatch(setScreenshotBase64Data({
          objectId: objectId,
          fileName: fileName,
          base64Data: dataBase64,
        }));
      }
    };

    reader.onerror = function() {

    };

  }
  useEffect(() => {
    if (!isModalVisible) {
      dispatch(resetTemplateScreenshots());
    }
  }, [isModalVisible]);

  useEffect(() => {
    if (objectId && templateScreenshots[objectId]) {
      Object.keys(templateScreenshots[objectId]).forEach(filename => {
        const file = templateScreenshots[objectId][filename].file;
        readFile(file, objectId, filename);
      });
    }

  }, [templateScreenshots, objectId]);
  const handlerPaste = (event: ClipboardEventInit) => {
    const files = event.clipboardData?.files;
    if (files != undefined) {
      const screenshotsData = getObjectScreenshotData(files);
      screenshotsData.screenshotsList.forEach(
        fileData => {
          if (fileData.file !== null && objectId) {
            dispatch(setTemplateScreenshot(
              { objectId: objectId, fileName: fileData.fileName, image: fileData.file },
            ));
          }
        });
    }
  };

  const handleFileInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.currentTarget.files;
    if (files !== null) {
      for (let i = 0; i < files.length; i++) {
        const file = files.item(i);
        const fileName = file?.name ? Date.now() + '_' + file.name : '';
        if (file !== null && objectId) {
          dispatch(setTemplateScreenshot({ objectId: objectId, fileName, image: file }));
          readFile(file, objectId, fileName);
        }

      }

      e.currentTarget.value = '';
    }
  };

  const handeleCVSScalculatorChange = (value: CVSSDataType) => {
    if (value.cvss_vector !== '') {
      setVuln({
        ...vuln,
        risk_level: value.risk_level || 'info',
        cvss_vector: value.cvss_vector,
        cvss_score: value.cvss_score,
      });
    }
  };

  const handleScreenshotSequence = (e: ChangeEvent<HTMLInputElement>, fileName: string | null) => {
    dispatch(setScreenshotSequence({ objectId: objectId, fileName, sequence: e.target.value }));
  };
  return (
    <>
      <Modal
        isModalVisible={isModalVisible}
        setModalVisible={setModalVisible}
        title={localization.modals.vuln.createTitle}
        onPaste={handlerPaste}
        isFullScreen={true}
        isNavBar={true}
      >

        <div className={cn(style['applyTemplatesModal_nav'])}>
          <NavButton
            text="Уязвимость"
            isActive={isActiveTemplateTab}
            onClick={() => setIsActiveTemplateTab(true)}
            buttonType="nav"
          />
          <NavButton
            text="Скриншоты"
            isActive={!isActiveTemplateTab}
            onClick={() => {
              setIsActiveTemplateTab(false);
            }}
            buttonType="nav"
          />
        </div>
        {isActiveTemplateTab ?
          <div className={styles['modal-inputs']}>
            <InputForm
              text={localization.modals.vuln.nameText}
              placeholder={localization.modals.vuln.namePlaceholder}
              value={vuln.name}
              errorMessage={vulnerability_name_error}
              onChange={(event) => setVuln({ ...vuln, name: event.target.value })}
              required
            />
            <InputForm
              text={localization.modals.vuln.locationText}
              placeholder={localization.modals.vuln.locationPlaceholder}
              value={vuln.location}
              errorMessage={location_error}
              onChange={(event) => {
                setVuln({ ...vuln, location: event.target.value });
              }}
              required
            />
            <InputForm
              text={localization.modals.vuln.descriptionText}
              placeholder={localization.modals.vuln.descriptionPlaceholder}
              value={vuln.description}
              errorMessage={description_error}
              onTextareaChange={(event) => {
                setVuln({ ...vuln, description: event.target.value });
              }}
              textarea
              required
            />
            <InputForm
              text={localization.modals.vuln.causeOccurrenceText}
              placeholder={localization.modals.vuln.causeOccurrencePlaceholder}
              value={vuln.cause_of_occurrence}
              errorMessage={cause_of_occurrence_error}
              onTextareaChange={(event) => {
                setVuln({ ...vuln, cause_of_occurrence: event.target.value });
              }}
              textarea
            />
            <InputForm
              text={localization.modals.vuln.negativeConsequencesText}
              placeholder={localization.modals.vuln.negativeConsequencesPlaceholder}
              errorMessage={negative_consequences_error}
              value={selectedNegativeOption}
              onSelectChange={onNegativeConsequencesChange}
              options={negativeConsequencesTypes}
              isMulti
            />
            <div className={styles['modal-calculator-wrapper']}>
              <CvssCalculator setCVSSValue={handeleCVSScalculatorChange} CVSSVector={vuln.cvss_vector} />
            </div>
            <div className={styles['modal-calculator-info']}>
              <Text className={styles['modal-calculator-info-title']}>
                {
                  `${localization.modals.vuln.vectorString} - ${vuln.cvss_vector ?
                    vuln.cvss_vector : localization.modals.vuln.vector}`
                }
              </Text>
              {vuln.risk_level && (
                <div
                  className={classNames(
                    styles['modal-calculator-info-score'], styles[`modal-calculator-info-score_${vuln.risk_level}`])}
                >
                  <Title variant={TitleVariantEnum.H1}>
                    {(vuln.cvss_score !== null && vuln.cvss_score !== undefined) ? vuln.cvss_score.toString() : '-'}
                  </Title>
                  <Text className={styles['modal-calculator-info-risk-level']}
                    variant={TextVariantEnum.L}>
                    {`(${prepareRiskLevelToRu[vuln.risk_level]})`}
                  </Text>
                </div>
              )}
            </div>
            <InputForm
              text={localization.modals.vuln.procedureExploitingText}
              placeholder={localization.modals.vuln.procedureExploitingPlaceholder}
              value={vuln.procedure_exploiting}
              errorMessage={procedure_exploiting_error}
              onTextareaChange={(event) => {
                setVuln({ ...vuln, procedure_exploiting: event.target.value });
              }}
              textarea
            />
            <InputForm
              text={localization.modals.vuln.recommendationsText}
              placeholder={localization.modals.vuln.recommendationsPlaceholder}
              value={vuln.recommendations}
              errorMessage={recommendation_error}
              onTextareaChange={(event) => {
                setVuln({ ...vuln, recommendations: event.target.value });
              }}
              textarea
            />
            <InputForm
              text={localization.modals.additionalInfoText}
              placeholder={localization.modals.additionalInfoPlaceholder}
              value={vuln.additional_info}
              onTextareaChange={(event) => {
                setVuln({ ...vuln, additional_info: event.target.value });
              }}
              textarea
            />

          </div>
          : <div style={{ overflow: 'scroll' }}>
            {objectId &&
              templateScreenshots[objectId] &&
              Object.keys(templateScreenshots[objectId]).map(fileName => (
                <ImageCard
                  imageSrc={
                    templateScreenshots[objectId][fileName].base64data
                  }
                  imageName={
                    fileName
                  }
                  sequence={templateScreenshots[objectId][fileName].sequence}
                  onSequenceChange={e => handleScreenshotSequence(e, fileName)}
                  textDescription={
                    templateScreenshots[objectId][fileName].description
                  }
                  onDeleteHandler={() => {
                    handleDeleteScreenshot(fileName);
                  }
                  }
                  onTextAreaChange={(event) => {
                    handleScreenshotDescription(event, fileName);
                  }} />
              ))
            }
          </div>
        }
        <div className={classNames(styles['modal-buttons'], styles['modal-buttons_between'])}>
          {!isActiveTemplateTab &&
            <InputForm
              text={''}
              placeholder={localization.modals.vuln.screenshotsPlaceholder}
              type={InputTypeEnum.File}
              onChange={handleFileInputChange}
              multiple={true}
              isMulti={true}
            />
          }
          <div style={{ display: 'flex', gap: '20px' }}>

            <Button
              buttonText={localization.modals.createButtonText}
              onClick={onConfirmCreateModalHandler}
            />
            <Button
              type={ButtonTypeEnum.Red}
              buttonText={localization.modals.resetButtonText}
              onClick={onConfirmResetModalHandler}
            />
          </div>
        </div>
      </Modal>
      <ConfirmModal
        isModalVisible={isCreateVulnModal}
        setModalVisible={setCreateVulnModal}
        text={localization.vuln.confirmVulnText}
        onConfirmClick={addVulnHandler}
        type={ModalTypes.Create}
      />
      <ConfirmModal
        isModalVisible={isResetVulnDataModal}
        setModalVisible={setResetVulnDataModal}
        text={localization.vuln.confirmVulnDataText}
        onConfirmClick={resetVulnDataHandler}
        type={ModalTypes.Reset}
      />
    </>
  );
};

export default CreateKillChain;
