export const ROLES_ROUTES = {
    TEAMLEADS: '/teamleads',
    ASSIGN_TEAMLEAD: 'assign_teamlead',
    DELETE_TEAMLEAD: 'delete_teamlead',
    MANAGERS: '/managers',
    ASSIGN_MANAGER: 'assign_manager',
    DELETE_MANAGER: 'delete_manager',
    PENTESTERS: '/pentesters',
    ASSIGN_PENTESTER: 'assign_pentesters',
    DELETE_PENTESTER: 'delete_pentesters',
    ACTIVE_PENTESTERS: 'active_pentesters',
};

export const prepareRoleVariantsToRu: { [index: string]: string } = {
    admin: 'Администратор',
    client: 'Клиент',
    manager: 'Менеджер',
    chief: 'Руководитель',
    teamlead: 'Тимлид',
    pentester: 'Пентестер',
    analyst: 'Аналитик',
};
