import { createAsyncThunk } from "@reduxjs/toolkit";

import { AxiosResponse } from "axios";

import { IThunkApiConfigProps } from "../storeTypes";

import { PROJECTS_ROUTES } from "../../constants/projects";

import {
  IAddVulnInKillChainRequest,
  IKillChainAddRequest,
  IKillchainByIdGetRequest,
  IKillchainByIdPatchRequest,
  IKillchainByIdResponse,
  IKillChainListRequest,
  IKillchainListResponse,
  IKillchainVulnListRequest,
  IKillchainVulnListResponse,
} from "./killchainsType";

export const getKillchainList = createAsyncThunk<
  IKillchainListResponse,
  IKillChainListRequest,
  IThunkApiConfigProps
>(
  "killchain/getKillchainList",
  async (requestData, { rejectWithValue, extra }) => {
    const { project_id, limit, offset, filter, sort_by } = requestData;

    const queryParamList: string[] = []

    if (offset !== undefined && limit !== undefined) queryParamList.push(`offset=${offset}&limit=${limit}`)
    if (sort_by) queryParamList.push(sort_by)
    if (filter) queryParamList.push(filter)

    const queryParam = queryParamList.length > 0 ? '?' + queryParamList.join('&') : "";
    const URL = `${PROJECTS_ROUTES.FULL_URL}/${project_id}/killchains/${queryParam}`;

    try {
      const response: AxiosResponse<IKillchainListResponse> =
        await extra.api.get(URL);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.status);
    }
  }
);

export const getKillchain = createAsyncThunk<
  IKillchainByIdResponse,
  IKillchainByIdGetRequest,
  IThunkApiConfigProps
>("killchain/getKillchain", async (requestData, { rejectWithValue, extra }) => {
  const { project_id, vulnerability_id } = requestData;

  const URL = `${PROJECTS_ROUTES.FULL_URL}/${project_id}/killchains/${vulnerability_id}`;

  try {
    const response: AxiosResponse<IKillchainByIdResponse> =
      await extra.api.get(URL);
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.response.status);
  }
});

export const updateKillchain = createAsyncThunk<
  IKillchainByIdResponse,
  IKillchainByIdPatchRequest,
  IThunkApiConfigProps
>(
  'killchain/updateKillchain',
  async (requestData, { rejectWithValue, extra }) => {
    const { project_id, vulnerability_id, data } = requestData;


    const URL = `${PROJECTS_ROUTES.FULL_URL}/${project_id}/killchains/${vulnerability_id}`;

    try {
      const response: AxiosResponse<IKillchainByIdResponse> =
        await extra.api.patch(URL, data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.status);
    }
  }
);

export const getKillchainVulnsAsync = createAsyncThunk<
  IKillchainVulnListResponse,
  IKillchainVulnListRequest,
  IThunkApiConfigProps
>(
  'killchain/getKillchainVulns',
  async (requestData, { rejectWithValue, extra }) => {
    const { project_id, vulnerability_id, search, offset, limit, sort_by } = requestData;


    const queryParamList: string[] = []

    if (offset && limit) queryParamList.push(`offset=${offset}&limit=${limit}`)
    if (sort_by) queryParamList.push(sort_by)
    if (search) queryParamList.push('search=' + search)

    const queryParam = queryParamList.length > 0 ? '?' + queryParamList.join('&') : "";
    const URL = `${PROJECTS_ROUTES.FULL_URL}/${project_id}/killchains/${vulnerability_id}/vulns${queryParam}`;

    try {
      const response: AxiosResponse<IKillchainVulnListResponse> =
        await extra.api.get(URL);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.status);
    }
  }
);

export const addVulnInKillChainAsync = createAsyncThunk<
  IKillchainVulnListResponse,
  IAddVulnInKillChainRequest,
  IThunkApiConfigProps
>(
  'killchain/addVulnInKillChain',
  async (requestData, { rejectWithValue, extra }) => {
    const { projectId, killChainId, vulnerabilitysIds } = requestData
    const vulnsIdsQueryString = vulnerabilitysIds.reduce((result: string, id: string, index: number) => {
      return result + (index !== 0 ? '' : '&') + `vulnerabilities_ids=${id}`
    }, '')
    const URL = `/projects/${projectId}/killchains/${killChainId}/add_killchains?${vulnsIdsQueryString}`
    try {
      const response: AxiosResponse<IKillchainVulnListResponse> = await extra.api.get(URL)
      return response.data
    } catch (error: any) {
      return rejectWithValue(error.response.status)
    }
  }
);


export const addKillChainAsync = createAsyncThunk<
  IKillchainByIdResponse,
  IKillChainAddRequest,
  IThunkApiConfigProps
>(
  'killchain/addKillChain',
  async (requestData, { rejectWithValue, extra }) => {
    const { projectId, data } = requestData
    const URL = `/projects/${projectId}/killchains/`
    try {
      const response: AxiosResponse<IKillchainByIdResponse> = await extra.api.post(URL, data)
      return response.data
    } catch (error: any) {
      return rejectWithValue(error.response.status)
    }
  }
)
