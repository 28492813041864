export  type sortListType = {[headerName: string]: 'none' | 'up' | 'down'}

export const getSortString =  (params: sortListType) : string => {
    const result: string[] = [];
    for (const key in params){
        if (params[key] === 'up') result.push('sort_by=' + key);
        else if (params[key] === 'down') result.push('sort_by=-' + key);
    }
    return result.join('&');
};

export const getNextSortParam = (paramName: string, params: sortListType, isMany: boolean = false) :sortListType  => {
    let nextParam: 'none' | 'up' | 'down';

    if(params[paramName] === 'none'){
        nextParam = 'up';
    } else if(params[paramName] === 'up'){
        nextParam = 'down';
    } else {
        nextParam = 'none';
    }

    if(isMany){
        return {...params, [paramName]: nextParam};
    }
    else {
        const result : sortListType = {};
        for(const param in params){
            result[param] = 'none';
        }
        return {...result, [paramName]: nextParam};
    }

};

export  const getDefaultSortParams = (projectHeader: {[key: string]: {label: string}}): sortListType  => {
    const result: sortListType = {};
    for (const key in projectHeader){
        result[key] = 'none';
    }
    return result;
};