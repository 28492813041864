import { FC, useEffect, useState } from 'react';

import classNames from 'classnames';

import { useParams } from 'react-router-dom';

import Modal from '../Modal';
import { IModalProps } from '../ModalTypes';
import InputForm from '../../inputForm/InputForm';
import styles from '../Modal.module.scss';
import Button from '../../button/Button';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { IPopupItem } from '../../popup/PopupTypes';
import {
  selectVulnErrors,
  selectVulns,
} from '../../../store/vulns/vulnsSelectors';
import { useShowPopup } from '../../../hooks/useShowPopup';
import { validateVuln } from '../../../utils/validate/validateVuln';
import {
  attackComplexityPopupItems,
  attackVectorPopupItems,
  availabilityPopupItems,
  confidentialityPopupItems,
  integrityPopupItems,
  negativeConsequencesTypes,
  prepareAttackComplexityToFull,
  prepareAttackVectorToFull,
  prepareOtherCVSSToFull,
  preparePrivilegesRequiredToFull,
  prepareRiskLevelToRu,
  prepareScopeToFull,
  prepareUserInteractionToFull,
  privilegesRequiredPopupItems,
  scopePopupItems,
  userInteractionPopupItems,
} from '../../../constants/vulns';
import ConfirmModal from '../general/confirm/ConfirmModal';
import { ModalTypes } from '../general/confirm/ConfirmTypes';
import { ButtonTypeEnum } from '../../button/ButtonTypes';

import Text from '../../text/Text';
import Title from '../../title/Title';
import { OBJECT_TYPES } from '../../../constants/objects';

import { TextVariantEnum } from '../../text/TextTypes';

import { TitleVariantEnum } from '../../title/TitleTypes';

import { localization } from '../../../localization/localization';

import {
  IUniqueVulnByIDType,
  IUniqueVulnRequestData,
} from '../../../store/uniqueVulns/uniqueVulnsTypes';
import { CVSSCalculator } from '../../../entities/CVSSCalculator/lib/cvssLib';
import { updateUniqueVulnByIdAsync } from '../../../store/uniqueVulns/uniqueVulnsAsync';
import { InputTypeEnum } from '../../input/InputTypes';
import { selectKillchain } from '../../../store/killchains/killchainsSelectors';
import { getKillchainList } from '../../../store/killchains/killchainsAsync';

interface IModalVulnProps extends IModalProps {
  vulnData: IUniqueVulnByIDType;
}

const ChangeUniqueVuln: FC<IModalVulnProps> = ({
  isModalVisible,
  vulnData,
  setModalVisible,
}) => {
  const dispatch = useAppDispatch();

  const { projectId, objectId } = useParams();

  const { showPopupHandler } = useShowPopup();

  const resetVulnDataHandler = () => {
    setVuln(vulnData);
  };

  const { killChainList } = useAppSelector(selectKillchain);
  const [killChainId, setKillChainId] = useState<any>({});
  const {
    location_error,
    vulnerability_name_error,
    description_error,
    cause_of_occurrence_error,
    procedure_exploiting_error,
    recommendation_error,
    negative_consequences_error,
    cve_id_error,

    attack_vector_error,
    attack_complexity_error,
    privileges_required_error,
    user_interaction_error,
    integrity_error,
    availability_error,
    confidentiality_error,
    scope_error,
  } = useAppSelector(selectVulnErrors);

  useEffect(() => {
    if (projectId) dispatch(getKillchainList({ project_id: projectId }));
  }, [projectId]);

  const [vuln, setVuln] = useState<typeof vulnData>(vulnData);

  const [cvssAV, setCvssAV] = useState<IPopupItem>({
    text: '',
    value: '',
  });
  const [cvssAC, setCvssAC] = useState<IPopupItem>({
    text: '',
    value: '',
  });
  const [cvssPR, setCvssPR] = useState<IPopupItem>({
    text: '',
    value: '',
  });
  const [cvssUI, setCvssUI] = useState<IPopupItem>({
    text: '',
    value: '',
  });
  const [cvssS, setCvssS] = useState<IPopupItem>({
    text: '',
    value: '',
  });
  const [cvssC, setCvssC] = useState<IPopupItem>({
    text: '',
    value: '',
  });
  const [cvssI, setCvssI] = useState<IPopupItem>({
    text: '',
    value: '',
  });
  const [cvssA, setCvssA] = useState<IPopupItem>({
    text: '',
    value: '',
  });

  const [cveId, setCveId] = useState<string>('');
  const [cweId, setCweId] = useState<string>('');

  const [selectedNegativeOption, setSelectedNegativeOption] =
    useState<any>(null);

  const [isCreateVulnModal, setCreateVulnModal] = useState<boolean>(false);
  const [isResetVulnDataModal, setResetVulnDataModal] =
    useState<boolean>(false);
  const [isVulnsLoading] = useState<boolean>(false);
  const [isModalCorrect, setIsModalCorrect] = useState<boolean>(false);

  useEffect(() => {
    const cvss = CVSSCalculator(
      cvssAV,
      cvssAC,
      cvssPR,
      cvssUI,
      cvssS,
      cvssC,
      cvssI,
      cvssA,
    );
    setVuln((prevState) => ({
      ...prevState,
      risk_level: cvss.risk_level || '0',
      cvss_vector: cvss.cvss_vector,
      cvss_score: cvss.cvss_score.toString(),
    }));
  }, [cvssAV, cvssAC, cvssPR, cvssUI, cvssS, cvssC, cvssI, cvssA]);
  const addVulnHandler = () => {
    const cvssItems = [
      cvssAV,
      cvssAC,
      cvssPR,
      cvssUI,
      cvssS,
      cvssC,
      cvssI,
      cvssA,
    ];

    const isValidate = validateVuln(
      vuln,
      dispatch,
      cvssItems,
      [],
      cveId,
      cweId,
    );

    if (projectId && isValidate) {
      setIsModalCorrect(true);

      const sendVulnData: IUniqueVulnRequestData = {
        cvss_score: vuln.cvss_score,
        cvss_vector: vuln.cvss_vector,
        description: vuln.description,
        location: vuln.location,
        name: vuln.name,
        negative_consequences: vuln.negative_consequences || [],
        procedure_exploiting: vuln.procedure_exploiting,
        recommendations: vuln.recommendations,
        risk_level: vuln.risk_level,
        additional_info: vuln.additional_info,
        cause_of_occurrence: vuln.cause_of_occurrence,
        cve_id: cveId.split(', ').map((id) => id.trim()),
        cwe_id: cweId.split(', ').map((id) => id.trim()),
        is_killchain: vuln.is_killchain,
        killchain_id: (killChainId && !vuln.is_killchain) ? killChainId.value : null,
      };

      dispatch(
        updateUniqueVulnByIdAsync({
          projectId,
          updateId: vuln.update_id,
          vulnData: sendVulnData,
        }),
      );

      setCveId('');
      setCweId('');

      setCvssAV({
        text: '',
        value: '',
      });
      setCvssAC({
        text: '',
        value: '',
      });
      setCvssPR({
        text: '',
        value: '',
      });
      setCvssUI({
        text: '',
        value: '',
      });
      setCvssS({
        text: '',
        value: '',
      });
      setCvssC({
        text: '',
        value: '',
      });
      setCvssI({
        text: '',
        value: '',
      });
      setCvssA({
        text: '',
        value: '',
      });

      setModalVisible(false);
    }
  };

  useEffect(() => {
    setVuln(vulnData);
    setCveId(vulnData.cve_id?.join(', ') || '');
    setCweId(vulnData.cwe_id?.join(', ') || '');
    setSelectedNegativeOption(
      vulnData.negative_consequences ?
        vulnData.negative_consequences?.map((el) => ({
          label: el,
          value: el.toLowerCase(),
        })) : null,
    );
    const cvssItems = vulnData.cvss_vector.split('/');

    if (cvssItems.length !== 0) {
      setCvssAV({
        text: prepareAttackVectorToFull[cvssItems[1]],
        value: cvssItems[1],
      });
      setCvssAC({
        text: prepareAttackComplexityToFull[cvssItems[2]],
        value: cvssItems[2],
      });
      setCvssPR({
        text: preparePrivilegesRequiredToFull[cvssItems[3]],
        value: cvssItems[3],
      });
      setCvssUI({
        text: prepareUserInteractionToFull[cvssItems[4]],
        value: cvssItems[4],
      });

      setCvssS({
        text: prepareScopeToFull[cvssItems[5]],
        value: cvssItems[5],
      });
      setCvssC({
        text: prepareOtherCVSSToFull[cvssItems[6]],
        value: cvssItems[6]?.split(':')[1],
      });
      setCvssI({
        text: prepareOtherCVSSToFull[cvssItems[7]],
        value: cvssItems[7]?.split(':')[1],
      });
      setCvssA({
        text: prepareOtherCVSSToFull[cvssItems[8]],
        value: cvssItems[8]?.split(':')[1],
      });
    }
  }, [vulnData]);

  useEffect(() => { }, []);

  const onCvssAVPopupItemChange = (item: IPopupItem) => setCvssAV(item);
  const onCvssACPopupItemChange = (item: IPopupItem) => setCvssAC(item);
  const onCvssPRPopupItemChange = (item: IPopupItem) => setCvssPR(item);
  const onCvssUIPopupItemChange = (item: IPopupItem) => setCvssUI(item);
  const onCvssSPopupItemChange = (item: IPopupItem) => setCvssS(item);
  const onCvssCPopupItemChange = (item: IPopupItem) => setCvssC(item);
  const onCvssIPopupItemChange = (item: IPopupItem) => setCvssI(item);
  const onCvssAPopupItemChange = (item: IPopupItem) => setCvssA(item);

  const onNegativeConsequencesChange = (data: any) => {
    const prepareNegativeConsequences = data.map(({ value }: any) => value);

    setVuln({
      ...vuln,
      negative_consequences: prepareNegativeConsequences,
    });
    setSelectedNegativeOption(data);
  };

  const onConfirmCreateModalHandler = () =>
    setCreateVulnModal((prevState) => !prevState);
  const onConfirmResetModalHandler = () =>
    setResetVulnDataModal((prevState) => !prevState);

  return (
    <>
      <Modal
        isModalVisible={isModalVisible}
        setModalVisible={setModalVisible}
        title={localization.modals.vuln.createTitle}
        isFullScreen={true}
      >
        <div className={styles['modal-inputs']}>
          <InputForm
            text={localization.modals.vuln.nameText}
            placeholder={localization.modals.vuln.namePlaceholder}
            value={vuln.name}
            errorMessage={vulnerability_name_error}
            onChange={(event) =>
              setVuln({ ...vuln, name: event.target.value })
            }
            required
          />
          <InputForm
            text={localization.modals.vuln.cveIdText}
            placeholder={localization.modals.vuln.cveIdPlaceholder}
            errorMessage={cve_id_error}
            value={cveId}
            onChange={(event) => setCveId(event.target.value)}
          />
          <InputForm
            text={localization.modals.vuln.cweIdText}
            placeholder={localization.modals.vuln.cweIdPlaceholder}
            value={cweId}
            onChange={(event) => setCweId(event.target.value)}
          />
          <InputForm
            text={localization.modals.vuln.locationText}
            placeholder={
              localization.modals.vuln.locationPlaceholder
            }
            value={vuln.location}
            errorMessage={location_error}
            onChange={(event) => {
              setVuln({ ...vuln, location: event.target.value });
            }}
            required
          />
          <InputForm
            text={localization.modals.vuln.descriptionText}
            placeholder={
              localization.modals.vuln.descriptionPlaceholder
            }
            value={vuln.description}
            errorMessage={description_error}
            onTextareaChange={(event) => {
              setVuln({
                ...vuln,
                description: event.target.value,
              });
            }}
            textarea
            required
          />
          <InputForm
            text={localization.modals.vuln.causeOccurrenceText}
            placeholder={
              localization.modals.vuln.causeOccurrencePlaceholder
            }
            value={vuln.cause_of_occurrence}
            errorMessage={cause_of_occurrence_error}
            onTextareaChange={(event) => {
              setVuln({
                ...vuln,
                cause_of_occurrence: event.target.value,
              });
            }}
            textarea
            required
          />
          <InputForm
            text={localization.modals.vuln.negativeConsequencesText}
            placeholder={
              localization.modals.vuln
                .negativeConsequencesPlaceholder
            }
            errorMessage={negative_consequences_error}
            value={selectedNegativeOption}
            onSelectChange={onNegativeConsequencesChange}
            options={negativeConsequencesTypes}
            isMulti
            required
          />
          <div className={styles['modal-calculator-wrapper']}>
            <div className={styles['modal-calculator-panel']}>
              <InputForm
                text={localization.modals.vuln.attackVectorText}
                placeholder={
                  localization.modals.vuln
                    .attackVectorPlaceholder
                }
                errorMessage={attack_vector_error}
                value={cvssAV.text || ''}
                popupItems={attackVectorPopupItems}
                onClick={showPopupHandler}
                onPopupChange={onCvssAVPopupItemChange}
                onChange={(event) => {
                  setCvssAV({
                    text: event.target.value,
                    value: '',
                  });
                }}
                disabled
                required
              />
              <InputForm
                text={
                  localization.modals.vuln
                    .attackComplexityText
                }
                placeholder={
                  localization.modals.vuln
                    .attackComplexityPlaceholder
                }
                errorMessage={attack_complexity_error}
                value={cvssAC.text || ''}
                popupItems={attackComplexityPopupItems}
                onClick={showPopupHandler}
                onPopupChange={onCvssACPopupItemChange}
                onChange={(event) => {
                  setCvssAC({
                    text: event.target.value,
                    value: '',
                  });
                }}
                disabled
                required
              />
              <InputForm
                text={
                  localization.modals.vuln
                    .privilegesRequiredText
                }
                placeholder={
                  localization.modals.vuln
                    .privilegesRequiredPlaceholder
                }
                errorMessage={privileges_required_error}
                value={cvssPR.text || ''}
                popupItems={privilegesRequiredPopupItems}
                onClick={showPopupHandler}
                onPopupChange={onCvssPRPopupItemChange}
                onChange={(event) => {
                  setCvssPR({
                    text: event.target.value,
                    value: '',
                  });
                }}
                disabled
                required
              />
              <InputForm
                text={
                  localization.modals.vuln.userInteractionText
                }
                placeholder={
                  localization.modals.vuln
                    .userInteractionPlaceholder
                }
                errorMessage={user_interaction_error}
                value={cvssUI.text || ''}
                popupItems={userInteractionPopupItems}
                onClick={showPopupHandler}
                onPopupChange={onCvssUIPopupItemChange}
                onChange={(event) => {
                  setCvssUI({
                    text: event.target.value,
                    value: '',
                  });
                }}
                disabled
                required
              />
            </div>
            <div className={styles['modal-calculator-panel']}>
              <InputForm
                text={localization.modals.vuln.scopeText}
                placeholder={
                  localization.modals.vuln.scopePlaceholder
                }
                errorMessage={scope_error}
                value={cvssS.text || ''}
                popupItems={scopePopupItems}
                onClick={showPopupHandler}
                onPopupChange={onCvssSPopupItemChange}
                onChange={(event) => {
                  setCvssS({
                    text: event.target.value,
                    value: '',
                  });
                }}
                disabled
                required
              />
              <InputForm
                text={
                  localization.modals.vuln.confidentialityText
                }
                placeholder={
                  localization.modals.vuln
                    .confidentialityPlaceholder
                }
                errorMessage={confidentiality_error}
                value={cvssC.text || ''}
                popupItems={confidentialityPopupItems}
                onClick={showPopupHandler}
                onPopupChange={onCvssCPopupItemChange}
                onChange={(event) => {
                  setCvssC({
                    text: event.target.value,
                    value: '',
                  });
                }}
                disabled
                required
              />
              <InputForm
                text={localization.modals.vuln.integrityText}
                placeholder={
                  localization.modals.vuln
                    .integrityPlaceholder
                }
                errorMessage={integrity_error}
                value={cvssI.text || ''}
                popupItems={integrityPopupItems}
                onClick={showPopupHandler}
                onPopupChange={onCvssIPopupItemChange}
                onChange={(event) => {
                  setCvssI({
                    text: event.target.value,
                    value: '',
                  });
                }}
                disabled
                required
              />
              <InputForm
                text={localization.modals.vuln.availabilityText}
                placeholder={
                  localization.modals.vuln
                    .availabilityPlaceholder
                }
                errorMessage={availability_error}
                value={cvssA.text || ''}
                popupItems={availabilityPopupItems}
                onClick={showPopupHandler}
                onPopupChange={onCvssAPopupItemChange}
                onChange={(event) => {
                  setCvssA({
                    text: event.target.value,
                    value: '',
                  });
                }}
                disabled
                required
              />
            </div>
          </div>
          <div className={styles['modal-calculator-info']}>
            <Text className={styles['modal-calculator-info-title']}>
              {`${localization.modals.vuln.vectorString} - ${vuln.cvss_vector
                ? vuln.cvss_vector
                : localization.modals.vuln.vector
              }`}
            </Text>
            {vuln.risk_level && (
              <div
                className={classNames(
                  styles['modal-calculator-info-score'],
                  styles[
                    `modal-calculator-info-score_${vuln.risk_level}`
                  ],
                )}
              >
                <Title variant={TitleVariantEnum.H1}>
                  {vuln.cvss_score !== null &&
                    vuln.cvss_score !== undefined
                    ? vuln.cvss_score.toString()
                    : '-'}
                </Title>
                <Text
                  className={
                    styles[
                      'modal-calculator-info-risk-level'
                    ]
                  }
                  variant={TextVariantEnum.L}
                >
                  {`(${prepareRiskLevelToRu[vuln.risk_level]
                  })`}
                </Text>
              </div>
            )}
          </div>
          <InputForm
            text={localization.modals.vuln.procedureExploitingText}
            placeholder={
              localization.modals.vuln
                .procedureExploitingPlaceholder
            }
            value={vuln.procedure_exploiting}
            errorMessage={procedure_exploiting_error}
            onTextareaChange={(event) => {
              setVuln({
                ...vuln,
                procedure_exploiting: event.target.value,
              });
            }}
            textarea
            required
          />
          <InputForm
            text={localization.modals.vuln.recommendationsText}
            placeholder={
              localization.modals.vuln.recommendationsPlaceholder
            }
            value={vuln.recommendations}
            errorMessage={recommendation_error}
            onTextareaChange={(event) => {
              setVuln({
                ...vuln,
                recommendations: event.target.value,
              });
            }}
            textarea
            required
          />
          <InputForm
            text={localization.modals.additionalInfoText}
            placeholder={
              localization.modals.additionalInfoPlaceholder
            }
            value={vuln.additional_info}
            onTextareaChange={(event) => {
              setVuln({
                ...vuln,
                additional_info: event.target.value,
              });
            }}
            textarea
          />
          <InputForm
            text={'Прикрепить к kill chain'}
            placeholder={
              localization.modals.vuln
                .negativeConsequencesPlaceholder
            }
            errorMessage={''}
            value={killChainId}
            onSelectChange={(data: any) => {
              setKillChainId(data);
            }}
            options={killChainList?.data.map((killChain: any) => ({
              value: killChain.id,
              label: killChain.name,
            }))}
            required
            disabled={vuln.is_killchain}
          />
          <InputForm
            text={'Назначить Kill chain'}
            type={InputTypeEnum.Checkbox}
            value={vuln.is_killchain}
            onChange={(event) => {
              setVuln({
                ...vuln,
                is_killchain: event.currentTarget.checked,
              });
            }}
          />
        </div>
        <div
          className={classNames(
            styles['modal-buttons'],
            styles['modal-buttons_between'],
          )}
        >
          <Button
            buttonText={'Изменить'}
            onClick={onConfirmCreateModalHandler}
          />
          <Button
            type={ButtonTypeEnum.Red}
            buttonText={localization.modals.resetButtonText}
            onClick={onConfirmResetModalHandler}
          />
        </div>
      </Modal>
      <ConfirmModal
        isModalVisible={isCreateVulnModal}
        setModalVisible={setCreateVulnModal}
        text={localization.vuln.confirmVulnText}
        onConfirmClick={addVulnHandler}
        type={ModalTypes.Change}
      />
      <ConfirmModal
        isModalVisible={isResetVulnDataModal}
        setModalVisible={setResetVulnDataModal}
        text={localization.vuln.confirmVulnDataText}
        onConfirmClick={resetVulnDataHandler}
        type={ModalTypes.Reset}
      />
    </>
  );
};

export default ChangeUniqueVuln;
