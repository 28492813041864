import { ComponentProps, FC } from 'react';
import cnb from 'classnames/bind';

import styles from './Atag.module.scss';

interface IAtagProps extends ComponentProps<'a'> {
  text: string
}

export const Atag: FC<IAtagProps> = (props) => {
  const { text, ...otherProps } = props;
  const cx = cnb.bind(styles);
  return <a {...otherProps} className={cx('atag')}>{text}</a>;
};
