import Fancybox from "../../../components/fancybox/Fancybox"
import cn from "classnames/bind"
import { ComponentProps, FC } from "react"
import { localization } from "../../../localization/localization"
import styles from "./ImageBox.module.scss"

interface ImageBoxProps extends ComponentProps<'div'> {
  screenshots?: {
    id: string;
    title: string;
    src: string;
    description: string;
    sequence: number;
  }[];
}



export const ImageBox: FC<ImageBoxProps> = (props) => {
  const { screenshots, ...otherProps } = props
  const cs = cn.bind(styles)


  if (screenshots === undefined || screenshots.length === 0) {
    return <></>
  }
  return (
    <Fancybox
      options={{
        Carousel: {
          infinite: false,
        },
      }}
      {...otherProps}
    >

      {screenshots.map((screenshot) => (
        <div className={cs('box-item')}>
          <a
            onClick={() => { }}
            key={screenshot.id}
            data-fancybox="gallery"
            href={screenshot.src}
            className={cs('box-item_link')}
          >
            <img
              className={cs('box-item_image')}
              src={screenshot.src}
              alt={screenshot.title}
            />
            {/*<div*/}
            {/*  id={screenshot.id}*/}
            {/*  className={cs('')}*/}
            {/*>*/}
            {/*  <img*/}
            {/*    id={screenshot.id}*/}
            {/*    className={cs('')}*/}
            {/*    src="/assets/images/close.png"*/}
            {/*    alt={*/}
            {/*      localization.common*/}
            {/*        .closeAlt*/}
            {/*    }*/}
            {/*  />*/}
            {/*</div>*/}
          </a>
          <div>
            <h3
              className={
                styles[
                "vuln-body-info-secondary-screenshot-header"
                ]
              }
            >
               Описание:
            </h3>
            <p
              className={
                styles[
                "vuln-body-info-secondary-screenshot-description"
                ]
              }
            >
              {screenshot.description
                ? screenshot.description
                : "-"}
            </p>
          </div>
        </div>
      ))}
    </Fancybox>
  )
}
