import React, {Dispatch, FC, SetStateAction, useState} from 'react';

import Modal from '../Modal';
import InputForm from '../../inputForm/InputForm';
import {InputTypeEnum} from '../../input/InputTypes';
import Button from '../../button/Button';
import {useAppDispatch} from '../../../hooks/useAppDispatch';
import pageStyle from '../../../pages/objects/objectsPage/ObjectsPage.module.scss';
import {uploadExcelObjects} from '../../../store/objects/objectsAsync';

import style from './UploadFileExcelModal.module.scss';

export interface IUploadFileProps {
    isVisible: boolean;
    setVisible: Dispatch<SetStateAction<boolean>>;
    projectId: string;
}


export const UploadExcelModal: FC<IUploadFileProps> = (props) => {
    const {isVisible, setVisible, projectId} = props;

    const [uploadFile, setUploadFile] = useState<File | null>(null);

    const dispatch = useAppDispatch();

    const resetModal = () => {
        setUploadFile(null);
        setVisible(false);
    };

    const onClickHandleImport = () => {
        if (uploadFile) {
            dispatch(uploadExcelObjects({file: uploadFile, projectId}));
        }
    };

    const onChangeHandle = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.item(0);
        if (file) {
            setUploadFile(file);
        }
    };

    return (
        <Modal isModalVisible={isVisible} setModalVisible={setVisible} title={'Импорт объектов'}>
            <div className={style['upload-excel']}>
                <InputForm
                    text={''} isMulti={false}
                    type={InputTypeEnum.File}
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    onChange={onChangeHandle}
                />
                <a
                    download={true}
                    href="/filestore/report_templates/Upload_objects.xlsx"
                    className={(pageStyle['excel-block_link'])}
                > Скачать шаблон</a>
                <Button buttonText={'Отмена'} onClick={resetModal}/>
                <Button buttonText={'Импортировать'} onClick={onClickHandleImport}/>
            </div>

        </Modal>
    );
};

