import { FC, useEffect, useState } from 'react';

import styles from '../../../components/modals/Modal.module.scss';
import InputForm from '../../../components/inputForm/InputForm';
import { localization } from '../../../localization/localization';
import {
  attackComplexityPopupItems,
  attackVectorPopupItems,
  availabilityPopupItems,
  confidentialityPopupItems,
  integrityPopupItems, prepareAttackComplexityToFull, prepareAttackVectorToFull, prepareOtherCVSSToFull,
  preparePrivilegesRequiredToFull,
  prepareScopeToFull,
  prepareUserInteractionToFull,
  privilegesRequiredPopupItems,
  scopePopupItems,
  userInteractionPopupItems,
} from '../../../constants/vulns';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { selectVulnErrors } from '../../../store/vulns/vulnsSelectors';
import { IPopupItem } from '../../../components/popup/PopupTypes';
import { useShowPopup } from '../../../hooks/useShowPopup';
import { CVSSCalculator } from '../lib/cvssLib';
import { RiskLevelType } from '../../../store/templates/templatesTypes';
import { CVSSDataType } from '../lib/CVSSCalculator.types';


interface ICvssCalculatorProps {
  CVSSVector?: string | null;
  setCVSSValue: (value: CVSSDataType) => void;
}

export const CvssCalculator: FC<ICvssCalculatorProps> = (props) => {

  const { CVSSVector, setCVSSValue } = props;

  const {
    attack_vector_error,
    attack_complexity_error,
    privileges_required_error,
    user_interaction_error,
    integrity_error,
    availability_error,
    confidentiality_error,
    scope_error,
  } = useAppSelector(selectVulnErrors);


  const [CVSSValueTemp, setCVSSValueTemp] = useState<{ risk_level: RiskLevelType | null; cvss_vector: string; cvss_score: number }>({
    risk_level: null,
    cvss_vector: '',
    cvss_score: 0,
  });

  useEffect(() => {
    setCVSSValue(CVSSValueTemp);
  }, [CVSSValueTemp]);

  const [cvssAV, setCvssAV] = useState<IPopupItem>({
    text: '',
    value: '',
  });

  const [cvssAC, setCvssAC] = useState<IPopupItem>({
    text: '',
    value: '',
  });
  const [cvssPR, setCvssPR] = useState<IPopupItem>({
    text: '',
    value: '',
  });
  const [cvssUI, setCvssUI] = useState<IPopupItem>({
    text: '',
    value: '',
  });
  const [cvssS, setCvssS] = useState<IPopupItem>({
    text: '',
    value: '',
  });
  const [cvssC, setCvssC] = useState<IPopupItem>({
    text: '',
    value: '',
  });
  const [cvssI, setCvssI] = useState<IPopupItem>({
    text: '',
    value: '',
  });
  const [cvssA, setCvssA] = useState<IPopupItem>({
    text: '',
    value: '',
  });
  const { showPopupHandler } = useShowPopup();

  useEffect(() => {
    if (CVSSVector) {

      const cvssItems = CVSSVector.split('/');
      if (cvssItems.length !== 0) {
        setCvssAV({ text: prepareAttackVectorToFull[cvssItems[1]], value: cvssItems[1] });
        setCvssAC({ text: prepareAttackComplexityToFull[cvssItems[2]], value: cvssItems[2] });
        setCvssPR({ text: preparePrivilegesRequiredToFull[cvssItems[3]], value: cvssItems[3] });
        setCvssUI({ text: prepareUserInteractionToFull[cvssItems[4]], value: cvssItems[4] });

        setCvssS({ text: prepareScopeToFull[cvssItems[5]], value: cvssItems[5] });
        setCvssC({ text: prepareOtherCVSSToFull[cvssItems[6]], value: cvssItems[6]?.split(':')[1] });
        setCvssI({ text: prepareOtherCVSSToFull[cvssItems[7]], value: cvssItems[7]?.split(':')[1] });
        setCvssA({ text: prepareOtherCVSSToFull[cvssItems[8]], value: cvssItems[8]?.split(':')[1] });
      }
    }
    else {
      setCvssAV({ text: '', value: '' });
      setCvssAC({ text: '', value: '' });
      setCvssPR({ text: '', value: '' });
      setCvssUI({ text: '', value: '' });
      setCvssS({ text: '', value: '' });
      setCvssC({ text: '', value: '' });
      setCvssI({ text: '', value: '' });
      setCvssA({ text: '', value: '' });
    }

  }, [CVSSVector]);



  useEffect(() => {
    const cvss = CVSSCalculator(cvssAV, cvssAC, cvssPR, cvssUI, cvssS, cvssC, cvssI, cvssA);
    if (cvss) {
      setCVSSValueTemp(cvss);
    }
  }, [cvssAV, cvssAC, cvssPR, cvssUI, cvssS, cvssC, cvssI, cvssA]);

  const onCvssAVPopupItemChange = (item: IPopupItem) => setCvssAV(item);
  const onCvssACPopupItemChange = (item: IPopupItem) => setCvssAC(item);
  const onCvssPRPopupItemChange = (item: IPopupItem) => setCvssPR(item);
  const onCvssUIPopupItemChange = (item: IPopupItem) => setCvssUI(item);
  const onCvssSPopupItemChange = (item: IPopupItem) => setCvssS(item);
  const onCvssCPopupItemChange = (item: IPopupItem) => setCvssC(item);
  const onCvssIPopupItemChange = (item: IPopupItem) => setCvssI(item);
  const onCvssAPopupItemChange = (item: IPopupItem) => setCvssA(item);


  return (
    <div className={styles['modal-calculator-wrapper']}>
      <div className={styles['modal-calculator-panel']}>
        <InputForm
          text={localization.modals.vuln.attackVectorText}
          placeholder={localization.modals.vuln.attackVectorPlaceholder}
          errorMessage={attack_vector_error}
          value={cvssAV.text || ''}
          popupItems={attackVectorPopupItems}
          onClick={showPopupHandler}
          onPopupChange={onCvssAVPopupItemChange}
          onChange={(event) => {
            setCvssAV({ text: event.target.value, value: '' });
          }}
          disabled
          required
        />
        <InputForm
          text={localization.modals.vuln.attackComplexityText}
          placeholder={localization.modals.vuln.attackComplexityPlaceholder}
          errorMessage={attack_complexity_error}
          value={cvssAC.text || ''}
          popupItems={attackComplexityPopupItems}
          onClick={showPopupHandler}
          onPopupChange={onCvssACPopupItemChange}
          onChange={(event) => {
            setCvssAC({ text: event.target.value, value: '' });
          }}
          disabled
          required
        />
        <InputForm
          text={localization.modals.vuln.privilegesRequiredText}
          placeholder={localization.modals.vuln.privilegesRequiredPlaceholder}
          errorMessage={privileges_required_error}
          value={cvssPR.text || ''}
          popupItems={privilegesRequiredPopupItems}
          onClick={showPopupHandler}
          onPopupChange={onCvssPRPopupItemChange}
          onChange={(event) => {
            setCvssPR({ text: event.target.value, value: '' });
          }}
          disabled
          required
        />
        <InputForm
          text={localization.modals.vuln.userInteractionText}
          placeholder={localization.modals.vuln.userInteractionPlaceholder}
          errorMessage={user_interaction_error}
          value={cvssUI.text || ''}
          popupItems={userInteractionPopupItems}
          onClick={showPopupHandler}
          onPopupChange={onCvssUIPopupItemChange}
          onChange={(event) => {
            setCvssUI({ text: event.target.value, value: '' });
          }}
          disabled
          required
        />
      </div>
      <div className={styles['modal-calculator-panel']}>
        <InputForm
          text={localization.modals.vuln.scopeText}
          placeholder={localization.modals.vuln.scopePlaceholder}
          errorMessage={scope_error}
          value={cvssS.text || ''}
          popupItems={scopePopupItems}
          onClick={showPopupHandler}
          onPopupChange={onCvssSPopupItemChange}
          onChange={(event) => {
            setCvssS({ text: event.target.value, value: '' });
          }}
          disabled
          required
        />
        <InputForm
          text={localization.modals.vuln.confidentialityText}
          placeholder={localization.modals.vuln.confidentialityPlaceholder}
          errorMessage={confidentiality_error}
          value={cvssC.text || ''}
          popupItems={confidentialityPopupItems}
          onClick={showPopupHandler}
          onPopupChange={onCvssCPopupItemChange}
          onChange={(event) => {
            setCvssC({ text: event.target.value, value: '' });
          }}
          disabled
          required
        />
        <InputForm
          text={localization.modals.vuln.integrityText}
          placeholder={localization.modals.vuln.integrityPlaceholder}
          errorMessage={integrity_error}
          value={cvssI.text || ''}
          popupItems={integrityPopupItems}
          onClick={showPopupHandler}
          onPopupChange={onCvssIPopupItemChange}
          onChange={(event) => {
            setCvssI({ text: event.target.value, value: '' });
          }}
          disabled
          required
        />
        <InputForm
          text={localization.modals.vuln.availabilityText}
          placeholder={localization.modals.vuln.availabilityPlaceholder}
          errorMessage={availability_error}
          value={cvssA.text || ''}
          popupItems={availabilityPopupItems}
          onClick={showPopupHandler}
          onPopupChange={onCvssAPopupItemChange}
          onChange={(event) => {
            setCvssA({ text: event.target.value, value: '' });
          }}
          disabled
          required
        />
      </div>
    </div>
  );
};

