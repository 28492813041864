import { FC } from 'react';
import cn from 'classnames/bind';

import { navButtonProps } from './NavButtonProps';

import style from './NavButton.module.scss';


export const NavButton: FC<navButtonProps> = (props) => {
  const { text, isActive, isComplite, buttonType } = props;
  const cx = cn.bind(style)
  return (
    <button
      className={
        cx({
          'navButton-nav': buttonType === 'nav',
          'navButton-nav__active': isActive && buttonType === 'nav',
          'navButton-list': buttonType === 'list',
          'navButton-list__active': buttonType === 'list' && isActive,
          'navButton-list__complite': buttonType === 'list' && !isActive && isComplite,
        })
      }
      {...props}
    >
      {text}
    </button >
  );
};
