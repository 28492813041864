import { createAsyncThunk } from '@reduxjs/toolkit';

import { OBJECTS_ROUTES } from '../../constants/objects';
import { PROJECTS_ROUTES } from '../../constants/projects';

import { IEntityResponse, IGetOptionalRequest, IThunkApiConfigProps } from '../storeTypes';
import { ROLES_ROUTES } from '../../constants/roles';
import { IUser } from '../auth/authTypes';

import { ICreateVulnsForObjectsResponse, ICreateVulnsForObjectsResponseData, ICreateVulnsForObjectsSendData } from '../vulns/vulnsTypes';

import {
  IChangeObjectResponse,
  ICount,
  ICreatePentesterRequest,
  ICreatePentestersResponse,
  IDeleteObjectResponse,
  IDeletePentesterRequest,
  IGetObjectLogsResponse,
  IGetObjectResponse,
  IGetObjectsResponse,
  IObjectResponse,
  IPentesterRequest,
  IRequestChangeObject,
  IRequestCreateObject,
  IRequestObjectById, IUploadExcelRequest,
  ObjectTypes,
  PentersTypes,
} from './objectsTypes';

export const getObjects = createAsyncThunk<IGetObjectsResponse, IGetOptionalRequest, IThunkApiConfigProps>(
  ObjectTypes.GET,
  async ({ id, objectType, filters, pagination, sortParams }, { rejectWithValue, extra }) => {
    try {
      const paginationString = pagination ? `offset=${pagination.offset}&limit=${pagination.limit}` : '';
      const response = await extra.api.get(
        `${PROJECTS_ROUTES.FULL_URL}/${id}/${OBJECTS_ROUTES.URL}/${objectType}?${paginationString}${filters ? `&${filters}` : ''}&${sortParams ? sortParams : ''}`);

      return { data: response.data, objectType };
    } catch (error: any) {
      return rejectWithValue(error.response.status);
    }
  },
);

export const getAllObjects = createAsyncThunk<IGetObjectsResponse, IGetOptionalRequest, IThunkApiConfigProps>(
  ObjectTypes.GET_ALL,
  async ({ id, objectType, filters }, { rejectWithValue, extra }) => {
    try {
      const response = await extra.api.get(
        `${PROJECTS_ROUTES.FULL_URL}/${id}/${OBJECTS_ROUTES.URL}/${objectType}${filters ? `?${filters}` : ''}`);

      return { data: response.data, objectType };
    } catch (error: any) {
      return rejectWithValue(error.response.status);
    }
  },
);

export const getBaseObjects = createAsyncThunk<IGetObjectsResponse, IGetOptionalRequest, IThunkApiConfigProps>(
  ObjectTypes.GET_BASE,
  async ({ id, objectType, filters, pagination, sortParams }, { rejectWithValue, extra }) => {
    try {
      const paginationString = pagination ? `offset=${pagination.offset}&limit=${pagination.limit}` : '';
      const response = await extra.api.get(
        `${PROJECTS_ROUTES.FULL_URL}/${id}/${OBJECTS_ROUTES.URL}/${OBJECTS_ROUTES.BASE}?${paginationString}${filters ? `&${filters}` : ''}${sortParams ? '&' + sortParams : ''}`,
      );

      return { data: response.data, objectType };
    } catch (error: any) {
      return rejectWithValue(error.response.status);
    }
  },
);

export const getObjectCounts = createAsyncThunk<ICount[], IGetOptionalRequest, IThunkApiConfigProps>(
  ObjectTypes.GET_COUNTS,
  async ({ id, filters }, { rejectWithValue, extra }) => {
    try {
      const response = await extra.api.get(
        `${PROJECTS_ROUTES.FULL_URL}/${id}/${OBJECTS_ROUTES.URL}/${filters ? '?' + filters : ''}`);

      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.status);
    }
  },
);

export const getObjectById = createAsyncThunk<IGetObjectResponse, IRequestObjectById, IThunkApiConfigProps>(
  ObjectTypes.GET_ONE,
  async ({ objectType, objectId, projectId }, { rejectWithValue, extra }) => {
    try {
      const response = await extra.api.get(
        `${PROJECTS_ROUTES.FULL_URL}/${projectId}/${OBJECTS_ROUTES.URL}/${objectType}/${objectId}`);

      return { object: response.data, objectType };
    } catch (error: any) {
      return rejectWithValue(error.response.status);
    }
  },
);

export const getObjectLogs = createAsyncThunk<IGetObjectLogsResponse, IRequestObjectById, IThunkApiConfigProps>(
  ObjectTypes.GET_LOGS,
  async ({ objectType, objectId, projectId }, { rejectWithValue, extra }) => {
    try {
      // @ts-ignore
      const response = await extra.api.get(
        `${PROJECTS_ROUTES.FULL_URL}/${projectId}/${OBJECTS_ROUTES.URL}/${objectType}/${objectId}/${PROJECTS_ROUTES.LOGS}`);

      return { data: response.data.data, objectType };
    } catch (error: any) {
      return rejectWithValue(error.response.status);
    }
  },
);

export const createObject = createAsyncThunk<IObjectResponse, IRequestCreateObject, IThunkApiConfigProps>(
  ObjectTypes.POST,
  async ({ objectType, projectId, object }, { rejectWithValue, extra }) => {
    try {
      const response = await extra.api.post(
        `${PROJECTS_ROUTES.FULL_URL}/${projectId}/${OBJECTS_ROUTES.URL}/${objectType}`,
        object);

      return { object: response.data, status: response.status, objectType };
    } catch (error: any) {
      return rejectWithValue(error.response.status);
    }
  },
);

export const deleteObject = createAsyncThunk<IDeleteObjectResponse, IRequestObjectById, IThunkApiConfigProps>(
  ObjectTypes.DELETE,
  async ({ projectId, objectId, objectType }, { rejectWithValue, extra }) => {
    try {
      const response = await extra.api.delete(
        `${PROJECTS_ROUTES.FULL_URL}/${projectId}/${OBJECTS_ROUTES.URL}/${objectType}/${objectId}`);

      return { id: objectId, status: response.status, objectType };
    } catch (error: any) {
      return rejectWithValue(error.response.status);
    }
  },
);

export const changeObject = createAsyncThunk<IChangeObjectResponse, IRequestChangeObject, IThunkApiConfigProps>(
  ObjectTypes.PATCH,
  async ({ projectId, objectType, objectId, object }, { rejectWithValue, extra }) => {
    try {
      const response = await extra.api.patch(
        `${PROJECTS_ROUTES.FULL_URL}/${projectId}/${OBJECTS_ROUTES.URL}/${objectType}/${objectId}`,
        object);

      return { object: response.data, status: response.status, objectType, currentObjectType: object.object_type };
    } catch (error: any) {
      return rejectWithValue(error.response.status);
    }
  },
);



export const getPentesters = createAsyncThunk<IUser[], string, IThunkApiConfigProps>(
  PentersTypes.GET,
  async (projectId, { rejectWithValue, extra }) => {
    try {
      const response = await extra.api.get(
        `${ROLES_ROUTES.PENTESTERS}?project_id=${projectId}`);

      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.status);
    }
  },
);

export const getPentesterInfo = createAsyncThunk<IUser, IPentesterRequest, IThunkApiConfigProps>(
  PentersTypes.GET_INFO,
  async ({ pentesterId }, { rejectWithValue, extra }) => {
    try {
      const response = await extra.api.get(`${ROLES_ROUTES.PENTESTERS}/${pentesterId}`);

      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.status);
    }
  },
);

export const createPentester = createAsyncThunk<IEntityResponse, IDeletePentesterRequest, IThunkApiConfigProps>(
  PentersTypes.POST,
  async ({ objectId, projectId, pentesterIds }, { rejectWithValue, extra }) => {
    try {
      const response = await extra.api.post(
        `${OBJECTS_ROUTES.FULL_URL}/${objectId}/${ROLES_ROUTES.ASSIGN_PENTESTER}?project_id=${projectId}${pentesterIds}`,
        {});

      return { status: response.status };
    } catch (error: any) {
      return rejectWithValue(error.response.status);
    }
  },
);

export const createPentesters = createAsyncThunk<ICreatePentestersResponse[], ICreatePentesterRequest, IThunkApiConfigProps>(
  PentersTypes.POSTS,
  async ({ objectIds, projectId, pentesterIds }, { rejectWithValue, extra }) => {
    try {
      const response = await extra.api.post(
        `${OBJECTS_ROUTES.FULL_URL}/${ROLES_ROUTES.ASSIGN_PENTESTER}?project_id=${projectId}${objectIds}${pentesterIds}`,
        {});

      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.status);
    }
  },
);

export const deletePentester = createAsyncThunk<IEntityResponse, IDeletePentesterRequest, IThunkApiConfigProps>(
  PentersTypes.DELETE,
  async ({ objectId, projectId, pentesterIds }, { rejectWithValue, extra }) => {
    try {
      const response = await extra.api.delete(
        `${OBJECTS_ROUTES.FULL_URL}/${objectId}/${ROLES_ROUTES.DELETE_PENTESTER}?project_id=${projectId}${pentesterIds}`);

      return { status: response.status };
    } catch (error: any) {
      return rejectWithValue(error.response.status);
    }
  },
);

export const uploadExcelObjects = createAsyncThunk<IEntityResponse, IUploadExcelRequest, IThunkApiConfigProps>(
  ObjectTypes.UPLOAD_EXCEL,
  async ({ projectId, file }, { rejectWithValue, extra }) => {
    try {
      const formData = new FormData();
      formData.append('file', file);

      const response = await extra.api.post(
        `${PROJECTS_ROUTES.FULL_URL}/${projectId}/${OBJECTS_ROUTES.URL}/${OBJECTS_ROUTES.BASE}/upload_excel`,
        formData,
      );
      return { status: response.status };
    } catch (error: any) {
      return rejectWithValue(error.response.status);
    }
  },
);


export const createVulnsForObjects = createAsyncThunk<{ msg: string; objects: string[] }, ICreateVulnsForObjectsResponse, IThunkApiConfigProps>(
  'createVulnsForObjects',
  async (
    {
      objectsList,
      projectId,
      dataValue,
      screenshots,
    },
    {
      rejectWithValue, extra,
    }) => {
    try {

      const responseData: ICreateVulnsForObjectsResponseData = { vulns: [], variables: { PATH: dataValue.path !== undefined ? dataValue.path : '' } };
      for (const object of objectsList) {
        const screenshotsObject = screenshots[object.id];
        const screenshotData = screenshotsObject ? Object.keys(screenshotsObject).map(fileName => {
          return {
            imgbase64: screenshotsObject[fileName].base64data.split(',')[1],
            description: screenshotsObject[fileName].description,
            sequence: screenshotsObject[fileName].sequence,
          };
        }) : [];
        const objectData: ICreateVulnsForObjectsSendData = {
          template_id: dataValue.template_id || '',
          name: dataValue.name || '',
          cve_id: dataValue.cve_id ? dataValue.cve_id : [],
          cwe_id: dataValue.cwe_id ? dataValue.cwe_id : [],
          location: dataValue.location || '',
          description: dataValue.description || '',
          cause_of_occurrence: dataValue.cause_of_occurrence || '',
          negative_consequences: dataValue.negative_consequences ? dataValue.negative_consequences?.join(',') : '',
          cvss_score: typeof dataValue.cvss_score === 'string' ? parseFloat(dataValue.cvss_score) : 0,
          cvss_vector: dataValue.cvss_vector ? dataValue.cvss_vector : '',
          risk_level: dataValue.risk_level ? dataValue.risk_level : null,
          procedure_exploiting: dataValue.procedure_exploiting ? dataValue.procedure_exploiting : '',
          recommendations: dataValue.recommendations ? dataValue.recommendations : '',
          additional_info: dataValue.additional_info ? dataValue.additional_info : '',
          is_delete: false,
          object_id: object.id,
          screenshots: screenshotData,
          object_type: object.type,
          status: 'new',
        };
        responseData.vulns.push(objectData);
      }

      const response = await extra.api.post(
        `${PROJECTS_ROUTES.FULL_URL}/${projectId}/objects/add_vulns`,
        responseData,
      );
      return { msg: response.data.msg, objects: objectsList.map(el => el.id) };

    } catch (error: any) {
      return rejectWithValue(error.response.status);
    }
  },
);
