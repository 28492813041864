import { createAsyncThunk } from '@reduxjs/toolkit';

import { PROJECTS_ROUTES } from '../../constants/projects';


import { IEntityResponse, IGetOptionalRequest, IThunkApiConfigProps } from '../storeTypes';
import { ROLES_ROUTES } from '../../constants/roles';
import { IUser } from '../auth/authTypes';

import {
  IActivePentester,
  ICreateManagerRequest,
  ICreateProjectRequest,
  ICreateTeamleadRequest,
  IDeleteObjectsOrProjectsRequest,
  IDeleteProjectResponse,
  IGetProjectLogsResponse,
  IGetProjectResponse, IManagerRequest, IManagerResponse,
  IProject,
  IProjectResponse,
  ITeamleadRequest,
  ITeamleadResponse, ManagerTypes,
  ProjectTypes,
  TeamleadTypes,
} from './projectsTypes';

export const getProjects = createAsyncThunk<IGetProjectResponse, IGetOptionalRequest, IThunkApiConfigProps>(
  ProjectTypes.GET,
  async ({ filters, pagination, sortParams }, { rejectWithValue, extra }) => {
    try {
      const paginationString = pagination ? `offset=${pagination.offset}&limit=${pagination.limit}` : '';
      const response = await extra.api.get(
        `${PROJECTS_ROUTES.FULL_URL}/?${paginationString}${filters ? `&${filters}` : ''}&${sortParams ? sortParams : ''}`);

      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.status);
    }
  },
);

export const getAllProjects = createAsyncThunk<IGetProjectResponse, IGetOptionalRequest, IThunkApiConfigProps>(
  ProjectTypes.GET_ALL,
  async ({ filters }, { rejectWithValue, extra }) => {
    try {
      const response = await extra.api.get(
        `${PROJECTS_ROUTES.FULL_URL}/${filters ? `?${filters}` : ''}`);

      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.status);
    }
  },
);

export const getProjectById = createAsyncThunk<IProject, string, IThunkApiConfigProps>(
  ProjectTypes.GET_ONE,
  async (id, { rejectWithValue, extra }) => {
    try {
      const response = await extra.api.get(`${PROJECTS_ROUTES.FULL_URL}/${id}`);

      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.status);
    }
  },
);

export const getProjectLogs = createAsyncThunk<IGetProjectLogsResponse, string, IThunkApiConfigProps>(
  ProjectTypes.GET_LOGS,
  async (id, { rejectWithValue, extra }) => {
    try {
      const response = await extra.api.get(`${PROJECTS_ROUTES.FULL_URL}/${id}/${PROJECTS_ROUTES.LOGS}`);

      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.status);
    }
  },
);

export const restoreProject = createAsyncThunk<IEntityResponse, string, IThunkApiConfigProps>(
  ProjectTypes.RESTORE,
  async (id, { rejectWithValue, extra }) => {
    try {
      const response = await extra.api.get(`${PROJECTS_ROUTES.FULL_URL}/${id}/${PROJECTS_ROUTES.RESTORE}`);

      return { status: response.status };
    } catch (error: any) {
      return rejectWithValue(error.response.status);
    }
  },
);

export const createProject = createAsyncThunk<IProjectResponse, ICreateProjectRequest, IThunkApiConfigProps>(
  ProjectTypes.POST,
  async ({ project, customerId }, { rejectWithValue, extra }) => {
    try {
      const response = await extra.api.post(`${PROJECTS_ROUTES.FULL_URL}/?customer_id=${customerId}`,
        project);

      return { project: response.data, status: response.status };
    } catch (error: any) {
      return rejectWithValue(error.response.status);
    }
  },
);

export const deleteProject = createAsyncThunk<IDeleteProjectResponse, string, IThunkApiConfigProps>(
  ProjectTypes.DELETE,
  async (id: string, { rejectWithValue, extra }) => {
    try {
      const response = await extra.api.delete(`${PROJECTS_ROUTES.FULL_URL}/${id}`);

      return { id, status: response.status };
    } catch (error: any) {
      return rejectWithValue(error.response.status);
    }
  },
);

export const changeProject = createAsyncThunk<IProjectResponse, IProject, IThunkApiConfigProps>(
  ProjectTypes.PATCH,
  async (project: IProject, { rejectWithValue, extra }) => {
    try {
      const response = await extra.api.patch(`${PROJECTS_ROUTES.FULL_URL}/${project.id}`, project);

      return { project: response.data, status: response.status };
    } catch (error: any) {
      return rejectWithValue(error.response.status);
    }
  },
);



export const getTeamleads = createAsyncThunk<IUser[], undefined, IThunkApiConfigProps>(
  TeamleadTypes.GET,
  async (_, { rejectWithValue, extra }) => {
    try {
      const response = await extra.api.get(ROLES_ROUTES.TEAMLEADS);

      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.status);
    }
  },
);

export const getTeamleadInfo = createAsyncThunk<IUser, ITeamleadRequest, IThunkApiConfigProps>(
  TeamleadTypes.GET_INFO,
  async ({ teamleadId }, { rejectWithValue, extra }) => {
    try {
      const response = await extra.api.get(`${ROLES_ROUTES.TEAMLEADS}/${teamleadId}`);

      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.status);
    }
  },
);

export const createTeamlead = createAsyncThunk<ITeamleadResponse, ICreateTeamleadRequest, IThunkApiConfigProps>(
  TeamleadTypes.POST,
  async ({ projectId, teamleadId }, { rejectWithValue, extra }) => {
    try {
      const response = await extra.api.post(
        `${PROJECTS_ROUTES.FULL_URL}/${projectId}/${ROLES_ROUTES.ASSIGN_TEAMLEAD}?teamlead_id=${teamleadId}`,
        undefined);

      return { teamleadId, status: response.status };
    } catch (error: any) {
      return rejectWithValue(error.response.status);
    }
  },
);

export const deleteTeamlead = createAsyncThunk<IEntityResponse, string, IThunkApiConfigProps>(
  TeamleadTypes.DELETE,
  async (id, { rejectWithValue, extra }) => {
    try {
      const response = await extra.api.delete(
        `${PROJECTS_ROUTES.FULL_URL}/${id}/${ROLES_ROUTES.DELETE_TEAMLEAD}`);

      return { status: response.status };
    } catch (error: any) {
      return rejectWithValue(error.response.status);
    }
  },
);



export const getManagers = createAsyncThunk<IUser[], undefined, IThunkApiConfigProps>(
  ManagerTypes.GET,
  async (_, { rejectWithValue, extra }) => {
    try {
      const response = await extra.api.get(ROLES_ROUTES.MANAGERS);

      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.status);
    }
  },
);

export const getManagerInfo = createAsyncThunk<IUser, IManagerRequest, IThunkApiConfigProps>(
  ManagerTypes.GET_INFO,
  async ({ managerId }, { rejectWithValue, extra }) => {
    try {
      const response = await extra.api.get(`${ROLES_ROUTES.MANAGERS}/${managerId}`);

      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.status);
    }
  },
);

export const createManager = createAsyncThunk<IManagerResponse, ICreateManagerRequest, IThunkApiConfigProps>(
  ManagerTypes.POST,
  async ({ projectId, managerId }, { rejectWithValue, extra }) => {
    try {
      const response = await extra.api.post(
        `${PROJECTS_ROUTES.FULL_URL}/${projectId}/${ROLES_ROUTES.ASSIGN_MANAGER}?manager_id=${managerId}`,
        undefined);

      return { managerId, status: response.status };
    } catch (error: any) {
      return rejectWithValue(error.response.status);
    }
  },
);

export const deleteManager = createAsyncThunk<IEntityResponse, string, IThunkApiConfigProps>(
  ManagerTypes.DELETE,
  async (id, { rejectWithValue, extra }) => {
    try {
      const response = await extra.api.delete(
        `${PROJECTS_ROUTES.FULL_URL}/${id}/${ROLES_ROUTES.DELETE_MANAGER}`);

      return { status: response.status };
    } catch (error: any) {
      return rejectWithValue(error.response.status);
    }
  },
);


export const getActivePentesters = createAsyncThunk<IActivePentester[], string, IThunkApiConfigProps>(
  ManagerTypes.ACTIVE_PENTESTERS,
  async (id, { rejectWithValue, extra }) => {
    try {
      const response = await extra.api.get(
        `${PROJECTS_ROUTES.FULL_URL}/${id}/${ROLES_ROUTES.ACTIVE_PENTESTERS}`,
      );
      return response.data;
    }
    catch (error: any) {
      return rejectWithValue(error.response.status);
    }
  },
);

export const deleteObjectsOrVulns = createAsyncThunk<{ msg: string }, IDeleteObjectsOrProjectsRequest, IThunkApiConfigProps>(
  'projects/deleteObjectsOrPentesters',
  async ({ projectId, data }, { rejectWithValue, extra }) => {
    try {
      const response = await extra.api.post(
        `${PROJECTS_ROUTES.FULL_URL}/${projectId}/mass_deletion`,
        data,
      );
      return response.data;
    }
    catch (error: any) {
      return rejectWithValue(error.response.status);
    }
  },
);

export const dataFromReport = createAsyncThunk<{ msg: string }, string, IThunkApiConfigProps>(
  'projects/dataFromReport',
  async (projectId, { rejectWithValue, extra }) => {
    try {
      const response = await extra.api.get(
        `${PROJECTS_ROUTES.FULL_URL}/${projectId}/data_from_report`,
      );
      return response.data;
    }
    catch (error: any) {
      return rejectWithValue(error.response.status);
    }
  },
);
