import { ComponentProps, FC } from 'react';

import cn from 'classnames';

import { Spinner } from '../Spiner/Spinner';

import style from './ProgressUpload.module.scss';



interface ProgressUploadProps extends ComponentProps<'div'> {
    isHidden: boolean;
    isLoad: boolean;
    isComplete: boolean;
}

export  const ProgressUpload: FC<ProgressUploadProps> = (props) => {
    const {isHidden, isComplete, isLoad} = props;
    return (
        <div {...props} className={cn(style['ProgressUpload'])}>
            {!isHidden && <>
                {isLoad 
                    ? <Spinner /> 
                    : <span className={cn(style['ProgressUpload_item'], !isComplete ? style['ProgressUpload_item__error']: '')}>
                        {isComplete?'✓':'X' }
                    </span> }
            </>
            }            
        </div>
    );
};
