import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ITemplateById, ITemplatesListItem, ITemplatesStore } from './templatesTypes';
import {
  changeStatusTemplate,
  createTemplate,
  deleteTemplate,
  getTemplateById,
  getTemplates,
  updateTemplate, uploadTemplateFromFile,
} from './templatesAsync';

const initialState: ITemplatesStore = {
  templates: [],
  templateById: {
    id: '',
    tpl_name: '',
    name: '',
    cve_id: '',
    cwe_id: '',
    location: '',
    description: '',
    cause_of_occurrence: '',
    negative_consequences: '',
    cvss_score: '',
    cvss_vector: '',
    procedure_exploiting: '',
    recommendations: '',
    risk_level: null,
    additional_info: '',
    status: 'recheck',
    is_delete: false,
  },
  templatesIsLoad: true,
  templateByIdIsLoad: false,
  currentTemplateID: '',
  sendStatus: {
    isSuccess: true,
    message: '',
    popupIsActive: false,
  },
};

export const templateSlice = createSlice({
  name: 'templates',
  initialState,
  reducers: {
    resetTemplate: (state) => {
      state.templateById = {
        id: '',
        tpl_name: '',
        name: '',
        cve_id: '',
        cwe_id: '',
        location: '',
        description: '',
        cause_of_occurrence: '',
        negative_consequences: '',
        risk_level: null,
        cvss_score: '',
        cvss_vector: '',
        procedure_exploiting: '',
        recommendations: '',
        additional_info: '',
        status: 'recheck',
        is_delete: false,
      };
      state.currentTemplateID = '';
    },
    updateTemplateValue: (state, action: PayloadAction<ITemplateById>) => {
      state.templateById = action.payload;
    },
    setCurrentTemplateId: (state, action: PayloadAction<string>) => {
      state.currentTemplateID = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getTemplates.pending,
        (state) => {
          state.templatesIsLoad = false;
        },
      )
      .addCase(
        getTemplates.fulfilled,
        (state, action) => {
          const newTemplate: ITemplatesListItem = {
            id: 'void',
            tpl_name: 'Новый шаблон',
            status: 'recheck',
          };
          state.templates = [newTemplate, ...action.payload.data];
          state.templatesIsLoad = true;

        },
      )
      .addCase(
        getTemplateById.pending,
        (state) => {
          state.templateByIdIsLoad = false;
          state.templateById = {
            id: '',
            tpl_name: '',
            name: '',
            cve_id: '',
            cwe_id: '',
            location: '',
            description: '',
            cause_of_occurrence: '',
            negative_consequences: '',
            risk_level: null,
            cvss_score: '',
            cvss_vector: '',
            procedure_exploiting: '',
            recommendations: '',
            additional_info: '',
            status: 'recheck',
            is_delete: false,
          };
        },
      )
      .addCase(
        getTemplateById.fulfilled,
        (state, action) => {
          state.templateByIdIsLoad = true;
          state.templateById = action.payload;
        },
      )
      .addCase(
        createTemplate.pending,
        (state) => {
          state.templateByIdIsLoad = false;
          state.sendStatus = {
            isSuccess: true,
            message: '',
            popupIsActive: false,
          };
        },
      )
      .addCase(
        createTemplate.fulfilled,
        (state, action) => {
          state.templateByIdIsLoad = true;
          state.templateById = action.payload;
          state.currentTemplateID = action.payload.id;
          state.templates.push({ tpl_name: action.payload.tpl_name, id: action.payload.id, status: action.payload.status });
          state.sendStatus = {
            isSuccess: true,
            message: 'Шаблон успешно создан.',
            popupIsActive: true,
          };
        },
      )
      .addCase(
        updateTemplate.pending,
        (state) => {
          state.templateByIdIsLoad = false;
          state.sendStatus = {
            isSuccess: true,
            message: '',
            popupIsActive: false,
          };
        },
      )
      .addCase(
        updateTemplate.rejected,
        (state) => {
          state.templateByIdIsLoad = false;
          state.sendStatus = {
            isSuccess: false,
            message: 'Ошибка при сохранении шаблона',
            popupIsActive: true,
          };
        },
      )
      .addCase(
        updateTemplate.fulfilled,
        (state, action) => {
          state.templateByIdIsLoad = true;
          state.templateById = action.payload;
          state.sendStatus = {
            isSuccess: true,
            message: 'Шаблон успешно сохранен.',
            popupIsActive: true,
          };
        },
      )
      .addCase(
        deleteTemplate.pending,
        (state) => {
          state.templateByIdIsLoad = false;
          state.sendStatus = {
            isSuccess: true,
            message: '',
            popupIsActive: false,
          };
        },
      )
      .addCase(
        deleteTemplate.rejected,
        (state) => {
          state.templateByIdIsLoad = false;
          state.sendStatus = {
            isSuccess: false,
            message: 'Ошибка удаления шаблона',
            popupIsActive: true,
          };
        },
      )
      .addCase(
        deleteTemplate.fulfilled,
        (state, action) => {
          state.templateByIdIsLoad = true;
          state.currentTemplateID = '';
          // state.templates = state.templates.filter(template => template.id !== action.payload)
          state.sendStatus = {
            isSuccess: true,
            message: 'Шаблон успешно удален!',
            popupIsActive: true,
          };
        },
      )
      .addCase(
        changeStatusTemplate.pending,
        (state) => {
          state.templateByIdIsLoad = false;
          state.sendStatus = {
            isSuccess: true,
            message: '',
            popupIsActive: false,
          };
        },
      )
      .addCase(
        changeStatusTemplate.rejected,
        (state, action) => {
          state.sendStatus = {
            isSuccess: false,
            message: 'Ошибка при изменении статуса шаблона.',
            popupIsActive: true,

          };
        },
      )
      .addCase(
        changeStatusTemplate.fulfilled,
        (state, action) => {
          state.templateByIdIsLoad = true;
          state.templateById = action.payload;
          state.sendStatus = {
            isSuccess: true,
            message: 'Статус шаблона успешно изменен.',
            popupIsActive: true,
          };
        },
      )
      .addCase(
        uploadTemplateFromFile.pending,
        (state) => {
          state.templateByIdIsLoad = false;
          state.sendStatus = {
            isSuccess: true,
            message: '',
            popupIsActive: false,
          };
        },
      )
      .addCase(
        uploadTemplateFromFile.rejected,
        (state, action) => {
          state.sendStatus = {
            isSuccess: false,
            message: 'Ошибка при загрузке шаблонов.',
            popupIsActive: true,

          };
        },
      )
      .addCase(
        uploadTemplateFromFile.fulfilled,
        (state, action) => {
          state.templateByIdIsLoad = true;
          state.templates = [...state.templates, ...action.payload.data];
          state.sendStatus = {
            isSuccess: true,
            message: 'Шаблоны успешно добавлены',
            popupIsActive: true,
          };
        },
      );


  },
});


export const {
  resetTemplate,
  updateTemplateValue,
  setCurrentTemplateId,
} = templateSlice.actions;

export default templateSlice.reducer;
