import axios from 'axios';

import {BASE_URI} from '../../constants/auth';

const BASE_URL = BASE_URI + '/api/v1';
const AUTHORIZATION_USERNAME = 'Bearer';
const $api = axios.create({
    withCredentials: true,
    baseURL: BASE_URL,
});

$api.defaults.withCredentials = true;

$api.interceptors.request.use(config => {
    const AUTHORIZATION_TOKEN = localStorage.getItem('token') || null;
    config.headers.Authorization = `${AUTHORIZATION_USERNAME} ${AUTHORIZATION_TOKEN}`;
    return config;
});

$api.interceptors.response.use(config => {
    return config;
}, async (error) => {
    const originalRequest = error.config;

    if (error.response.status == 401 && error.config && !error.config._isRetry) {
        originalRequest._isRetry = true;
        try {
            const response = await axios.post(`${BASE_URL}/auth/jwt/refresh`, {},{withCredentials: true});
            localStorage.setItem('token', response.data.access_token);

            return $api.request(originalRequest);
        } catch (e) {
            localStorage.removeItem('token');
            window.location.href = 'login';
        }
    }
},
);

export {$api};