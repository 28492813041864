import {FC, useEffect, useState} from 'react';

import {useParams} from 'react-router-dom';

import classNames from 'classnames';

import Modal from '../Modal';
import {IModalProps} from '../ModalTypes';
import InputForm from '../../inputForm/InputForm';
import styles from '../Modal.module.scss';
import Button from '../../button/Button';
import {InputTypeEnum} from '../../input/InputTypes';
import {useAppDispatch} from '../../../hooks/useAppDispatch';
import {useAppSelector} from '../../../hooks/useAppSelector';
import ConfirmModal from '../general/confirm/ConfirmModal';
import {ModalTypes} from '../general/confirm/ConfirmTypes';
import {useShowPopup} from '../../../hooks/useShowPopup';


import {ButtonTypeEnum} from '../../button/ButtonTypes';
import {IPopupItem} from '../../popup/PopupTypes';
import {
    officeSecurityLevelPopupItems,
    prepareSecurityLevelToEng,
    prepareSecurityLevelToRu,
} from '../../../constants/offices';
import {IOffice} from '../../../store/offices/officesTypes';
import {changeOffice} from '../../../store/offices/officesAsync';
import {validateOffice} from '../../../utils/validate/groups/validateOffice';
import {selectOfficeById, selectOfficeErrors, selectOffices} from '../../../store/offices/officesSelectors';

import {localization} from '../../../localization/localization';

import {resetOfficeData} from './utils/resetOfficeData';

const ChangeOffice: FC<IModalProps> = ({ isModalVisible, setModalVisible }) => {
    const dispatch = useAppDispatch();

    const { customerId, officeId } = useParams();

    const { allOffices } = useAppSelector(selectOffices);

    const { showPopupHandler } = useShowPopup();

    const {
        office_name_error,
        address_error,
        responsible_is_error,
        office_security_level_error,
    } = useAppSelector(selectOfficeErrors);

    const {
        additional_info,
        name,
        address,
        availability_wifi,
        responsible_is,
        availability_separate_internet,
        security_level: securityLevel,
    }: IOffice = useAppSelector(selectOfficeById);

    const [office, setOffice] = useState<IOffice>({
        additional_info: additional_info,
        address: address,
        availability_separate_internet: availability_separate_internet,
        availability_wifi: availability_wifi,
        name: name,
        security_level: prepareSecurityLevelToRu[securityLevel || ''],
        responsible_is: responsible_is,
        id: officeId,
    });

    const [isChangeOfficeModal, setChangeOfficeModal] = useState<boolean>(false);
    const [isResetOfficeDataModal, setResetOfficeDataModal] = useState<boolean>(false);

    useEffect(() => {
        setOffice({
            additional_info: additional_info,
            address: address,
            availability_separate_internet: availability_separate_internet,
            availability_wifi: availability_wifi,
            name: name,
            security_level: prepareSecurityLevelToRu[securityLevel || ''],
            responsible_is: responsible_is,
            id: officeId,
        });
    }, [name, address, additional_info, availability_separate_internet, availability_wifi, securityLevel, responsible_is, officeId]);

    const changeOfficeHandler = () => {
        const isValidate = validateOffice(office, dispatch, allOffices);

        if (isValidate && customerId && officeId) {
            office.security_level = prepareSecurityLevelToEng[office.security_level];

            dispatch(changeOffice({ customerId, office, officeId }));

            office.security_level = prepareSecurityLevelToRu[office.security_level];

            setModalVisible(false);
        }
    };

    const resetOfficeDataHandler = () => resetOfficeData(dispatch, setOffice);

    const onSecurityLevelChange = (item: IPopupItem) => setOffice({ ...office, security_level: item.text || '' });

    const onConfirmChangeModalHandler = () => setChangeOfficeModal(prevState => !prevState);
    const onConfirmResetModalHandler = () => setResetOfficeDataModal(prevState => !prevState);

    return (
        <>
            <Modal
                isModalVisible={isModalVisible}
                setModalVisible={setModalVisible}
                title={localization.modals.office.changeTitle}
            >
                <div className={styles['modal-inputs']}>
                    <InputForm
                        text={localization.modals.office.nameText}
                        placeholder={localization.modals.office.namePlaceholder}
                        errorMessage={office_name_error}
                        value={office.name}
                        onChange={(event) => {
                            setOffice({...office, name: event.target.value});
                        }}
                        required
                    />
                    <InputForm
                        text={localization.modals.office.addressText}
                        placeholder={localization.modals.office.addressPlaceholder}
                        errorMessage={address_error}
                        value={office.address}
                        onChange={(event) => {
                            setOffice({...office, address: event.target.value});
                        }}
                    />
                    <InputForm
                        text={localization.modals.office.responsibleText}
                        placeholder={localization.modals.office.responsiblePlaceholder}
                        errorMessage={responsible_is_error}
                        value={office.responsible_is}
                        onChange={(event) => {
                            setOffice({...office, responsible_is: event.target.value});
                        }}
                    />
                    <InputForm
                        text={localization.modals.office.securityLevelText}
                        placeholder={localization.modals.office.securityLevelPlaceholder}
                        value={office.security_level || ''}
                        errorMessage={office_security_level_error}
                        popupItems={officeSecurityLevelPopupItems}
                        onClick={showPopupHandler}
                        onPopupChange={onSecurityLevelChange}
                        disabled
                        onChange={(event) => {
                            setOffice({...office, security_level: event.target.value});
                        }}
                    />
                    <InputForm
                        text={localization.modals.additionalInfoText}
                        placeholder={localization.modals.additionalInfoPlaceholder}
                        value={office.additional_info}
                        onTextareaChange={(event) => {
                            setOffice({...office, additional_info: event.target.value});
                        }}
                        textarea
                    />
                    <InputForm
                        text={localization.modals.office.availabilityWifiText}
                        type={InputTypeEnum.Checkbox}
                        value={office.availability_wifi}
                        onChange={(event) => {
                            setOffice({...office, availability_wifi: event.target.checked});
                        }}
                    />
                    <InputForm
                        text={localization.modals.office.availabilitySeparateInternetText}
                        type={InputTypeEnum.Checkbox}
                        value={office.availability_separate_internet}
                        onChange={(event) => {
                            setOffice({...office, availability_separate_internet: event.target.checked});
                        }}
                    />
                </div>
                <div className={classNames(styles['modal-buttons'], styles['modal-buttons_between'])}>
                    <Button buttonText={localization.common.changeButtonText} onClick={onConfirmChangeModalHandler} />
                    <Button
                        type={ButtonTypeEnum.Red}
                        buttonText={localization.modals.resetButtonText}
                        onClick={onConfirmResetModalHandler}
                    />
                </div>
            </Modal>
            <ConfirmModal
                isModalVisible={isChangeOfficeModal}
                setModalVisible={setChangeOfficeModal}
                text={localization.office.confirmDataText}
                onConfirmClick={changeOfficeHandler}
                type={ModalTypes.Change}
            />
            <ConfirmModal
                isModalVisible={isResetOfficeDataModal}
                setModalVisible={setResetOfficeDataModal}
                text={localization.office.confirmDataText}
                onConfirmClick={resetOfficeDataHandler}
                type={ModalTypes.Reset}
            />
        </>
    );
};

export default ChangeOffice;
