import { createAsyncThunk } from '@reduxjs/toolkit';

import { IThunkApiConfigProps } from '../storeTypes';

import { VULNS_ROUTES } from '../../constants/vulns';
import { PROJECTS_ROUTES } from '../../constants/projects';
import { OBJECTS_ROUTES } from '../../constants/objects';

import {
  IChangeVulnRequest,
  ICreateVulnRequest,
  ICreateVulnRequestTemplate,
  ICreateVulnsForObjectsResponse,
  ICreateVulnsForObjectsResponseData,
  ICreateVulnsForObjectsSendData,
  IDeleteScreenshotRequest,
  IDeleteVulnRequest,
  IDeleteVulnResponse,
  IGetScreenshotsList,
  IGetVulnResponse,
  IGetVulnResponseMini,
  IGetVulnTemplateResponse,
  IGetVulnTemplatesResponse,
  IUploadScreenshotsAndDescriptionRequest,
  IUploadScreenshotsRequest,
  IUploadScreenshotsResponse,
  IVuln,
  IVulnRequest,
  IVulnResponse,
  VulnsTypes,
} from './vulnsTypes';

export const getVulns = createAsyncThunk<IGetVulnResponseMini, IVulnRequest, IThunkApiConfigProps>(
  VulnsTypes.GET,
  async ({ projectId, objectType, objectId, filters, pagination, sortParams }, { rejectWithValue, extra }) => {
    try {
      const paginationString = pagination ? `offset=${pagination.offset}&limit=${pagination.limit}` : '';
      const response = await extra.api.get(
        `${PROJECTS_ROUTES.FULL_URL}/${projectId}/${OBJECTS_ROUTES.URL}/${objectType}/${objectId}/${VULNS_ROUTES.URL}/?${paginationString}${filters ? `&${filters}` : ''}&${sortParams ? sortParams : ''}`);

      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.status);
    }
  },
);

export const getAllVulns = createAsyncThunk<IGetVulnResponse, IVulnRequest, IThunkApiConfigProps>(
  VulnsTypes.GET_ALL,
  async ({ projectId, objectType, objectId, filters, pagination }, { rejectWithValue, extra }) => {
    try {
      const response = await extra.api.get(
        `${PROJECTS_ROUTES.FULL_URL}/${projectId}/${OBJECTS_ROUTES.URL}/${objectType}/${objectId}/${VULNS_ROUTES.URL}/${filters ? `?${filters}` : ''}`);

      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.status);
    }
  },
);

export const getVulnById = createAsyncThunk<IVuln, IDeleteVulnRequest, IThunkApiConfigProps>(
  VulnsTypes.GET_ONE,
  async ({ projectId, vulnId, objectId, objectType }, { rejectWithValue, extra }) => {
    try {
      const response = await extra.api.get(
        `${PROJECTS_ROUTES.FULL_URL}/${projectId}/${OBJECTS_ROUTES.URL}/${objectType}/${objectId}/${VULNS_ROUTES.URL}/${vulnId}`);

      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.status);
    }
  },
);

export const createVuln = createAsyncThunk<IVulnResponse, ICreateVulnRequest, IThunkApiConfigProps>(
  VulnsTypes.POST,
  async ({ objectId, objectType, vuln, projectId }, { rejectWithValue, extra }) => {
    try {
      const response = await extra.api.post(
        `${PROJECTS_ROUTES.FULL_URL}/${projectId}/${OBJECTS_ROUTES.URL}/${objectType}/${objectId}/${VULNS_ROUTES.URL}/`,
        vuln);

      return { vuln: response.data, status: response.status };
    } catch (error: any) {
      return rejectWithValue(error.response.status);
    }
  },
);

export const deleteVuln = createAsyncThunk<IDeleteVulnResponse, IDeleteVulnRequest, IThunkApiConfigProps>(
  VulnsTypes.DELETE,
  async ({ projectId, vulnId, objectId, objectType }, { rejectWithValue, extra }) => {
    try {
      const response = await extra.api.delete(
        `${PROJECTS_ROUTES.FULL_URL}/${projectId}/${OBJECTS_ROUTES.URL}/${objectType}/${objectId}/${VULNS_ROUTES.URL}/${vulnId}`);

      return { id: vulnId, status: response.status };
    } catch (error: any) {
      return rejectWithValue(error.response.status);
    }
  },
);

export const changeVuln = createAsyncThunk<IVulnResponse, IChangeVulnRequest, IThunkApiConfigProps>(
  VulnsTypes.PATCH,
  async ({ vuln, projectId, objectType, objectId, vulnId }, { rejectWithValue, extra }) => {
    try {
      const response = await extra.api.patch(
        `${PROJECTS_ROUTES.FULL_URL}/${projectId}/${OBJECTS_ROUTES.URL}/${objectType}/${objectId}/${VULNS_ROUTES.URL}/${vulnId}`,
        vuln);

      return { vuln: response.data, status: response.status };
    } catch (error: any) {
      return rejectWithValue(error.response.status);
    }
  },
);



export const uploadVulnScreenshots = createAsyncThunk<IUploadScreenshotsResponse[], IUploadScreenshotsRequest, IThunkApiConfigProps>(
  VulnsTypes.UPLOAD,
  async ({ files, projectId, vulnId, objectId, objectType }, { rejectWithValue, extra }) => {
    try {
      const response = await extra.api.post(
        `${PROJECTS_ROUTES.FULL_URL}/${projectId}/${OBJECTS_ROUTES.URL}/${objectType}/${objectId}/${VULNS_ROUTES.URL}/${vulnId}/${VULNS_ROUTES.UPLOAD_SCREENSHOTS}`,
        files);

      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.status);
    }
  },
);

export const getVulnScreenshots = createAsyncThunk<IGetScreenshotsList[], IDeleteVulnRequest, IThunkApiConfigProps>(
  VulnsTypes.GET_SCREENSHOTS,
  async ({ projectId, vulnId, objectId, objectType }, { rejectWithValue, extra }) => {
    try {
      const response = await extra.api.get(
        `${PROJECTS_ROUTES.FULL_URL}/${projectId}/${OBJECTS_ROUTES.URL}/${objectType}/${objectId}/${VULNS_ROUTES.URL}/${vulnId}/${VULNS_ROUTES.SCREENSHOTS}`);

      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.status);
    }
  },
);

export const deleteVulnScreenshots = createAsyncThunk<[], IDeleteVulnRequest, IThunkApiConfigProps>(
  VulnsTypes.DELETE_SCREENSHOTS,
  async ({ projectId, vulnId, objectId, objectType }, { rejectWithValue, extra }) => {
    try {
      await extra.api.delete(
        `${PROJECTS_ROUTES.FULL_URL}/${projectId}/${OBJECTS_ROUTES.URL}/${objectType}/${objectId}/${VULNS_ROUTES.URL}/${vulnId}/${VULNS_ROUTES.DELETE_SCREENSHOTS}`);

      return [];
    } catch (error: any) {
      return rejectWithValue(error.response.status);
    }
  },
);



export const updateVulnScreenshotsDescription = createAsyncThunk<IGetScreenshotsList, IDeleteScreenshotRequest, IThunkApiConfigProps>(
  VulnsTypes.UPDATE_SCREENSHOT_DESCRIPTION,
  async ({ projectId, vulnId, objectId, objectType, screenId, fullScreenId, sequence }, { rejectWithValue, extra }) => {
    try {
      const response = await extra.api.patch(
        `${PROJECTS_ROUTES.FULL_URL}/${projectId}/${OBJECTS_ROUTES.URL}/${objectType}/${objectId}/${VULNS_ROUTES.URL}/${vulnId}/${VULNS_ROUTES.UPDATE_SCREENSHOT}/${screenId}`,
        {
          description: fullScreenId,
          sequence: sequence,
        },
      );

      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.status);
    }
  },
);

export const deleteVulnScreenshot = createAsyncThunk<string, IDeleteScreenshotRequest, IThunkApiConfigProps>(
  VulnsTypes.DELETE_SCREENSHOT,
  async ({ projectId, vulnId, objectId, objectType, screenId, fullScreenId }, { rejectWithValue, extra }) => {
    try {
      await extra.api.delete(
        `${PROJECTS_ROUTES.FULL_URL}/${projectId}/${OBJECTS_ROUTES.URL}/${objectType}/${objectId}/${VULNS_ROUTES.URL}/${vulnId}/${VULNS_ROUTES.DELETE_SCREENSHOT}/${screenId}`);

      return fullScreenId;
    } catch (error: any) {
      return rejectWithValue(error.response.status);
    }
  },
);


export const getVulnTemplates = createAsyncThunk<IGetVulnTemplatesResponse, string | undefined, IThunkApiConfigProps>(
  VulnsTypes.GET_TEMPLATES,
  async (objectType, { rejectWithValue, extra }) => {
    try {
      const response = await extra.api.get(
        `${process.env.REACT_APP_API_URI}/api/v1/vuln_templates/${objectType ? '?object_type=' + objectType : ''}`);

      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.status);
    }
  },
);
export const getVulnTemplate = createAsyncThunk<IGetVulnTemplateResponse, string, IThunkApiConfigProps>(
  VulnsTypes.GET_TEMPLATE,
  async (id, { rejectWithValue, extra }) => {
    try {
      const response = await extra.api.get(
        `${process.env.REACT_APP_API_URI}/api/v1/vuln_templates/${id}`);

      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.status);
    }
  },
);


export const createVulnTemplates = createAsyncThunk<{ data: { [objectId: string]: boolean } }, ICreateVulnRequestTemplate, IThunkApiConfigProps>(
  VulnsTypes.CREATE_TEMPLATE,
  async ({ objectId, projectId, objectType, template, screenshotData }, { rejectWithValue, extra }) => {
    try {
      const responseVuln = await extra.api.post(
        `${PROJECTS_ROUTES.FULL_URL}/${projectId}/${OBJECTS_ROUTES.URL}/${objectType}/${objectId}/${VULNS_ROUTES.URL}/`,
        template,
      );

      let status = responseVuln.status == 201;

      const data = new FormData();


      if (screenshotData) {
        const fileNames: string[] = Object.keys(screenshotData);
        const descriptionsList: string[] = [];
        if (fileNames) {
          fileNames.forEach(fileName => {
            data.append('files', screenshotData[fileName].file, fileName);
            descriptionsList.push(screenshotData[fileName].description);

          });
          const descriptions = descriptionsList.join(String.fromCharCode(29));
          data.append('descriptions', descriptions);
          const responseScreen = await extra.api.post(
            `${PROJECTS_ROUTES.FULL_URL}/${projectId}/${OBJECTS_ROUTES.URL}/${objectType}/${objectId}/${VULNS_ROUTES.URL}/${responseVuln.data.id}/upload_screenshots`,
            data,
          );
          status = responseScreen.status == 200;
        }
      }

      return { data: { [objectId]: status } };
    } catch (error: any) {
      return rejectWithValue(error.response.status);
    }
  },
);


export const uploadScreenshotsAndDescriptions = createAsyncThunk<IGetScreenshotsList[], IUploadScreenshotsAndDescriptionRequest, IThunkApiConfigProps>(
  VulnsTypes.UPLOAD_SCREENSHOT_DESCRIPTION,
  async ({ projectId, objectType, objectId, vulnId, screenshotData }, { rejectWithValue, extra }) => {
    try {

      const data: { imgbase64: string, sequence: number, description: string }[] = [];
      const fileNames: string[] = Object.keys(screenshotData);


      fileNames.forEach(fileName => {
        data.push(
          {
            'imgbase64': screenshotData[fileName].base64data.split(',')[1],
            'sequence': screenshotData[fileName].sequence,
            'description': screenshotData[fileName].description,
          },
        );

      });

      const responseScreen = await extra.api.post(
        `${PROJECTS_ROUTES.FULL_URL}/${projectId}/${OBJECTS_ROUTES.URL}/${objectType}/${objectId}/${VULNS_ROUTES.URL}/${vulnId}/upload_screenshots`,
        data,
      );
      return responseScreen.data;


    } catch (error: any) {
      return rejectWithValue(error.response.status);
    }
  },
);


