import React, {ComponentProps, useState, FC, useEffect} from 'react';
import cn from 'classnames/bind';

import Select  from 'react-select';

import {NavButton} from '../NavButton';
import { IVulnTemplates} from '../../../store/vulns/vulnsTypes';
import {objectTypeList} from '../../../constants/objects';
import InputForm from '../../../components/inputForm/InputForm';

import styles from './SelectList.module.scss';

interface ISelectListProps extends ComponentProps<'div'>{
    templateList: IVulnTemplates[];
    selectedTemplateId: string;
    objectTypeFilterValue: string
    setSelectedTemplateId: (id: string) => void;
    setObjectTypeFilterValue: (param: string) => void;

}

export const SelectList: FC<ISelectListProps> = (props) => {

    const cx = cn.bind(styles);

    const {
        templateList,
        selectedTemplateId,
        objectTypeFilterValue,
        setSelectedTemplateId,
        setObjectTypeFilterValue,
        ...otherProps
    } = props;

    const [
        sortedList,
        setSortedList,
    ] = useState<typeof templateList>(templateList);

    const [sortName, setSortName] = useState<string>('');

    useEffect(()=>{
        setSortedList(templateList.filter(template => template.tpl_name.toLowerCase().includes(sortName.toLowerCase())));
    }, [templateList, sortName]);


    const [filterParam, setFilterParam] = useState({
        name: '',
        object_type: '',
    });

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, sortParam: 'name' | 'object_type', sortValue: string) => {
        setFilterParam({...filterParam, [sortParam]: sortValue});
    };


    return (
        <div {...otherProps} className={cx(['select-list'])}>
            <InputForm
                text="Имя шаблона: "
                placeholder="Поиск по шаблонам..."
                imageUrl="/assets/icons/search.svg"
                value={sortName}
                onChange={(e) => {
                    setSortName(e.target.value);
                }}
            />
            <div>
                <h3 className={cx(['select-list_header'])}>Тип объекта: </h3>

                <Select
                    placeholder="Тип объекта..."
                    onChange={(e) => {
                        e && setObjectTypeFilterValue(e.value);
                    }}
                    options={[{label: 'Все шаблоны', value:''}].concat(objectTypeList)}
                />
            </div>

            <div className={cx(['select-list_items-bar'])}>
                {sortedList.map(el => (
                    <NavButton
                        key={el.id}
                        text={el.tpl_name}
                        isActive={el.id === selectedTemplateId}
                        onClick={() => {setSelectedTemplateId( el.id);}}
                        buttonType="list"
                    />
                ),
                )
                }
            </div>
        </div>
    );
};

