export const OFFICES_URL = 'offices';

export const officeHeadCells = [
    {
        id: 'checked',
        checked: true,
        image: false,
    },
    {
        id: 'name',
        text: 'Наименование офиса',
    },
    {
        id: 'address',
        text: 'Адрес',
    },
    {
        id: 'availability_wifi',
        text: 'Наличие Wi-Fi',
    },
    {
        id: 'responsible_is',
        text: 'Ответственный за ИБ',
    },
    {
        id: 'availability_separate_internet',
        text: 'Наличие отдельного интернета',
    },
    {
        id: 'security_level',
        text: 'Уровень защищенности',
    },
];

export const officeHeadTable =   {
    'name': {label: 'Наименование офиса'},
    'address': {label: 'Адрес'},
    'availability_wifi': {label: 'Наличие Wi-Fi'},
    'responsible_is': {label: 'Ответственный за ИБ'},
    'availability_separate_internet': {label: 'Наличие отдельного интернета'},
    'security_level': {label: 'Уровень защищенности'},
};

export const officeSecurityLevelPopupItems = [
    {
        value: 'critical',
        text: 'Критический',
        id: 1,
    },
    {
        value: 'high',
        text: 'Высокий',
        id: 2,
    },
    {
        value: 'medium',
        text: 'Средний',
        id: 3,
    },
    {
        value: 'low',
        text: 'Низкий',
        id: 4,
    },
];

export const prepareSecurityLevelToRu: { [index: string]: string } = {
    'critical': 'Критический',
    'high': 'Высокий',
    'medium': 'Средний',
    'low': 'Низкий',
};

export const prepareSecurityLevelToEng: { [index: string]: string } = {
    'Критический': 'critical',
    'Высокий': 'high',
    'Средний': 'medium',
    'Низкий': 'low',
};

export const securityLevels = [
    { value: 'critical', label: 'Критический' },
    { value: 'high', label: 'Высокий' },
    { value: 'medium', label: 'Средний' },
    { value: 'low', label: 'Низкий' },
];
