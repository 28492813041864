import {createAsyncThunk} from '@reduxjs/toolkit';

import {IThunkApiConfigProps} from '../storeTypes';
import {PROJECTS_ROUTES} from '../../constants/projects';
import {VULNS_ROUTES} from '../../constants/vulns';

import {
    IObjectsForUniqueVulns,
    IResponseList,
    IUniqueVulnByIDRequest,
    IUniqueVulnByIDType, IUniqueVulnByIDUpdateRequest,
    IUniqueVulnListItemTypes,
    IUniqueVulnListRequest,
} from './uniqueVulnsTypes';

export const getUniqueVulnsAsync = createAsyncThunk<IResponseList<IUniqueVulnListItemTypes>, IUniqueVulnListRequest, IThunkApiConfigProps>(
    'uniqueVulns/vulnsList',
    async ({ projectId, filters, pagination, sortParam }, {rejectWithValue, extra}) => {
        try {
            const paginationString = pagination ? `offset=${pagination.offset}&limit=${pagination.limit}` : '';
            const response = await extra.api.get(
                `${PROJECTS_ROUTES.FULL_URL}/${projectId}/${VULNS_ROUTES.URL}/?${paginationString}${filters ? `&${filters}` : ''}${sortParam ? '&' + sortParam : ''}`);

            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.status);
        }
    },
);



export const getUniqueVulnByIdAsync = createAsyncThunk<IUniqueVulnByIDType, IUniqueVulnByIDRequest, IThunkApiConfigProps>(
    'uniqueVulns/vulnById',
    async ({ projectId,updateId }, {rejectWithValue, extra}) => {
        try {
            const response = await extra.api.get(
                `${PROJECTS_ROUTES.FULL_URL}/${projectId}/${VULNS_ROUTES.URL}/${updateId}`,
            );
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.status);
        }
    },
);

export const getObjectsForUniqueVulnsAsync = createAsyncThunk<IResponseList<IObjectsForUniqueVulns>, IUniqueVulnByIDRequest, IThunkApiConfigProps>(
    'uniqueVulns/objects',
    async ({
        projectId,
        updateId,
        sortBy,
        search ,
        offset,
        limit,
    }, {rejectWithValue, extra}) => {
        try {
            const params: string[] = [];
            if (search) {params.push(`search=${search}`);}
            if (offset) {params.push(`offset=${offset}`);}
            if (limit) {params.push(`limit=${limit}`);}
            if (sortBy) {params.push(`${sortBy}`);}
            const paramsString = params.length > 0 ? '?' + params.join('&') : '';
            const response = await extra.api.get(
                `${PROJECTS_ROUTES.FULL_URL}/${projectId}/${VULNS_ROUTES.URL}/${updateId}/objects${paramsString}`,
            );
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.status);
        }
    },
);

export const updateUniqueVulnByIdAsync = createAsyncThunk<IUniqueVulnByIDType, IUniqueVulnByIDUpdateRequest, IThunkApiConfigProps>(
    'uniqueVulns/updateVulnById',
    async ({ projectId, vulnData, updateId }, {rejectWithValue, extra}) => {
        try {
            const response = await extra.api.patch(
                `${PROJECTS_ROUTES.FULL_URL}/${projectId}/${VULNS_ROUTES.URL}/${updateId}`,
                vulnData,
            );
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.status);
        }
    },
);