import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import cnb from "classnames/bind";

import { IMAGE_PATH, prepareRiskLevelToRu } from "../../../constants/vulns";
import { useAppSelector } from "../../../hooks/useAppSelector";
import Loader from "../../../components/loader/Loader";
import Title from "../../../components/title/Title";
import { TitleVariantEnum } from "../../../components/title/TitleTypes";
import PageItem from "../../../components/pageItem/PageItem";
import Text from "../../../components/text/Text";
import { TextVariantEnum } from "../../../components/text/TextTypes";
import { preparedVulnCvssItems } from "../../../utils/prepare/preparedVulnCvssItems";
import { IPopupItem } from "../../../components/popup/PopupTypes";
import {
  getNextSortParam,
  getSortString,
  sortListType,
  TableComponent,
} from "../../../shared";
import { getDefaultSortParams } from "../../../shared/lib";
import Pagination from "../../../components/pagination/Pagination";
import { localization } from "../../../localization/localization";
import { IItemProps } from "../../../components/pageItem/PageItemTypes";
import Navbar from "../../../components/navbar/Navbar";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import Button from "../../../components/button/Button";
import { selectProfileData } from "../../../store/auth/authSelectors";
import { selectProjectById } from "../../../store/projects/projectsSelectors";
import { getProjectById } from "../../../store/projects/projectsAsync";
import InputForm from "../../../components/inputForm/InputForm";

import Fancybox from "../../../components/fancybox/Fancybox";

import styles from "./killchainPage.module.scss";
import { selectKillchain } from "../../../store/killchains/killchainsSelectors";
import { IKillchainVuln } from "../../../store/killchains/killchainsType";
import {
  getKillchain,
  getKillchainVulnsAsync,
} from "../../../store/killchains/killchainsAsync";
import ChangeKillChain from "../../../components/modals/vulns/ChangeKillChain";
import { NotificationPopUp } from "../../../shared/NotificationPopUp/ui/NotificationPopUp";
import { ImageBox } from "../../../entities";
import classNames from "classnames";
import { resetSendStatus } from "../../../store/killchains/killchainsSlice";
export interface ITableObjectData
  extends Omit<IKillchainVuln, 'status' | 'owner'> {
  owner: string;
}
export const KillChainPage = () => {
  const cx = cnb.bind(styles);


  const objectsHeader = {
    name: { label: 'Имя' },
    description: { label: 'Описание' },
    owner: { label: 'Создатель' },
    created_at: { label: 'Дата создания' },
  };

  const dispatch = useAppDispatch();
  const { killChainById, killChainVulnList, sendStatus } =
    useAppSelector(selectKillchain);
  const { projectId, vulnId } = useParams();
  const { role, id } = useAppSelector(selectProfileData);
  const { teamlead } = useAppSelector(selectProjectById);

  const [tableData, setTableData] = useState<ITableObjectData[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [sortParams, setSortParams] = useState<sortListType>(
    getDefaultSortParams(objectsHeader)
  );
  const [searchValue, setSearchValue] = useState("");
  const [pagination, setPagination] = useState({ offset: 0, limit: 100 });

  useEffect(() => {
    const newTableData: ITableObjectData[] = [];
    if (killChainVulnList) {
      for (const vuln of killChainVulnList.data) {
        newTableData.push({
          id: vuln.id,
          name: vuln.name,
          description: vuln.description,
          owner: vuln.owner.email,
          created_at: vuln.created_at,
          object_id: vuln.object_id,
          object_type: vuln.object_type,
        });
      }
      setTableData(newTableData);
    }
  }, [killChainVulnList]);

  const [cvssAV, setCvssAV] = useState<IPopupItem>({
    text: '',
    value: '',
  });

  const [cvssAC, setCvssAC] = useState<IPopupItem>({
    text: "",
    value: "",
  });
  const [cvssPR, setCvssPR] = useState<IPopupItem>({
    text: "",
    value: "",
  });
  const [cvssUI, setCvssUI] = useState<IPopupItem>({
    text: "",
    value: "",
  });
  const [cvssS, setCvssS] = useState<IPopupItem>({
    text: "",
    value: "",
  });
  const [cvssC, setCvssC] = useState<IPopupItem>({
    text: "",
    value: "",
  });
  const [cvssI, setCvssI] = useState<IPopupItem>({
    text: "",
    value: "",
  });
  const [cvssA, setCvssA] = useState<IPopupItem>({
    text: "",
    value: "",
  });

  const [hasTeamleadAccess, setTeamleadAccess] = useState<boolean>(false);

  useEffect(() => {
    setTeamleadAccess(teamlead?.id === id);
  }, [id, teamlead]);

  useEffect(() => {
    if (!teamlead && projectId) {
      dispatch(getProjectById(projectId));
    }
    if (projectId && vulnId) {
      dispatch(
        getKillchain({
          project_id: projectId,
          vulnerability_id: vulnId,
        })
      );
      dispatch(
        getKillchainVulnsAsync({
          search: "",
          project_id: projectId,
          vulnerability_id: vulnId,
          ...pagination,
          sort_by: getSortString(sortParams),
        })
      );
    }
  }, []);

  useEffect(() => {
    if (projectId && vulnId) {
      dispatch(
        getKillchainVulnsAsync({
          search: searchValue,
          project_id: projectId,
          vulnerability_id: vulnId,
          ...pagination,
          sort_by: getSortString(sortParams),
        })
      );
    }
  }, [pagination, sortParams]);

  useEffect(() => {
    if (killChainById?.cvss_vector) {
      preparedVulnCvssItems(
        killChainById.cvss_vector,
        setCvssAV,
        setCvssAC,
        setCvssPR,
        setCvssUI,
        setCvssS,
        setCvssC,
        setCvssI,
        setCvssA
      );
    }
    return () => {
      dispatch(resetSendStatus())
    }
  }, [killChainById]);

  const cvssList: IItemProps[] = [
    {
      title: localization.vuln.cvssInfo.AV,
      text: cvssAV.text ? cvssAV.text : "-",
      id: 1,
    },
    {
      title: localization.vuln.cvssInfo.AC,
      text: cvssAC.text ? cvssAC.text : "-",
      id: 2,
    },
    {
      title: localization.vuln.cvssInfo.PR,
      text: cvssPR.text ? cvssPR.text : "-",
      id: 3,
    },
    {
      title: localization.vuln.cvssInfo.UI,
      text: cvssUI.text ? cvssUI.text : "-",
      id: 4,
    },
    {
      title: localization.vuln.cvssInfo.S,
      text: cvssS.text ? cvssS.text : "-",
      id: 5,
    },
    {
      title: localization.vuln.cvssInfo.C,
      text: cvssC.text ? cvssC.text : "-",
      id: 6,
    },
    {
      title: localization.vuln.cvssInfo.I,
      text: cvssI.text ? cvssI.text : "-",
      id: 7,
    },
    {
      title: localization.vuln.cvssInfo.A,
      text: cvssA.text ? cvssA.text : "-",
      id: 8,
    },
  ];

  const infoList: IItemProps[] = [
    {
      title: 'Имя: ',
      text: killChainById?.name ? killChainById.name : '-',
      id: 1,
      isFirst: true,
    },
    {
      title: localization.vuln.infoList.locationText,
      text: killChainById?.location ? killChainById.location : '-',
      id: 2,
    },
    {
      title: localization.vuln.infoList.negativeConsequencesText,
      text:
        killChainById?.negative_consequences &&
          killChainById?.negative_consequences?.length !== 0
          ? killChainById.negative_consequences.join(', ')
          : '-',
      id: 3,
    },
    {
      title: localization.vuln.infoList.procedureExploitingText,
      text: killChainById?.procedure_exploiting || "-",
      id: 4,
    },
    {
      title: localization.vuln.infoList.cveIdText,
      text:
        killChainById?.cve_id && killChainById.cve_id.length !== 0
          ? killChainById?.cve_id.join(", ")
          : "-",
      id: 5,
    },
    {
      title: localization.vuln.infoList.cweIdText,
      text:
        killChainById?.cwe_id && killChainById?.cwe_id.length !== 0
          ? killChainById.cwe_id.join(", ")
          : "-",
      id: 6,
    },
  ];

  const isLoading = false;

  const secondaryInfoList: IItemProps[] = [
    {
      title: localization.vuln.secondaryInfoList.descriptionText,
      text: killChainById?.description || "-",
      id: 3,
    },
    {
      title: localization.vuln.secondaryInfoList.causeOccurrenceText,
      text: killChainById?.cause_of_occurrence || "-",
      id: 4,
    },
    {
      title: localization.vuln.secondaryInfoList.recommendationsText,
      text: killChainById?.recommendations || "-",
      id: 5,
    },
    {
      title: localization.modals.additionalInfoText,
      text: killChainById?.additional_info || "-",
      id: 6,
    },
    {
      title: "Статус",
      text: killChainById?.status || "-",
      id: 7,
    },
  ];

  const stateCvssItemList: IItemProps[] = [
    {
      title: "Вектор: ",
      text: killChainById?.cvss_vector ? killChainById?.cvss_vector : "-",
      id: 1,
      isFirst: true,
    },
    {
      title: "Оценка: ",
      text: killChainById?.cvss_score
        ? killChainById?.cvss_score.toString()
        : "-",
      id: 2,
      isFirst: true,
    },
    {
      title: localization.vuln.secondaryInfoList.riskLevelText,
      text: killChainById?.risk_level ? (
        <span
          className={
            styles[`vuln-risk-level_${killChainById.risk_level}`]
          }
        >
          {prepareRiskLevelToRu[killChainById.risk_level]}
        </span>
      ) : (
        "-"
      ),
      id: 3,
    },
  ];

  const stateInfoList: IItemProps[] = [
    {
      title: localization.vuln.stateInfo.pentester,
      text: killChainById?.owner?.id
        ? `${killChainById?.owner?.first_name} (${killChainById?.owner?.email})`
        : "-",
      id: 1,
    },
  ];

  const onPageClick = (offset: number, limit: number) => {
    setPagination({ offset, limit });
  };

  const onSearchValueChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchValue(event.target.value);
  };

  const handleSearchClick = () => {
    if (projectId && vulnId) {
      dispatch(
        getKillchainVulnsAsync({
          search: searchValue,
          project_id: projectId,
          vulnerability_id: vulnId,
          sort_by: getSortString(sortParams),
          ...pagination,
        })
      );
    }
  };
  const handleSearchResetClick = () => {
    if (projectId && vulnId && searchValue !== "") {
      dispatch(
        getKillchainVulnsAsync({
          search: "",
          project_id: projectId,
          vulnerability_id: vulnId,
          ...pagination,
          sort_by: getSortString(sortParams),
        })
      );

      setSearchValue("");
    }
  };
  const handleClickSort = (param: string) => {
    setSortParams(getNextSortParam(param, sortParams));

  };
  return (
    <>
      <Navbar />

      <div className={cx("content")}>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <div className={cx("content-info")}>
              <div className={styles["vuln-body-info"]}>
                <Title
                  className={styles["vuln-body-info-title"]}
                  variant={TitleVariantEnum.H3}
                >
                  {localization.vuln.infoList.title}
                </Title>
                <div className={cx("content-column")}>
                  {infoList.map((item) => (
                    <PageItem
                      key={item.id}
                      {...item}
                      isTextCropped={true}
                      textSize={80}
                    />
                  ))}
                </div>
              </div>
              <div className={styles["vuln-body-info-secondary"]}>
                <Title
                  className={
                    styles["vuln-body-info-secondary-title"]
                  }
                  variant={TitleVariantEnum.H3}
                >
                  {localization.common.secondaryInfoTitle}
                </Title>
                <div className={cx("content-column")}>
                  {secondaryInfoList.map((item) => (
                    <PageItem
                      key={item.id}
                      {...item}
                      isTextCropped={true}
                      textSize={80}
                    />
                  ))}
                </div>
              </div>
              <div className={cx("content-column")}>
                <div className={cx("content-column")}>
                  <Text
                    variant={TextVariantEnum.L}
                    className={
                      styles["vuln-panel-bottom-title"]
                    }
                  >
                    {localization.vuln.cvssInfo.title}
                  </Text>
                  <div className={cx("content-column")}>
                    {stateCvssItemList.map((item) => (
                      <PageItem
                        {...item}
                        className={cx("")}
                        classNameTitle={
                          styles[
                          "vuln-panel-bottom-item-title"
                          ]
                        }
                        classNameText={
                          styles[
                          "vuln-panel-bottom-item-text"
                          ]
                        }
                        key={item.id}
                      />
                    ))}
                  </div>
                  <div className={cx("content-cvss")}>
                    {cvssList.map((item) => (
                      <PageItem
                        {...item}
                        className={cx(
                          "content-cvss-item"
                        )}
                        classNameTitle={
                          styles[
                          "vuln-panel-bottom-item-title"
                          ]
                        }
                        classNameText={
                          styles[
                          "vuln-panel-bottom-item-text"
                          ]
                        }
                        key={item.id}
                      />
                    ))}
                  </div>
                </div>
                <div className={cx("content-column")}>
                  <Text
                    variant={TextVariantEnum.L}
                    className={
                      styles["vuln-panel-bottom-title"]
                    }
                  >
                    {localization.common.stateInfoTitle}
                  </Text>
                  <div
                    className={
                      styles["vuln-panel-bottom-list"]
                    }
                  >
                    {stateInfoList.map((item) => (
                      <PageItem
                        {...item}
                        className={
                          styles[
                          "vuln-panel-bottom-item_secondary"
                          ]
                        }
                        classNameTitle={
                          styles[
                          "vuln-panel-bottom-item-title"
                          ]
                        }
                        classNameText={
                          styles[
                          "vuln-panel-bottom-item-text"
                          ]
                        }
                        key={item.id}
                      />
                    ))}
                  </div>
                </div>
                <div className={styles["content-buttons"]}>
                  {/*<PageItem*/}
                  {/*  title="Обновлено"*/}
                  {/*  text={killChainById?.updated_at || '-'}*/}
                  {/*  className={styles['vuln-panel-bottom-item_secondary']}*/}
                  {/*  classNameTitle={styles['vuln-panel-bottom-item-title']}*/}
                  {/*  classNameText={styles['vuln-panel-bottom-item-text']}*/}
                  {/*/>*/}
                  {(role === "admin" ||
                    role === "pentester" ||
                    hasTeamleadAccess ||
                    killChainById?.owner?.id === id) && (
                      <Button
                        onClick={() =>
                          setIsModalVisible(
                            (prev) => !prev
                          )
                        }
                        buttonText={
                          localization.common
                            .changeButtonText
                        }
                      />
                    )}
                </div>
              </div>
              <ImageBox
                className={cx('screenshots')}
                screenshots={killChainById?.screenshots.map(screenshot => ({
                  id: screenshot.id,
                  description: screenshot.description,
                  title: screenshot.id,
                  src: IMAGE_PATH + screenshot.id + '.jpg',
                  sequence: screenshot.sequence,
                })
                )}
              />
            </div>

            <div className={cx("content-column")}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                  gap: "10px",
                }}
              >
                <Title
                  className={styles["vuln-body-info-title"]}
                  variant={TitleVariantEnum.H3}
                >
                  Уязвимости{" "}
                  <span
                    style={{
                      backgroundColor: "#2478F8",
                      padding: "0px 6px",
                      borderRadius: "15px",
                      marginLeft: "5px",
                      color: "#fff",
                      fontSize: "14px",
                    }}
                  >
                    {killChainVulnList?.count || 0}
                  </span>
                </Title>
                <InputForm
                  text={""}
                  placeholder="Поиск..."
                  className={cx("input")}
                  value={searchValue}
                  onChange={onSearchValueChange}
                />
                <Button
                  buttonText={"Поиск"}
                  onClick={handleSearchClick}
                />
                <Button
                  buttonText={"Сброс"}
                  onClick={handleSearchResetClick}
                />
              </div>


              {killChainVulnList && (
                <TableComponent
                  baseUrl={() => `/projects/${projectId}/objects/${killChainVulnList?.data[0]?.object_type}/${killChainVulnList?.data[0]?.object_id}/vulns`}
                  sortParams={sortParams}
                  headersRow={objectsHeader}
                  handleClickSort={handleClickSort}
                  dataList={tableData}
                  isNotLink={false}

                />
              )}
              <Pagination
                onPageClick={onPageClick}
                count={killChainVulnList?.count || undefined}
                startLimit={pagination.limit}
              />
            </div>
          </>
        )}
        {isModalVisible && killChainById &&
          <ChangeKillChain
            isModalVisible={true}
            setModalVisible={() => {
              setIsModalVisible(false);
            }}
            currentObjectId={''}
            vulnData={killChainById}
          />
        }
      </div>
      <NotificationPopUp isActive={sendStatus.popupIsActive} text={sendStatus.message} isSuccess={sendStatus.isSuccess} />
    </>
  );
};
