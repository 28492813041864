import { createSlice } from "@reduxjs/toolkit";

import { IKillChainStore } from "./killchainsType";
import {
  addKillChainAsync,
  addVulnInKillChainAsync,
  getKillchain,
  getKillchainList,
  getKillchainVulnsAsync,
  updateKillchain,
} from "./killchainsAsync";

const initialState: IKillChainStore = {
  killChainById: null,
  killChainList: null,
  killChainVulnList: null,
  sendStatus: {
    isSuccess: false,
    message: "",
    popupIsActive: false
  }
};

export const killchainSlice = createSlice({
  name: "killchain",
  initialState,
  reducers: {
    resetSendStatus(state) {
      state.sendStatus = {
        isSuccess: false,
        message: "",
        popupIsActive: false,
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getKillchainList.fulfilled, (state, action) => {
      state.killChainList = action.payload;
    });
    builder.addCase(getKillchain.fulfilled, (state, action) => {
      state.killChainById = action.payload;
    });
    builder.addCase(getKillchainVulnsAsync.fulfilled, (state, action) => {
      state.killChainVulnList = action.payload;
    });
    builder.addCase(updateKillchain.pending, (state) => {
      state.sendStatus = {
        isSuccess: false,
        message: "",
        popupIsActive: false
      }
    });
    builder.addCase(updateKillchain.fulfilled, (state, action) => {
      state.killChainById = action.payload;
      state.sendStatus = {
        isSuccess: true,
        message: "Успешно обновлено!",
        popupIsActive: true
      }
    });
    builder.addCase(updateKillchain.rejected, (state) => {
      state.sendStatus = {
        isSuccess: false,
        message: "Ошибка при обновлении",
        popupIsActive: false
      }
    });
    builder.addCase(addVulnInKillChainAsync.pending, state => {
      state.sendStatus = {
        isSuccess: false,
        message: "",
        popupIsActive: false,
      }
    });
    builder.addCase(addVulnInKillChainAsync.fulfilled, state => {
      state.sendStatus = {
        isSuccess: true,
        message: "Успешно добавлено",
        popupIsActive: true,
      }
    });
    builder.addCase(addVulnInKillChainAsync.rejected, state => {
      state.sendStatus = {
        isSuccess: false,
        message: "Ошибка при добавлении",
        popupIsActive: true,
      }
    });
    builder.addCase(addKillChainAsync.pending, state => {
      state.sendStatus = {
        isSuccess: false,
        message: "",
        popupIsActive: false,
      }
    });
    builder.addCase(addKillChainAsync.fulfilled, (state, action) => {
      state.sendStatus = {
        isSuccess: true,
        message: "Успешно создано",
        popupIsActive: true,
      }
      state.killChainList?.data.push(action.payload)
    });
    builder.addCase(addKillChainAsync.rejected, state => {
      state.sendStatus = {
        isSuccess: false,
        message: "Ошибка при создании",
        popupIsActive: true,
      }
    });




  },
});

export const { resetSendStatus } = killchainSlice.actions
export default killchainSlice.reducer;
