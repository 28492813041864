export const BASE_URI = process.env.REACT_APP_API_URI;
export const AUTH_ROUTES = {
  LOGIN: '/auth/jwt/login',
  LOGOUT: '/auth/jwt/logout',
  USER_INFO: '/users/me',
  LOGIN_HISTORY: '/users/login_history',
  REPORT_HISTORY: '/users/report_history',
};
export const AUTHORIZATION_USERNAME = 'Bearer';
export const AUTHORIZATION_TOKEN = localStorage.getItem('token') || null;
