import { IEntity, IEntityResponse, IState } from '../storeTypes';
import { ICustomerInfo } from '../customers/customersTypes';
import { IUser } from '../auth/authTypes';

export enum ProjectTypes {
  GET = 'projects/getProject',
  GET_ALL = 'projects/getAllProject',
  GET_ONE = 'projects/getProjectById',
  GET_LOGS = 'projects/getProjectLogs',
  RESTORE = 'projects/restoreProject',
  POST = 'projects/createProject',
  DELETE = 'projects/deleteProject',
  PATCH = 'projects/changeProject'
}

export enum TeamleadTypes {
  GET = 'projects/getTeamleads',
  GET_INFO = 'projects/getTeamleadInfo',
  POST = 'projects/createTeamlead',
  DELETE = 'projects/deleteTeamlead'
}

export enum ManagerTypes {
  GET = 'projects/getManagers',
  GET_INFO = 'projects/getManagerInfo',
  POST = 'projects/createManager',
  DELETE = 'projects/deleteManager',
  ACTIVE_PENTESTERS = 'projects/activePentesters'
}

export interface IProject extends IEntity {
  name: string;
  functional_customer?: string;
  start_date: string;
  end_date: string;
  status?: string;
  gos_order_number?: string;
  gos_order_date?: string;
  teamlead?: IUser | null;
  manager?: IUser | null;
  customer?: ICustomerInfo | null;
  report?: {
    id: string;
    file_extension: string;
    dir_path: string;
    is_approved: boolean;
  }
}



export interface ICreateProjectRequest {
  project: IProject;
  customerId: string;
}

export interface IProjectResponse extends IEntityResponse {
  project: IProject;
}


export interface IDeleteObjectsOrProjectsRequestData {
  category: 'object' | 'vulnerability' | 'killchain' | 'unique_vuln';
  ids: string[];
}
export interface IDeleteObjectsOrProjectsRequest {
  projectId: string;
  data: IDeleteObjectsOrProjectsRequestData;
}

export interface IGetProjectResponse extends Pick<IState, 'count'> {
  data: IProject[];
  count: number;
}

export interface IGetProjectLogsResponse {
  data: ILog[];
}

export interface IDeleteProjectResponse extends Omit<IProjectResponse, 'project'> {
  id: string;
}



export interface ITeamleadRequest {
  teamleadId: string;
}

export interface ICreateTeamleadRequest extends Pick<ITeamleadRequest, 'teamleadId'> {
  projectId: string;
}

export interface ITeamleadResponse extends IEntityResponse {
  teamleadId: string;
}



export interface IManagerRequest {
  managerId: string;
}

export interface ICreateManagerRequest extends Pick<IManagerRequest, 'managerId'> {
  projectId: string;
}

export interface IManagerResponse extends IEntityResponse {
  managerId: string;
}



export interface IProjectErrors {
  customer_name_error: string;
  functional_customer_error: string;
  project_name_error: string;
  start_date_error: string;
  end_date_error: string;
  status_error: string;
  gos_order_number_error: string;
  gos_order_date_error: string;
}

export interface ILog {
  created_at: string;
  user: IUser;
  action_description: string;
}

export interface IProjectsState extends IState, IEntityResponse {
  projects: IProject[];
  allProjects: IProject[];
  activePentesters: { label: string, value: string }[]
  projectById: IProject;
  logs: ILog[];
  errors: IProjectErrors;
  teamleads: IUser[];
  managers: IUser[];
  notificationTitle: string;
  isProjectChanged: boolean;
}

export type IActivePentester = {
  id: string;
  email: string;
  first_name: string;
  role: string;
}

