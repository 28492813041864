import { localization } from '../localization/localization';

export const PROJECTS_ROUTES = {
  FULL_URL: '/projects',
  LOGS: 'logs',
  RESTORE: 'restore',
};

export const projectHeadCells = [
  {
    id: 'checked',
    checked: true,
    image: false,
  },
  {
    id: 'name',
    text: 'Наименование проекта',
  },
  {
    id: 'functional_customer',
    text: 'Функциональный заказчик',
  },
  {
    id: 'start_date',
    text: 'Дата начала',
  },
  {
    id: 'end_date',
    text: 'Дата окончания',
  },
  {
    id: 'status',
    text: 'Статус',
  },
  {
    id: 'gos_order_number',
    text: 'Номер гос. контракта',
  },
  {
    id: 'gos_order_date',
    text: 'Дата гос. контракта',
  },
];

export const projectHeader = {
  name: { label: 'Наименование проекта' },
  functional_customer: { label: 'Функциональный заказчик' },
  start_date: { label: 'Дата начала' },
  end_date: { label: 'Дата окончания' },
  status: { label: 'Статус' },
  gos_order_number: { label: 'Номер гос. контракта' },
  gos_order_date: { label: 'Дата гос. контракта' },
};


export const projectStatusPopupItems = [
  {
    value: 'active',
    text: 'Активный',
    id: 1,
  },
  {
    value: 'completed',
    text: 'Завершенный',
    id: 2,
  },
  {
    value: 'planned',
    text: 'Планируемый',
    id: 3,
  },
];

export const prepareProjectStatusToRu: { [index: string]: string } = {
  'active': 'Активный',
  'completed': 'Завершенный',
  'planned': 'Планируемый',
  'loaded': 'Загруженный',
};

export const prepareProjectStatusToEng: { [index: string]: string } = {
  'Активный': 'active',
  'Завершенный': 'completed',
  'Планируемый': 'planned',
};

export const projectStatusList = [
  { value: 'active', label: 'Активный' },
  { value: 'completed', label: 'Завершенный' },
  { value: 'planned', label: 'Планируемый' },
];
