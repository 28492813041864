import {Dispatch, SetStateAction} from 'react';

import {
    setProjectStatusError,
    setProjectFunctionalCustomerError,
    setProjectCustomerNameError,
    setProjectEndDateError,
    setProjectGosOrderDateError,
    setProjectGosOrderNumberError,
    setProjectNameError,
    setProjectStartDateError,
} from '../../../../store/projects/projectsSlice';
import {EMPTY_ERROR_MESSAGE} from '../../../../constants/errors';
import {IProject} from '../../../../store/projects/projectsTypes';

/**
 *
 * @param {any} dispatch                                           Dispatcher to change values in the store
 * @param {Dispatch<SetStateAction<IProject>>} setProject          Setter for resetting project data
 * @param {Dispatch<SetStateAction<string>>} setProjectName        Setter for resetting project name data
 * @param {Dispatch<SetStateAction<string>>} setFunctionalCustomer Setter for resetting functional customer data
 * @param {Dispatch<SetStateAction<Date | null>>} setStartDate     Setter for resetting start date data
 * @param {Dispatch<SetStateAction<Date | null>>} setEndDate       Setter for resetting end date data
 * @param {Dispatch<SetStateAction<string>>} setStatus             Setter for resetting status data
 * @param {Dispatch<SetStateAction<Date | null>>} setGosOrderDate  Setter for resetting gos order date data
 * @param {Dispatch<SetStateAction<string>>} setCustomerId         Setter for resetting customer id data
 * @param {Dispatch<SetStateAction<string>>} setCustomerName       Setter for resetting customer name data
 */
export const resetProjectData = (
    dispatch: any,
    setProject: Dispatch<SetStateAction<IProject>>,
    setProjectName?: Dispatch<SetStateAction<string>>,
    setFunctionalCustomer?: Dispatch<SetStateAction<string>>,
    setStartDate?: Dispatch<SetStateAction<Date | null>>,
    setEndDate?: Dispatch<SetStateAction<Date | null>>,
    setStatus?: Dispatch<SetStateAction<string>>,
    setGosOrderDate?: Dispatch<SetStateAction<Date | null>>,
    setCustomerId?: Dispatch<SetStateAction<string>>,
    setCustomerName?: Dispatch<SetStateAction<string>>,
) => {
    setProject({
        additional_info: '',
        functional_customer: '',
        status: '',
        end_date: '',
        gos_order_date: '',
        gos_order_number: '',
        customer: { id: '' },
        name: '',
        start_date: '',
    });

    if (setProjectName && setFunctionalCustomer && setStartDate && setEndDate && setStatus && setGosOrderDate && setCustomerId && setCustomerName) {
        setProjectName('');
        setFunctionalCustomer('');
        setStartDate(null);
        setEndDate(null);
        setGosOrderDate(null);
        setStatus('');
        setCustomerId('');
        setCustomerName('');
    }

    dispatch(setProjectCustomerNameError(EMPTY_ERROR_MESSAGE));
    dispatch(setProjectNameError(EMPTY_ERROR_MESSAGE));
    dispatch(setProjectFunctionalCustomerError(EMPTY_ERROR_MESSAGE));
    dispatch(setProjectStartDateError(EMPTY_ERROR_MESSAGE));
    dispatch(setProjectEndDateError(EMPTY_ERROR_MESSAGE));
    dispatch(setProjectStatusError(EMPTY_ERROR_MESSAGE));
    dispatch(setProjectGosOrderDateError(EMPTY_ERROR_MESSAGE));
    dispatch(setProjectGosOrderNumberError(EMPTY_ERROR_MESSAGE));
};
