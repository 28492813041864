import React, { FC } from 'react';

import cxb from 'classnames/bind';

import { TextArea } from '../../../shared';

import { InputTypeEnum } from '../../../components/input/InputTypes';
import InputForm from '../../../components/inputForm/InputForm';

import style from './ImageCard.module.scss';
import { setScreenshotSequence } from '../../../store/vulns/vulnsSlice';

interface ImageCardProps {
  imageSrc: string;
  imageName: string;
  textDescription: string;
  sequence?: number;
  onDeleteHandler?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onTextAreaChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onSequenceChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isDeleteButton?: boolean;
}

export const ImageCard: FC<ImageCardProps> = ({ isDeleteButton = true, ...props }) => {
  const {
    imageSrc,
    imageName,
    textDescription,
    onDeleteHandler,
    onTextAreaChange,
    sequence,
    onSequenceChange,
    ...otherProps
  } = props;

  const cx = cxb.bind(style);

  return (
    <div {...otherProps} className={cx(style['ImageCard'])}>

      <h3 className={cx(style['ImageCard_header'])}>
        {imageName}
      </h3>

      <img
        src={imageSrc}
        alt={imageName}
        className={cx(style['ImageCard_image'])}
      />

      <TextArea
        labelText={"Описание: "}
        value={textDescription}
        onChange={onTextAreaChange}
        placeholderText={''}
      />
        <div className={cx(style['ImageCard_button-box'])}>
            <InputForm
                text="№ скриншота: "
                value={sequence || ''}
                onChange={onSequenceChange}
                className={cx('ImageCard_input')}
                type={InputTypeEnum.Number}
                min={1}
            />
            {
                isDeleteButton && (
                    <button
                        className={cx(style['ImageCard_button'])}
                        onClick={onDeleteHandler}
                    >
                        <img
                            src="/assets/icons/trash.svg"
                            alt=""
                            className={cx(style['ImageCard_button-image'])}
                        /> Удалить
                    </button>
                )
            }
        </div>


    </div>
  );
};
