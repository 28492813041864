import { FC, useEffect, useState } from 'react';

import { Link, useNavigate, useParams } from 'react-router-dom';

import classNames from 'classnames';

import Button from '../../../components/button/Button';
import { ButtonTypeEnum } from '../../../components/button/ButtonTypes';
import Loader from '../../../components/loader/Loader';
import ConfirmModal from '../../../components/modals/general/confirm/ConfirmModal';
import ChangeVuln from '../../../components/modals/vulns/ChangeVuln';
import Navbar from '../../../components/navbar/Navbar';
import Notification from '../../../components/notification/Notification';
import PageItem from '../../../components/pageItem/PageItem';
import { IItemProps } from '../../../components/pageItem/PageItemTypes';
import { IPopupItem } from '../../../components/popup/PopupTypes';
import Text from '../../../components/text/Text';
import { TextVariantEnum } from '../../../components/text/TextTypes';
import Title from '../../../components/title/Title';
import { TitleVariantEnum } from '../../../components/title/TitleTypes';
import { prepareRiskLevelToRu } from '../../../constants/vulns';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { selectProfileData } from '../../../store/auth/authSelectors';
import {
  deleteVuln,
  deleteVulnScreenshot,
  deleteVulnScreenshots,
  getVulnById,
  getVulnScreenshots,
} from '../../../store/vulns/vulnsAsync';
import {
  selectVulnById,
  selectVulns,
} from '../../../store/vulns/vulnsSelectors';
import { IGetScreenshotsList, IVuln } from '../../../store/vulns/vulnsTypes';
import { preparedVulnCvssItems } from '../../../utils/prepare/preparedVulnCvssItems';

import Archive from '../../../components/archive/Archive';
import Fancybox from '../../../components/fancybox/Fancybox';
import { localization } from '../../../localization/localization';
import { ROUTES } from '../../../router/routes';
import { getProjectById } from '../../../store/projects/projectsAsync';
import { selectProjectById } from '../../../store/projects/projectsSelectors';

import { getKillchain } from '../../../store/killchains/killchainsAsync';
import { selectKillchain } from '../../../store/killchains/killchainsSelectors';

import { AddVulnInKillChainModal } from '../../../components/modals/vulns/addVulnInKillChainModal';

import { NotificationPopUp } from '../../../shared/NotificationPopUp/ui/NotificationPopUp';
import { updateTemplateValue } from '../../../store/templates/templatesSlice';
import { ObjectType, RiskLevelType } from '../../../store/templates/templatesTypes';

import styles from './VulnPage.module.scss';



const VulnPage: FC = () => {
  const dispatch = useAppDispatch();

  const { sendStatus } = useAppSelector(selectKillchain);


  const { vulnId, projectId, objectId, objectType } = useParams();

  const navigate = useNavigate();

  const { role, id } = useAppSelector(selectProfileData);
  const { customer, teamlead } = useAppSelector(selectProjectById);

  const {
    vulnScreenshots: screenshots,
    isLoading,
    error,
    status,
  } = useAppSelector(selectVulns);

  const { killChainById } = useAppSelector(selectKillchain);

  const {
    additional_info: additionalInfo,
    cvss_score: cvssScore,
    cvss_vector: cvssVector,
    risk_level: riskLevel,
    location: locationName,
    name,
    description,
    cause_of_occurrence: causeOccurrence,
    procedure_exploiting: procedureExploiting,
    recommendations,
    negative_consequences: negativeConsequences,
    owner,
    cve_id,
    cwe_id,
    is_delete: isDelete,
    status: statusVuln,
    is_killchain,
    killchain_id,

  }: IVuln = useAppSelector(selectVulnById);
  const [cvssAV, setCvssAV] = useState<IPopupItem>({
    text: '',
    value: '',
  });

  const [cvssAC, setCvssAC] = useState<IPopupItem>({
    text: '',
    value: '',
  });
  const [cvssPR, setCvssPR] = useState<IPopupItem>({
    text: '',
    value: '',
  });
  const [cvssUI, setCvssUI] = useState<IPopupItem>({
    text: '',
    value: '',
  });
  const [cvssS, setCvssS] = useState<IPopupItem>({
    text: '',
    value: '',
  });
  const [cvssC, setCvssC] = useState<IPopupItem>({
    text: '',
    value: '',
  });
  const [cvssI, setCvssI] = useState<IPopupItem>({
    text: '',
    value: '',
  });
  const [cvssA, setCvssA] = useState<IPopupItem>({
    text: '',
    value: '',
  });
  const [screenshotList, setScreenshotList] = useState<IGetScreenshotsList[]>(
    [],
  );

  const [screenshotId, setScreenshotId] = useState<string>('');
  const [fullScreenshotId, setFullScreenshotId] = useState<string>('');
  const [isChangeVulnModal, setChangeVulnModal] = useState<boolean>(false);
  const [isAddVulnInKillChainModal, setIsAddVulnInKillChainModal] = useState<boolean>(false);

  const [isDeleteVulnModal, setDeleteVulnModal] = useState<boolean>(false);
  const [isDeleteScreenshotsModal, setDeleteScreenshotsModal] =
    useState<boolean>(false);
  const [isDeleteScreenshotModal, setDeleteScreenshotModal] =
    useState<boolean>(false);
  const [hasTeamleadAccess, setTeamleadAccess] = useState<boolean>(false);

  useEffect(() => {
    if (projectId && !customer) {
      dispatch(getProjectById(projectId));
    }
  }, [dispatch, projectId, customer]);

  useEffect(() => {
    setTeamleadAccess(teamlead?.id === id);
  }, [id, teamlead]);

  useEffect(() => {
    if (cvssVector) {
      preparedVulnCvssItems(
        cvssVector,
        setCvssAV,
        setCvssAC,
        setCvssPR,
        setCvssUI,
        setCvssS,
        setCvssC,
        setCvssI,
        setCvssA,
      );
    }
  }, [cvssVector]);

  useEffect(() => {
    if (projectId && vulnId && objectId && objectType) {
      dispatch(
        getVulnScreenshots({
          vulnId,
          projectId,
          objectId,
          objectType,
        }),
      );
      dispatch(
        getVulnById({
          vulnId,
          projectId,
          objectId,
          objectType,
        }),
      );
    }
  }, [dispatch, objectId, objectType, projectId, vulnId, sendStatus]);

  useEffect(() => {
    screenshots && setScreenshotList(screenshots);
  }, [screenshots]);

  const removeVulnHandler = () => {
    if (vulnId && projectId && objectId && objectType) {
      dispatch(
        deleteVuln({
          vulnId,
          projectId,
          objectId,
          objectType,
        }),
      );
    }

    navigate(
      `/${ROUTES.PROJECTS}/${projectId}/${ROUTES.OBJECTS}${objectType}/${objectId}/${ROUTES.VULNS}`,
    );
  };

  const removeScreenshotsDeleteHandler = () => {
    if (vulnId && projectId && objectId && objectType) {
      dispatch(
        deleteVulnScreenshots({
          objectId,
          projectId,
          vulnId,
          objectType,
        }),
      );
    }
  };

  const removeScreenshotDeleteHandler = () => {
    if (vulnId && projectId && objectId && objectType) {
      dispatch(
        deleteVulnScreenshot({
          projectId,
          objectId,
          vulnId,
          screenId: screenshotId,
          fullScreenId: fullScreenshotId,
          objectType,
        }),
      );
    }
  };
  const onChangeVulnModalChange = () =>
    setChangeVulnModal((prevState) => !prevState);
  const onDeleteVulnModalChange = () =>
    setDeleteVulnModal((prevState) => !prevState);
  const onDeleteScreenshotsModalChange = () => {
    setDeleteScreenshotsModal((prevState) => !prevState);
  };

  const onConfirmDeleteScreenshotModalHandler = (event: any) => {
    const closeIconId = event.target.id;
    const prepareScreenshotId = closeIconId.split('.')[0];

    if (closeIconId) {
      setDeleteScreenshotModal((prevState) => !prevState);
      setScreenshotId(prepareScreenshotId);
      setFullScreenshotId(closeIconId);

      event.stopPropagation();
    }
  };

  const onBackNavigate = () => {
    navigate(
      `/${ROUTES.PROJECTS}/${projectId}/${ROUTES.OBJECTS}${objectType}/${objectId}/${ROUTES.VULNS}`,
    );
  };
  const onClickSaveTemplate = () => {
    dispatch(updateTemplateValue({
      name: name || '',
      cve_id: cve_id?.join(', ') || '',
      cwe_id: cwe_id?.join(', ') || '',
      location: locationName || '',
      description: description || '',
      cause_of_occurrence: causeOccurrence || '',
      negative_consequences: negativeConsequences?.join(',') || '',
      cvss_score: cvssScore?.toString() || '',
      cvss_vector: cvssVector || '',
      risk_level: riskLevel as RiskLevelType || 'info',
      procedure_exploiting: procedureExploiting || '',
      recommendations: recommendations || '',
      additional_info: additionalInfo || '',
      is_delete: false,
      object_type: objectType as ObjectType || '',
      id: '',
      tpl_name: name || '',
      status: 'recheck',
    }));
    navigate(ROUTES.COMMON + ROUTES.TEMPLATES);
  };

  const infoList: IItemProps[] = [
    {
      title: localization.vuln.infoList.nameText,
      text: name ? name : '-',
      id: 1,
      isFirst: true,
    },
    {
      title: localization.vuln.infoList.locationText,
      text: locationName ? locationName : '-',
      id: 2,
    },
    {
      title: localization.vuln.infoList.negativeConsequencesText,
      text:
        negativeConsequences && negativeConsequences?.length !== 0
          ? negativeConsequences.join(', ')
          : '-',
      id: 3,
    },
    {
      title: localization.vuln.infoList.procedureExploitingText,
      text: procedureExploiting ? procedureExploiting : '-',
      id: 4,
    },
    {
      title: localization.vuln.infoList.cveIdText,
      text: cve_id && cve_id.length !== 0 ? cve_id.join(', ') : '-',
      id: 5,
    },
    {
      title: localization.vuln.infoList.cweIdText,
      text: cwe_id && cwe_id.length !== 0 ? cwe_id.join(', ') : '-',
      id: 6,
    },
    {
      title: localization.vuln.infoList.killChain,
      text: is_killchain ? 'Да' : 'Нет',
      id: 7,
    },
    {
      title: localization.vuln.infoList.includeIn,
      text: killchain_id && killChainById
        ? <Link
          to={`/projects/${projectId}/killchains/${killchain_id}`}
        >
          {killChainById.name}
        </Link>
        : '-',
      id: 8,
    },
  ];

  const secondaryInfoList: IItemProps[] = [
    {
      title: localization.vuln.secondaryInfoList.cvssScoreText,
      text: cvssScore !== null ? cvssScore.toString() : '-',
      id: 1,
      isFirst: true,
    },
    {
      title: localization.vuln.secondaryInfoList.riskLevelText,
      //@ts-ignore
      text: riskLevel ? (
        <span className={styles[`vuln-risk-level_${riskLevel}`]}>
          {prepareRiskLevelToRu[riskLevel]}
        </span>
      ) : (
        '-'
      ),
      id: 2,
    },
    {
      title: localization.vuln.secondaryInfoList.descriptionText,
      text: description ? description : '-',
      id: 3,
    },
    {
      title: localization.vuln.secondaryInfoList.causeOccurrenceText,
      text: causeOccurrence ? causeOccurrence : '-',
      id: 4,
    },
    {
      title: localization.vuln.secondaryInfoList.recommendationsText,
      text: recommendations ? recommendations : '-',
      id: 5,
    },
    {
      title: localization.modals.additionalInfoText,
      text: additionalInfo ? additionalInfo : '-',
      id: 6,
    },
    {
      title: 'Статус',
      text: statusVuln ? statusVuln : '-',
      id: 7,
    },
  ];

  const stateInfoList: IItemProps[] = [
    {
      title: localization.vuln.stateInfo.pentester,
      text: owner?.id ? `${owner?.first_name} (${owner?.email})` : '-',
      id: 1,
    },
  ];

  const cvssList: IItemProps[] = [
    {
      title: localization.vuln.cvssInfo.AV,
      text: cvssAV.text ? cvssAV.text : '-',
      id: 1,
    },
    {
      title: localization.vuln.cvssInfo.AC,
      text: cvssAC.text ? cvssAC.text : '-',
      id: 2,
    },
    {
      title: localization.vuln.cvssInfo.PR,
      text: cvssPR.text ? cvssPR.text : '-',
      id: 3,
    },
    {
      title: localization.vuln.cvssInfo.UI,
      text: cvssUI.text ? cvssUI.text : '-',
      id: 4,
    },
    {
      title: localization.vuln.cvssInfo.S,
      text: cvssS.text ? cvssS.text : '-',
      id: 5,
    },
    {
      title: localization.vuln.cvssInfo.C,
      text: cvssC.text ? cvssC.text : '-',
      id: 6,
    },
    {
      title: localization.vuln.cvssInfo.I,
      text: cvssI.text ? cvssI.text : '-',
      id: 7,
    },
    {
      title: localization.vuln.cvssInfo.A,
      text: cvssA.text ? cvssA.text : '-',
      id: 8,
    },
  ];

  useEffect(() => {
    if (projectId && killchain_id) {
      dispatch(getKillchain({ project_id: projectId, vulnerability_id: killchain_id }));
    }
  }, [dispatch, projectId, killchain_id]);

  return (
    <>
      <Navbar />
      <div className={styles['vuln-content']}>
        {isLoading ? (
          <Loader />
        ) : (
          <div className={styles['vuln-body']}>
            <div className={styles['vuln-body-info']}>
              <Title
                className={styles['vuln-body-info-title']}
                variant={TitleVariantEnum.H3}
              >
                {localization.vuln.infoList.title}
              </Title>
              <div className={styles['vuln-body-info-list']}>
                {infoList.map((item) => (
                  <PageItem key={item.id} {...item} />
                ))}
              </div>
            </div>
            <div className={styles['vuln-body-info-secondary']}>
              <Title
                className={
                  styles['vuln-body-info-secondary-title']
                }
                variant={TitleVariantEnum.H3}
              >
                {localization.common.secondaryInfoTitle}
              </Title>
              <div
                className={
                  styles['vuln-body-info-secondary-list']
                }
              >
                {secondaryInfoList.map((item) => (
                  <PageItem key={item.id} {...item} />
                ))}
                <div
                  className={
                    styles[
                      'vuln-body-info-secondary-screenshots-wrapper'
                    ]
                  }
                >
                  <Title variant={TitleVariantEnum.H3}>
                    {
                      localization.vuln.interactive
                        .screenshots
                    }
                  </Title>
                  {screenshotList?.length !== 0 ? (
                    <Fancybox
                      options={{
                        Carousel: {
                          infinite: false,
                        },
                      }}
                      className={
                        styles[
                          'vuln-body-info-secondary-screenshots'
                        ]
                      }
                    >
                      {screenshotList?.map(
                        (screenshot) => (
                          <div
                            className={
                              styles[
                                'vuln-body-info-secondary-screenshot-wrapper'
                              ]
                            }
                          >
                            <a
                              onClick={
                                onConfirmDeleteScreenshotModalHandler
                              }
                              key={screenshot.id}
                              data-fancybox="gallery"
                              href={`/filestore/media/screenshots/${screenshot.id}.jpg`}
                            >
                              <img
                                className={
                                  styles[
                                    'vuln-body-info-secondary-screenshot'
                                  ]
                                }
                                src={
                                  '/filestore/media/screenshots/' +
                                  screenshot.id +
                                  '.jpg'
                                }
                                alt={
                                  screenshot.id
                                }
                              />
                              <div
                                id={
                                  screenshot.id
                                }
                                className={
                                  styles[
                                    'vuln-body-info-secondary-close-wrapper'
                                  ]
                                }
                              >
                                <img
                                  id={
                                    screenshot.id
                                  }
                                  className={
                                    styles[
                                      'vuln-body-info-secondary-close'
                                    ]
                                  }
                                  src="/assets/icons/delete.png"
                                  alt={
                                    localization
                                      .common
                                      .closeAlt
                                  }
                                />
                              </div>
                            </a>
                            <div>
                              <h3
                                className={
                                  styles[
                                    'vuln-body-info-secondary-screenshot-header'
                                  ]
                                }
                              >
                                Описание:
                              </h3>
                              <p
                                className={
                                  styles[
                                    'vuln-body-info-secondary-screenshot-description'
                                  ]
                                }
                              >
                                {screenshot.description
                                  ? screenshot.description
                                  : '-'}
                              </p>
                            </div>
                          </div>
                        ),
                      )}
                    </Fancybox>
                  ) : (
                    '-'
                  )}
                </div>
              </div>
            </div>
            <div className={styles['vuln-panel']}>
              <div className={styles['vuln-panel-top-wrapper']}>
                <div className={styles['vuln-panel-top']}>
                  <Title
                    className={
                      styles['vuln-panel-top-title']
                    }
                    variant={TitleVariantEnum.H3}
                  >
                    <>
                      {
                        localization.vuln.interactive
                          .title
                      }
                      {isDelete && <Archive />}
                    </>
                  </Title>
                  <div
                    className={
                      styles['vuln-panel-top-buttons']
                    }
                  >
                    <Button
                      onClick={onBackNavigate}
                      buttonText={
                        localization.common
                          .backButtonText
                      }
                    />
                    {(role === 'pentester' || role === 'teamlead') &&
                      < Button
                        buttonText="Сохранить как шаблон"
                        onClick={onClickSaveTemplate}
                      />
                    }
                    {(role === 'admin' ||
                      role === 'pentester' ||
                      hasTeamleadAccess ||
                      owner?.id === id) && (
                      <>
                        <Button
                          onClick={
                            onChangeVulnModalChange
                          }
                          buttonText={
                            localization.common
                              .changeButtonText
                          }
                        />
                        <Button
                          onClick={
                            () => {
                              setIsAddVulnInKillChainModal(true);
                            }
                          }
                          buttonText={
                            localization.common
                              .addVulnInKillChain
                          }
                        />

                      </>
                    )}
                    {(role === 'admin' ||
                      hasTeamleadAccess ||
                      owner?.id === id) && (
                      <Button
                        onClick={
                          onDeleteVulnModalChange
                        }
                        buttonText={
                          localization.common
                            .deleteButtonText
                        }
                        type={ButtonTypeEnum.Red}
                      />
                    )}
                  </div>
                  {(role === 'admin' ||
                    hasTeamleadAccess ||
                    owner?.id === id) &&
                    screenshots?.length !== 0 && (
                    <div
                      className={classNames(
                        styles[
                          'vuln-panel-top-buttons'
                        ],
                        styles[
                          'vuln-panel-top-buttons_secondary'
                        ],
                      )}
                    >
                      <Button
                        onClick={
                          onDeleteScreenshotsModalChange
                        }
                        buttonText={
                          localization.vuln
                            .interactive
                            .deleteScreenshotsText
                        }
                        type={ButtonTypeEnum.Red}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className={styles['vuln-panel-divider']}></div>
              <div className={styles['vuln-panel-bottom']}>
                <Text
                  variant={TextVariantEnum.L}
                  className={
                    styles['vuln-panel-bottom-title']
                  }
                >
                  {localization.common.stateInfoTitle}
                </Text>
                <div
                  className={styles['vuln-panel-bottom-list']}
                >
                  {stateInfoList.map((item) => (
                    <PageItem
                      {...item}
                      className={
                        styles[
                          'vuln-panel-bottom-item_secondary'
                        ]
                      }
                      classNameTitle={
                        styles[
                          'vuln-panel-bottom-item-title'
                        ]
                      }
                      classNameText={
                        styles[
                          'vuln-panel-bottom-item-text'
                        ]
                      }
                      key={item.id}
                    />
                  ))}
                </div>
              </div>
              <div className={styles['vuln-panel-divider']}></div>
              <div className={styles['vuln-panel-bottom']}>
                <Text
                  variant={TextVariantEnum.L}
                  className={
                    styles['vuln-panel-bottom-title']
                  }
                >
                  {localization.vuln.cvssInfo.title}
                </Text>
                <div
                  className={styles['vuln-panel-bottom-list']}
                >
                  {cvssList.map((item) => (
                    <PageItem
                      {...item}
                      className={
                        styles['vuln-panel-bottom-item']
                      }
                      classNameTitle={
                        styles[
                          'vuln-panel-bottom-item-title'
                        ]
                      }
                      classNameText={
                        styles[
                          'vuln-panel-bottom-item-text'
                        ]
                      }
                      key={item.id}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}
        <ChangeVuln
          isModalVisible={isChangeVulnModal}
          setModalVisible={setChangeVulnModal}
          currentObjectId={objectId}
          currentScreenshots={screenshots}
          objectType={objectType}
        />
        <AddVulnInKillChainModal
          isModalVisible={isAddVulnInKillChainModal}
          setModalVisible={setIsAddVulnInKillChainModal}
          projectId={projectId || ''}
          vulnId={vulnId || ''}
          killChainId={killchain_id || undefined}
        />
        <ConfirmModal
          isModalVisible={isDeleteVulnModal}
          setModalVisible={setDeleteVulnModal}
          text={localization.vuln.confirmVulnText}
          onConfirmClick={removeVulnHandler}
        />
        <ConfirmModal
          isModalVisible={isDeleteScreenshotsModal}
          setModalVisible={setDeleteScreenshotsModal}
          text={localization.vuln.confirmScreenshotsText}
          onConfirmClick={removeScreenshotsDeleteHandler}
        />
        <ConfirmModal
          isModalVisible={isDeleteScreenshotModal}
          setModalVisible={setDeleteScreenshotModal}
          text={localization.vuln.confirmScreenshotText}
          onConfirmClick={removeScreenshotDeleteHandler}
        />
      </div>
      {status !== 201 &&
        status !== 202 &&
        status !== 203 &&
        status !== 205 &&
        status !== 206 &&
        status !== 207 && (
        <Notification
          status={status}
          error={error}
          title={localization.vuln.notificationTitle}
        />
      )}
      <NotificationPopUp
        isActive={sendStatus.popupIsActive}
        text={sendStatus.message}
        isSuccess={sendStatus.isSuccess}
      />
    </>
  );
};

export default VulnPage;
