import React, { JSX, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import cnb from 'classnames/bind';

import { prepareRiskLevelToRu } from '../../constants/vulns';
import { useAppSelector } from '../../hooks/useAppSelector';
import { selectUniqueVulns } from '../../store/uniqueVulns/uniqueVulnsSelectors';
import Loader from '../../components/loader/Loader';
import Title from '../../components/title/Title';
import { TitleVariantEnum } from '../../components/title/TitleTypes';
import PageItem from '../../components/pageItem/PageItem';
import Text from '../../components/text/Text';
import { TextVariantEnum } from '../../components/text/TextTypes';
import { preparedVulnCvssItems } from '../../utils/prepare/preparedVulnCvssItems';
import { IPopupItem } from '../../components/popup/PopupTypes';
import { getNextSortParam, getSortString, sortListType, TableComponent } from '../../shared';
import { getDefaultSortParams } from '../../shared/lib';
import Pagination from '../../components/pagination/Pagination';
import { localization } from '../../localization/localization';
import { IItemProps } from '../../components/pageItem/PageItemTypes';
import Navbar from '../../components/navbar/Navbar';
import { getObjectsForUniqueVulnsAsync, getUniqueVulnByIdAsync } from '../../store/uniqueVulns/uniqueVulnsAsync';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import ChangeUniqueVuln from '../../components/modals/vulns/ChangeUniqueVuln';
import Button from '../../components/button/Button';
import { NotificationPopUp } from '../../shared/NotificationPopUp/ui/NotificationPopUp';
import { selectProfileData } from '../../store/auth/authSelectors';
import { selectProjectById } from '../../store/projects/projectsSelectors';
import { getProjectById } from '../../store/projects/projectsAsync';
import InputForm from '../../components/inputForm/InputForm';

import styles from './projectVulnPage.module.scss';

export interface ITableObjectData {
  object_identifier: string;
  object_identifier_additional: string;
  object_type: string;
  links: JSX.Element;
}
export const ProjectVulnPage = () => {

  const cx = cnb.bind(styles);

  const objectsHeader = {
    object_identifier: { label: 'Идентификатор' },
    object_identifier_additional: { label: 'Идентификатор доп' },
    object_type: { label: 'Тип объекта' },
    links: { label: 'Ссылки', isUnsorted: true },
  };


  const dispatch = useAppDispatch();
  const { uniqueVuln, objectsForUniqueVuln, count, sendDataStatus } = useAppSelector(selectUniqueVulns);

  const { projectId, updateId } = useParams();
  const { role, id } = useAppSelector(selectProfileData);
  const { teamlead } = useAppSelector(selectProjectById);

  const [tableData, setTableData] = useState<ITableObjectData[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [sortParams, setSortParams] = useState<sortListType>(getDefaultSortParams(objectsHeader));
  const [searchValue, setSearchValue] = useState('');
  const [pagination, setPagination] = useState({ offset: 0, limit: 100 });


  useEffect(() => {
    const newTableData: ITableObjectData[] = [];
    if (objectsForUniqueVuln) {
      for (const object of objectsForUniqueVuln) {
        const objectLink = `/projects/${projectId}/objects/${object.object_type}/${object.id}`;
        const vulnLink = `${objectLink}/vulns/${object.vulnerability_id}`;
        const link = <>
          <Link to={objectLink}>Объект</Link> | <Link to={vulnLink}>Уязвимость</Link>
        </>;
        newTableData.push({
          object_identifier: object.object_identifier,
          object_identifier_additional: object.object_identifier_additional,
          object_type: object.object_type,
          links: link,
        });
      }
      setTableData(newTableData);
    }
  }, [objectsForUniqueVuln, projectId]);

  const [cvssAV, setCvssAV] = useState<IPopupItem>({
    text: '',
    value: '',
  });

  const [cvssAC, setCvssAC] = useState<IPopupItem>({
    text: '',
    value: '',
  });
  const [cvssPR, setCvssPR] = useState<IPopupItem>({
    text: '',
    value: '',
  });
  const [cvssUI, setCvssUI] = useState<IPopupItem>({
    text: '',
    value: '',
  });
  const [cvssS, setCvssS] = useState<IPopupItem>({
    text: '',
    value: '',
  });
  const [cvssC, setCvssC] = useState<IPopupItem>({
    text: '',
    value: '',
  });
  const [cvssI, setCvssI] = useState<IPopupItem>({
    text: '',
    value: '',
  });
  const [cvssA, setCvssA] = useState<IPopupItem>({
    text: '',
    value: '',
  });

  const [hasTeamleadAccess, setTeamleadAccess] = useState<boolean>(false);


  useEffect(() => {
    setTeamleadAccess(teamlead?.id === id);
  }, [id, teamlead]);

  useEffect(() => {
    if (!teamlead && projectId) {
      dispatch(getProjectById(projectId));
    }
    if (projectId && updateId) {
      dispatch(getUniqueVulnByIdAsync({ projectId, updateId }));
      dispatch(getObjectsForUniqueVulnsAsync({ projectId, updateId, ...pagination, sortBy: getSortString(sortParams) }));
    }
  }, []);

  useEffect(() => {
    if (projectId && updateId) {
      dispatch(getObjectsForUniqueVulnsAsync({ projectId, updateId, ...pagination, sortBy: getSortString(sortParams) }));
    }
  }, [pagination, sortParams]);


  useEffect(() => {
    if (uniqueVuln?.cvss_vector) {
      preparedVulnCvssItems(
        uniqueVuln.cvss_vector,
        setCvssAV,
        setCvssAC,
        setCvssPR,
        setCvssUI,
        setCvssS,
        setCvssC,
        setCvssI,
        setCvssA,
      );
    }
  }, [uniqueVuln]);

  const cvssList: IItemProps[] = [
    {
      title: localization.vuln.cvssInfo.AV,
      text: cvssAV.text ? cvssAV.text : '-',
      id: 1,
    },
    {
      title: localization.vuln.cvssInfo.AC,
      text: cvssAC.text ? cvssAC.text : '-',
      id: 2,
    },
    {
      title: localization.vuln.cvssInfo.PR,
      text: cvssPR.text ? cvssPR.text : '-',
      id: 3,
    },
    {
      title: localization.vuln.cvssInfo.UI,
      text: cvssUI.text ? cvssUI.text : '-',
      id: 4,
    },
    {
      title: localization.vuln.cvssInfo.S,
      text: cvssS.text ? cvssS.text : '-',
      id: 5,
    },
    {
      title: localization.vuln.cvssInfo.C,
      text: cvssC.text ? cvssC.text : '-',
      id: 6,
    },
    {
      title: localization.vuln.cvssInfo.I,
      text: cvssI.text ? cvssI.text : '-',
      id: 7,
    },
    {
      title: localization.vuln.cvssInfo.A,
      text: cvssA.text ? cvssA.text : '-',
      id: 8,
    },
  ];

  const infoList: IItemProps[] = [
    {
      title: 'Имя: ',
      text: uniqueVuln?.name ? uniqueVuln.name : '-',
      id: 1,
      isFirst: true,
    },
    {
      title: localization.vuln.infoList.locationText,
      text: uniqueVuln?.location ? uniqueVuln.location : '-',
      id: 2,
    },
    {
      title: localization.vuln.infoList.negativeConsequencesText,
      text: (uniqueVuln?.negative_consequences && uniqueVuln?.negative_consequences?.length !== 0) ?
        uniqueVuln.negative_consequences.join(', ') : '-',
      id: 3,
    },
    {
      title: localization.vuln.infoList.procedureExploitingText,
      text: uniqueVuln?.procedure_exploiting || '-',
      id: 4,
    },
    {
      title: localization.vuln.infoList.cveIdText,
      text: (uniqueVuln?.cve_id && uniqueVuln.cve_id.length !== 0) ? uniqueVuln?.cve_id.join(', ') : '-',
      id: 5,
    },
    {
      title: localization.vuln.infoList.cweIdText,
      text: (uniqueVuln?.cwe_id && uniqueVuln?.cwe_id.length !== 0) ? uniqueVuln.cwe_id.join(', ') : '-',
      id: 6,
    },
  ];

  const isLoading = false;

  const secondaryInfoList: IItemProps[] = [

    {
      title: localization.vuln.secondaryInfoList.descriptionText,
      text: uniqueVuln?.description || '-',
      id: 3,
    },
    {
      title: localization.vuln.secondaryInfoList.causeOccurrenceText,
      text: uniqueVuln?.cause_of_occurrence || '-',
      id: 4,
    },
    {
      title: localization.vuln.secondaryInfoList.recommendationsText,
      text: uniqueVuln?.recommendations || '-',
      id: 5,
    },
    {
      title: localization.modals.additionalInfoText,
      text: uniqueVuln?.additional_info || '-',
      id: 6,
    },
    {
      title: 'Статус',
      text: uniqueVuln?.status || '-',
      id: 7,
    },
  ];

  const stateCvssItemList: IItemProps[] = [
    {
      title: 'Вектор: ',
      text: uniqueVuln?.cvss_vector ? uniqueVuln?.cvss_vector : '-',
      id: 1,
      isFirst: true,
    },
    {
      title: 'Оценка: ',
      text: uniqueVuln?.cvss_score ? uniqueVuln?.cvss_score.toString() : '-',
      id: 2,
      isFirst: true,
    },
    {
      title: localization.vuln.secondaryInfoList.riskLevelText,
      text: uniqueVuln?.risk_level ?
        <span className={styles[`vuln-risk-level_${uniqueVuln.risk_level}`]}>
          {prepareRiskLevelToRu[uniqueVuln.risk_level]}
        </span> : '-',
      id: 3,
    },
  ];

  const stateInfoList: IItemProps[] = [
    {
      title: localization.vuln.stateInfo.pentester,
      text: uniqueVuln?.owner?.id ? `${uniqueVuln?.owner?.first_name} (${uniqueVuln?.owner?.email})` : '-',
      id: 1,
    },
  ];

  const onPageClick = (offset: number, limit: number) => {
    setPagination({ offset, limit });
  };





  const onSearchValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const handleSearchClick = () => {
    if (projectId && updateId) {
      dispatch(getObjectsForUniqueVulnsAsync({ projectId, updateId, search: searchValue, ...pagination, sortBy: getSortString(sortParams) }));
    }
  };
  const handleSearchResetClick = () => {
    if (projectId && updateId && searchValue !== '') {
      dispatch(getObjectsForUniqueVulnsAsync({ projectId, updateId, search: '', ...pagination, sortBy: getSortString(sortParams) }));
      setSearchValue('');
    }
  };
  const handleClickSort = (param: string) => {
    setSortParams(getNextSortParam(param, sortParams));
  };


  return (
    <>
      <Navbar />

      <div className={cx('content')}>
        {isLoading ? <Loader /> : (
          <>
            <div className={cx('content-info')}>
              <div className={styles['vuln-body-info']}>
                <Title className={styles['vuln-body-info-title']} variant={TitleVariantEnum.H3}>
                  {localization.vuln.infoList.title}
                </Title>
                <div className={cx('content-column')}>
                  {infoList.map((item) => (
                    <PageItem
                      key={item.id}
                      {...item}
                      isTextCropped={true}
                      textSize={80}
                    />
                  ))}
                </div>

              </div>
              <div className={styles['vuln-body-info-secondary']}>
                <Title className={styles['vuln-body-info-secondary-title']}
                  variant={TitleVariantEnum.H3}>
                  {localization.common.secondaryInfoTitle}
                </Title>
                <div className={cx('content-column')}>

                  {secondaryInfoList.map((item) => (
                    <PageItem
                      key={item.id}
                      {...item}
                      isTextCropped={true}
                      textSize={80}
                    />
                  ))}
                </div>

              </div>
              <div className={cx('content-column')}>
                <div className={cx('content-column')}>
                  <Text variant={TextVariantEnum.L} className={styles['vuln-panel-bottom-title']}>
                    {localization.vuln.cvssInfo.title}
                  </Text>
                  <div className={cx('content-column')}>
                    {stateCvssItemList.map((item) => (
                      <PageItem
                        {...item}
                        className={cx('')}
                        classNameTitle={styles['vuln-panel-bottom-item-title']}
                        classNameText={styles['vuln-panel-bottom-item-text']}
                        key={item.id}

                      />
                    ))}
                  </div>
                  <div className={cx('content-cvss')}>
                    {cvssList.map((item) => (
                      <PageItem
                        {...item}
                        className={cx('content-cvss-item')}
                        classNameTitle={styles['vuln-panel-bottom-item-title']}
                        classNameText={styles['vuln-panel-bottom-item-text']}
                        key={item.id}
                      />
                    ))}
                  </div>
                </div>
                <div className={cx('content-column')}>
                  <Text variant={TextVariantEnum.L} className={styles['vuln-panel-bottom-title']}>
                    {localization.common.stateInfoTitle}
                  </Text>
                  <div className={styles['vuln-panel-bottom-list']}>
                    {stateInfoList.map((item) => (
                      <PageItem
                        {...item}
                        className={styles['vuln-panel-bottom-item_secondary']}
                        classNameTitle={styles['vuln-panel-bottom-item-title']}
                        classNameText={styles['vuln-panel-bottom-item-text']}
                        key={item.id}
                      />
                    ))}

                  </div>

                </div>
                <div className={styles['content-buttons']}>
                  <PageItem
                    title="Обновлено"
                    text={uniqueVuln?.updated_at || '-'}
                    className={styles['vuln-panel-bottom-item_secondary']}
                    classNameTitle={styles['vuln-panel-bottom-item-title']}
                    classNameText={styles['vuln-panel-bottom-item-text']}
                  />
                  {(role === 'admin' || role === 'pentester' || role === 'teamlead' || uniqueVuln?.owner?.id === id) && (
                    <Button
                      onClick={() => setIsModalVisible(prev => !prev)}
                      buttonText={localization.common.changeButtonText}
                    />
                  )}
                </div>
              </div>
            </div>



            <div className={cx('content-column')}>

              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'start', gap: '10px' }}>
                <Title className={styles['vuln-body-info-title']} variant={TitleVariantEnum.H3}>
                  Объекты  <span style={{ backgroundColor: '#2478F8', padding: '0px 6px', borderRadius: '15px', marginLeft: '5px', color: '#fff', fontSize: '14px' }}> {count}</span>
                </Title>
                <InputForm
                  text={''}
                  placeholder="Поиск..."
                  className={cx('input')}
                  value={searchValue}
                  onChange={onSearchValueChange}
                />
                <Button buttonText={'Поиск'} onClick={handleSearchClick} />
                <Button buttonText={'Сброс'} onClick={handleSearchResetClick} />
              </div>

              {objectsForUniqueVuln &&
                <TableComponent
                  sortParams={sortParams}
                  headersRow={objectsHeader}
                  handleClickSort={handleClickSort}
                  dataList={tableData}
                  isNotLink={true}
                />

              }
              <Pagination
                onPageClick={onPageClick}
                count={count ? count : undefined}
                startLimit={pagination.limit}
              />

            </div>
          </>

        )}
        {isModalVisible && uniqueVuln &&
          <ChangeUniqueVuln
            isModalVisible={true}
            setModalVisible={() => {
              setIsModalVisible(false);
            }}
            currentObjectId={''}
            vulnData={uniqueVuln}
          />
        }
      </div>

      <NotificationPopUp
        text={
          sendDataStatus.isSuccess
            ? 'Уязвимость успешно изменена.'
            : 'Ошибка при изменении уязвимости'
        }
        isSuccess={sendDataStatus.isSuccess}
        isActive={sendDataStatus.isCompleted} />
    </>
  );
};
