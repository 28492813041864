import React, { useEffect, useState } from 'react';

import cnb from 'classnames/bind';

import { SelectList } from '../../shared/ui/SelectList/SelectList';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';
import InputForm from '../../components/inputForm/InputForm';
import { localization } from '../../localization/localization';
import { negativeConsequencesTypes } from '../../constants/vulns';
import Navbar from '../../components/navbar/Navbar';

import { CvssCalculator } from '../../entities';
import { selectTemplates } from '../../store/templates/templateSelector';
import {
  changeStatusTemplate,
  createTemplate,
  deleteTemplate,
  getTemplateById,
  getTemplates,
  updateTemplate, uploadTemplateFromFile,
} from '../../store/templates/templatesAsync';
import { resetTemplate, setCurrentTemplateId, updateTemplateValue } from '../../store/templates/templatesSlice';
import Button from '../../components/button/Button';
import { selectProfileData } from '../../store/auth/authSelectors';
import { useShowPopup } from '../../hooks/useShowPopup';
import { objectTypeHandle, prepareObjectTypesForChangeModalToRu } from '../../constants/objects';
import { ObjectType, RiskLevelType } from '../../store/templates/templatesTypes';
import { NotificationPopUp } from '../../shared/NotificationPopUp/ui/NotificationPopUp';
import { InputTypeEnum } from '../../components/input/InputTypes';

import styles from './TemplatePage.module.scss';

export const TemplatePage = () => {

  const cx = cnb.bind(styles);

  const dispatch = useAppDispatch();

  const { role } = useAppSelector(selectProfileData);
  const { templates, templateById, currentTemplateID, sendStatus } = useAppSelector(selectTemplates);

  const [objectTypeFilterParam, setObjectTypeFilterParam] = useState('');
  const [uploadFileList, setUploadFile] = useState<FileList | null>(null);

  const [CVSSValue, setCVSSValue] = useState<{
    risk_level: RiskLevelType | null;
    cvss_vector: string;
    cvss_score: number;
  }>({
    risk_level: null,
    cvss_vector: '',
    cvss_score: 0,
  });


  const handleCVSSCalculator = (value: { risk_level: RiskLevelType | null; cvss_vector: string; cvss_score: number }) => {
    setCVSSValue(value);
  };
  const onNegativeConsequencesChange = (data: { value: string; label: string }[]) => {
    const prepareNegativeConsequences = data.map(({ value }: any) => value).join(',');
    dispatch(updateTemplateValue({ ...templateById, negative_consequences: prepareNegativeConsequences }));
  };

  useEffect(() => {
    dispatch(getTemplates({}));
    return () => {
      dispatch(resetTemplate());
    };
  }, []);


  const handleChangeSelectId = (id: string) => {
    dispatch(setCurrentTemplateId(id));
  };

  useEffect(() => {
    if (currentTemplateID === 'void') {

      dispatch(resetTemplate());
    } else if (currentTemplateID) {
      dispatch(getTemplateById(currentTemplateID));
    }
  }, [currentTemplateID]);



  useEffect(() => {
    dispatch(getTemplates({ objectType: objectTypeFilterParam }));
  }, [objectTypeFilterParam]);

  const handleChangeFilterParam = (param: string) => {

    setObjectTypeFilterParam(param);
  };

  const { showPopupHandler } = useShowPopup();
  const onChangeHandle = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files;
    if (fileList) {
      setUploadFile(fileList);
    }
  };

  const onClickHandleImport = () => {
    const uploadFile = uploadFileList?.item(0);
    if (uploadFile) {
      dispatch(uploadTemplateFromFile(uploadFile));
      setUploadFile(null);
    }
  };

  return (
    <>
      <Navbar />

      <div className={cx(['template-page'])}>
        <h1 className={cx(['template-page_header'])}>Редактор шаблонов</h1>
        <div>
          <SelectList
            templateList={templates}
            setSelectedTemplateId={handleChangeSelectId}
            selectedTemplateId={currentTemplateID}
            objectTypeFilterValue={objectTypeFilterParam}
            setObjectTypeFilterValue={handleChangeFilterParam}
          />
        </div>
        <div className={cx(['template-page_column'])}>
          <InputForm
            text="Название: "
            placeholder=""
            value={templateById.tpl_name}
            errorMessage=""
            onChange={(event) => {
              dispatch(updateTemplateValue({ ...templateById, tpl_name: event.target.value }));
            }}
            required
            disabled={templateById.status === 'approved'}
          />

          <InputForm
            text="CVE ID (через запятую, например: CVE-1111-1111, CVE-2222-2222): "
            placeholder=""
            value={templateById.cve_id}
            errorMessage=""
            onChange={(event) => {
              dispatch(updateTemplateValue({ ...templateById, cve_id: event.target.value }));
            }}
            disabled={templateById.status === 'approved'}
          />
          <InputForm
            text="CWE ID (через запятую, например: CWE-1, CWE-2): "
            placeholder=""
            value={templateById.cwe_id}
            errorMessage=""
            onChange={(event) => {
              dispatch(updateTemplateValue({ ...templateById, cwe_id: event.target.value }));
            }}
            disabled={templateById.status === 'approved'}
          />
          <InputForm
            text="URL+PORT: "
            placeholder=""
            value={templateById.location}
            errorMessage=""
            onChange={(event) => {
              dispatch(updateTemplateValue({ ...templateById, location: event.target.value }));
            }}
            disabled={templateById.status === 'approved'}
          />
          <InputForm
            text="Описание: "
            placeholder=""
            value={templateById.description}
            errorMessage=""
            onTextareaChange={(event) => {
              dispatch(updateTemplateValue({ ...templateById, description: event.target.value }));
            }}
            textarea
            disabled={templateById.status === 'approved'}
          />
          <InputForm
            text="Причина возникновения: "
            placeholder=""
            value={templateById.cause_of_occurrence}
            errorMessage=""
            onTextareaChange={(event) => {
              dispatch(updateTemplateValue({
                ...templateById,
                cause_of_occurrence: event.target.value,
              }));
            }}
            textarea
            disabled={templateById.status === 'approved'}
          />
          <InputForm
            text="Рекомендации: "
            placeholder=""
            value={templateById.recommendations}
            errorMessage=""
            onTextareaChange={(event) => {
              dispatch(updateTemplateValue({ ...templateById, recommendations: event.target.value }));
            }}
            textarea
            disabled={templateById.status === 'approved'}
          />
          <InputForm
            text="Порядок эксплуатации: "
            placeholder=""
            value={templateById.procedure_exploiting}
            errorMessage=""
            onTextareaChange={(event) => {
              dispatch(updateTemplateValue({
                ...templateById,
                procedure_exploiting: event.target.value,
              }));
            }}
            textarea
            disabled={templateById.status === 'approved'}
          />

          <InputForm
            text={localization.modals.vuln.negativeConsequencesText}
            placeholder={localization.modals.vuln.negativeConsequencesPlaceholder}
            errorMessage={''}
            value={
              templateById.negative_consequences ?
                templateById.negative_consequences?.split(',')
                  .map(el => (
                    {
                      label: el[0]?.toUpperCase() + el.slice(1),
                      value: el,
                    }
                  )) : []
            }
            onSelectChange={onNegativeConsequencesChange}
            options={negativeConsequencesTypes}
            isMulti
            disabled={templateById.status === 'approved'}
          />


          <InputForm
            text="Дополнительная информация: "
            placeholder=""
            value={templateById.additional_info}
            errorMessage=""
            onTextareaChange={(event) => {
              dispatch(updateTemplateValue({ ...templateById, additional_info: event.target.value }));
            }}
            textarea
            disabled={templateById.status === 'approved'}
          />

        </div>
        <div className={cx(['template-page_column'])}>
          <InputForm
            text={'Тип объекта'}
            placeholder={'Тип объекта...'}
            popupItems={objectTypeHandle}
            value={templateById.object_type ? prepareObjectTypesForChangeModalToRu[templateById.object_type] : ''}
            onClick={showPopupHandler}
            onPopupChange={(event: { value: ObjectType; text: string }) => {
              dispatch(updateTemplateValue({ ...templateById, object_type: event.value ? event.value : '' }));
            }
            }
            onChange={() => {

            }}

            disabled={templateById.status === 'approved'}
          />
          <InputForm
            text="Статус: "
            placeholder=""
            value={templateById.status}
            errorMessage=""
            disabled
          />
          <InputForm
            text="CVSS score: "
            placeholder=""
            value={CVSSValue.cvss_score || 0}
            errorMessage=""
            disabled
          />
          <InputForm
            text="Риск: "
            placeholder=""
            value={CVSSValue.risk_level || 'info'}
            errorMessage=""
            disabled
          />
          <InputForm
            text="CVSS вектор: "
            placeholder=""
            value={CVSSValue.cvss_vector || ''}
            errorMessage=""
            disabled
          />

          <CvssCalculator setCVSSValue={handleCVSSCalculator} CVSSVector={templateById.cvss_vector} />
          <div className={cx(['template-page_button'])}>
            {(role === 'teamlead' || role === 'admin') &&
              <>
                <InputForm
                  text={''}
                  placeholder={localization.modals.vuln.screenshotsPlaceholder}
                  type={InputTypeEnum.File}
                  onChange={onChangeHandle}

                  disabled={templateById.status === 'approved'}
                />
                <Button
                  buttonText="Загрузить из файла"
                  onClick={onClickHandleImport}
                  dissable={uploadFileList === null && templateById.status === 'approved'}

                />

              </>
            }
            <div className={cx(['template-page_button-group'])}>
              {role !== 'analyst' &&
                <>
                  <Button
                    buttonText="Изменить статус"
                    onClick={() => {
                      dispatch(changeStatusTemplate({
                        id: templateById.id,
                        status: templateById.status === 'approved' ? 'recheck' : 'approved',
                      }));
                    }
                    }
                    dissable={!templateById.id}
                  />

                  <Button
                    buttonText="Удалить"
                    onClick={() => { dispatch(deleteTemplate(templateById.id)); }}
                    dissable={!templateById.id}
                  />
                  <Button
                    buttonText="Обновить"
                    onClick={() => {
                      dispatch(updateTemplate({
                        ...templateById,
                        cvss_vector: CVSSValue.cvss_vector,
                        cvss_score: CVSSValue.cvss_score.toString(),
                        risk_level: CVSSValue.risk_level,
                        status: 'recheck',
                      }));
                    }}
                    dissable={!templateById.id || templateById.status === 'approved'}
                  />
                  <Button
                    buttonText="Создать"
                    dissable={templateById.status === 'approved' || templateById.tpl_name.length === 0}
                    onClick={() => {
                      dispatch(createTemplate({
                        ...templateById,
                        cvss_vector: CVSSValue.cvss_vector,
                        cvss_score: CVSSValue.cvss_score.toString(),
                        risk_level: CVSSValue.risk_level,
                        status: 'recheck',
                      }));
                    }}
                  />
                </>}
            </div>
          </div>
        </div>
      </div>
      <NotificationPopUp isActive={sendStatus.popupIsActive} text={sendStatus.message} isSuccess={sendStatus.isSuccess} />
    </>
  );
};

