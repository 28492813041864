import {EMPTY_ERROR_MESSAGE, GENERAL_ERROR_MESSAGES, OBJECT_ERROR_MESSAGE} from '../../../constants/errors';
import {
    setOfficeAddressError,
    setOfficeNameError,
    setOfficeResponsibleError,
    setOfficeSecurityLevelError,
} from '../../../store/offices/officesSlice';
import {IOffice} from '../../../store/offices/officesTypes';

/**
 * return is the data valid
 * @param {IOffice} office     Validation data
 * @param {any} dispatch       Dispatcher to change values in the store
 * @param {IOffice[]} offices  Array of offices to validate by name
 * @return {boolean}           Is the data valid
 */
export const validateOffice = (
    office: IOffice,
    dispatch: any,
    offices?: IOffice[],
): boolean => {
    const OfficeNameBusy = offices?.find((item: IOffice) => item.name === office.name && office.id !== item.id);

    if (!office.name) {
        dispatch(setOfficeNameError(GENERAL_ERROR_MESSAGES.FIELD_EMPTY));

        return false;
    } else if (OfficeNameBusy) {
        dispatch(setOfficeNameError(OBJECT_ERROR_MESSAGE.OFFICE_NAME_BUSY));

        return false;
    } else {
        dispatch(setOfficeNameError(EMPTY_ERROR_MESSAGE));

        return true;
    }
};
