import {
    EMPTY_ERROR_MESSAGE,
    GENERAL_ERROR_MESSAGES,
    OBJECT_ERROR_MESSAGE,
} from '../../../constants/errors';
import {IInfSystem} from '../../../store/infSystems/infSystemsTypes';
import {
    setInfSystemContactPersonError,
    setInfSystemNameError,
    setInfSystemProductError,
    setInfSystemProductManagerError,
    setInfSystemSecurityLevelError,
    setInfSystemWebInterfaceAddressError,
} from '../../../store/infSystems/infSystemsSlice';

/**
 * return is the data valid
 * @param {IInfSystem} infSystem     Validation data
 * @param {any} dispatch             Dispatcher to change values in the store
 * @param {IInfSystem[]} infSystems  Array of inf systems to validate by name
 * @return {boolean}                 Is the data valid
 */
export const validateInfSystem = (
    infSystem: IInfSystem,
    dispatch: any,
    infSystems?: IInfSystem[],
): boolean => {
    let isInfSystemNameCorrect;
    let isWebInterfaceAddressCorrect;
    let isProductCorrect;
 
    const infSystemNameBusy =
    infSystems?.find((item: IInfSystem) => item.name === infSystem.name && infSystem.id !== item.id);

    if (!infSystem.name) {
        dispatch(setInfSystemNameError(GENERAL_ERROR_MESSAGES.FIELD_EMPTY));

        isInfSystemNameCorrect = false;
    } else if (infSystemNameBusy) {
        dispatch(setInfSystemNameError(OBJECT_ERROR_MESSAGE.INF_SYSTEM_BUSY));

        isInfSystemNameCorrect = false;
    } else {
        dispatch(setInfSystemNameError(EMPTY_ERROR_MESSAGE));

        isInfSystemNameCorrect = true;
    }

    if (!infSystem.product) {
        dispatch(setInfSystemProductError(GENERAL_ERROR_MESSAGES.FIELD_EMPTY));

        isProductCorrect = false;
    } else {
        dispatch(setInfSystemProductError(EMPTY_ERROR_MESSAGE));

        isProductCorrect = true;
    }

    if (!infSystem.web_interface_address) {
        dispatch(setInfSystemWebInterfaceAddressError(GENERAL_ERROR_MESSAGES.FIELD_EMPTY));

        isWebInterfaceAddressCorrect = false;
    } else {
        dispatch(setInfSystemWebInterfaceAddressError(EMPTY_ERROR_MESSAGE));

        isWebInterfaceAddressCorrect = true;
    }

    return !!(isInfSystemNameCorrect && isWebInterfaceAddressCorrect && isProductCorrect);
};
