import {FC} from 'react';

import cn from 'classnames';

import { textAreaProps } from './TextAreaProps';
import style from './TextArea.module.scss';

export const TextArea:FC<textAreaProps> = (props) => {
    const {placeholderText, labelText, valueText, ...otherProps} = props;
    return (
        <label  className={cn(style['textArea'])}>
            <span className={cn(style['textArea_label'])}>{labelText}</span>
            <textarea 
                className={cn(style['textArea_input'])} 
                placeholder={placeholderText}
                value={valueText ? valueText : '' }
                {...otherProps}
            >
                
            </textarea>
        </label>
    );
};
