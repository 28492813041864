import {FC, useEffect, useState} from 'react';

import classNames from 'classnames';

import {useParams} from 'react-router-dom';

import styles from '../../Modal.module.scss';
import Button from '../../../button/Button';
import Modal from '../../Modal';
import Text from '../../../text/Text';
import {useAppDispatch} from '../../../../hooks/useAppDispatch';

import {ROLES_ERROR_MESSAGES} from '../../../../constants/errors';
import {deletePentester, getObjectById} from '../../../../store/objects/objectsAsync';
import {IPentesterModalProps} from '../PentesterTypes';
import {IUser} from '../../../../store/auth/authTypes';
import {setPentesterAppointed} from '../../../../store/objects/objectsSlice';
import {useAppSelector} from '../../../../hooks/useAppSelector';
import {selectObjects} from '../../../../store/objects/objectsSelectors';
import {localization} from '../../../../localization/localization';

const DeletePentester: FC<IPentesterModalProps> = ({
    isModalVisible,
    setModalVisible,
    selectTab,
    pentestersIds,
}) => {
    const dispatch = useAppDispatch();

    const { projectId, objectId } = useParams();

    const { isPentesterAppointed } = useAppSelector(selectObjects);

    useEffect(() => {
        setPentesters(pentestersIds || []);
        setPentesterIds([]);
    }, [pentestersIds]);

    useEffect(() => {
        if (projectId && objectId && isPentesterAppointed) {
            dispatch(getObjectById({ projectId, objectId, objectType: selectTab }));
            dispatch(setPentesterAppointed(false));
        }
    }, [dispatch, objectId, projectId, selectTab, isPentesterAppointed]);

    const [pentesterIds, setPentesterIds] = useState<string[]>([]);
    const [pentesters, setPentesters] = useState<IUser[]>(pentestersIds || []);
    const [isTeamleadIdCorrect, setPentesterIdCorrect] = useState<boolean>(true);

    const changeObjectPentesters = () => {
        setPentesterIdCorrect(pentesterIds.length >= 1);

        if (pentesterIds.length >= 1 && projectId && objectId) {
            let preparePentesterIds = '';

            pentesterIds.forEach((pentesterId) => {
                preparePentesterIds = preparePentesterIds + `&pentester_ids=${pentesterId}`;
            });

            dispatch(deletePentester({ projectId, objectId, pentesterIds: preparePentesterIds }));

            setModalVisible(false);
        }
    };

    const onPentesterClick = (id: string) => {
        if (pentesterIds.length === 0) {
            return setPentesterIds([id]);
        }

        const searchPentesterId = pentesterIds.find(pentesterId => pentesterId === id);

        return searchPentesterId ? setPentesterIds(pentesterIds.filter(pentesterId => pentesterId !== searchPentesterId)) : setPentesterIds([...pentesterIds, id]);
    };

    return (
        <Modal
            classNameContent={styles['modal-users-wrapper']}
            isModalVisible={isModalVisible}
            setModalVisible={setModalVisible}
            title={localization.modals.roles.deletePentesterTitle}
        >
            <div className={styles['modal-users']}>
                {pentesters.map(({ id, email, first_name }) => (
                    <div
                        key={id}
                        onClick={() => onPentesterClick(id)}
                        className={classNames(styles['modal-user'], { [styles['modal-user_active']]: pentesterIds.find(pentesterId => pentesterId === id) })}
                    >
                        <Text className={styles['modal-user-email']}>{`${first_name} (${email})`}</Text>
                    </div>
                ))}
            </div>
            <div className={styles['modal-buttons']}>
                <Button buttonText={localization.common.deleteButtonText} onClick={changeObjectPentesters}/>
                {!isTeamleadIdCorrect && (
                    <Text className={styles['modal-user-error']}>{ROLES_ERROR_MESSAGES.PENTESTER_EMPTY}</Text>
                )}
            </div>
        </Modal>
    );
};

export default DeletePentester;
