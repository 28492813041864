import {FC, useEffect, useState} from 'react';

import classNames from 'classnames';

import {useParams} from 'react-router-dom';

import styles from '../../Modal.module.scss';
import Button from '../../../button/Button';
import Modal from '../../Modal';
import {useAppSelector} from '../../../../hooks/useAppSelector';
import Text from '../../../text/Text';
import {useAppDispatch} from '../../../../hooks/useAppDispatch';

import {EMPTY_ERROR_MESSAGE, ROLES_ERROR_MESSAGES} from '../../../../constants/errors';
import {createPentester, createPentesters, getObjectById, getPentesters} from '../../../../store/objects/objectsAsync';
import {IPentesterModalProps} from '../PentesterTypes';
import {selectObjects} from '../../../../store/objects/objectsSelectors';
import {ModalTypes} from '../../general/confirm/ConfirmTypes';
import ConfirmModal from '../../general/confirm/ConfirmModal';
import {setPentesterAppointed} from '../../../../store/objects/objectsSlice';
import {localization} from '../../../../localization/localization';

const ChangePentester: FC<IPentesterModalProps> = ({
    isModalVisible,
    setModalVisible,
    selectTab,
    pentestersIds,
    activeRowIds,
}) => {
    const dispatch = useAppDispatch();

    const { projectId, objectId } = useParams();

    const { pentesters, isPentesterAppointed } = useAppSelector(selectObjects);

    useEffect(() => {
        const preparePentesterIds = pentestersIds?.map(pentestersId => pentestersId.id) || [];

        setPentesterIds([...preparePentesterIds]);
    }, [pentestersIds]);

    const [pentesterIds, setPentesterIds] = useState<string[]>([]);
    const [isPentesterIdCorrect, setPentesterIdCorrect] = useState<boolean>(true);
    const [error, setError] = useState<string>('');

    const [isAppointPentesterModal, setAppointPentesterModal] = useState<boolean>(false);

    useEffect(() => {
        dispatch(getPentesters(projectId || ''));
    }, [dispatch, projectId]);

    useEffect(() => {
        if (projectId && objectId && isPentesterAppointed) {
            dispatch(getObjectById({ projectId, objectId, objectType: selectTab }));
            dispatch(setPentesterAppointed(false));
        }
    }, [dispatch, projectId, objectId, selectTab, isPentesterAppointed]);

    const onAppointPentesterHandler = () => setAppointPentesterModal(prevState => !prevState);

    const changeObjectPentesters = () => {
        if (pentesterIds.length === 0) {
            setPentesterIdCorrect(false);
            setError(ROLES_ERROR_MESSAGES.PENTESTER_EMPTY);
        } else if (activeRowIds?.length === 0 && !objectId) {
            setPentesterIdCorrect(false);
            setError(ROLES_ERROR_MESSAGES.OBJECTS_EMPTY);
        } else {
            if (projectId) {
                let preparePentesterIds = '';

                pentesterIds.forEach((pentesterId) => {
                    preparePentesterIds = preparePentesterIds + `&pentester_ids=${pentesterId}`;
                });

                if (objectId) {
                    dispatch(createPentester({ projectId, objectId, pentesterIds: preparePentesterIds }));
                } else {
                    let prepareActiveRow = '';

                    activeRowIds?.forEach((activeRowId) => {
                        prepareActiveRow = prepareActiveRow + `&object_ids=${activeRowId}`;
                    });

                    dispatch(createPentesters({ projectId, objectIds: prepareActiveRow, pentesterIds: preparePentesterIds }));
                }

                setModalVisible(false);
            }

            setPentesterIdCorrect(true);
            setError(EMPTY_ERROR_MESSAGE);
        }
    };

    const onPentesterClick = (id: string) => {
        if (pentesterIds.length === 0) {
            return setPentesterIds([id]);
        }

        const searchPentesterId = pentesterIds.find(pentesterId => pentesterId === id);

        return searchPentesterId ? setPentesterIds(pentesterIds.filter(pentesterId => pentesterId !== searchPentesterId)) : setPentesterIds([...pentesterIds, id]);
    };

    return (
        <>
            <Modal
                classNameContent={styles['modal-users-wrapper']}
                isModalVisible={isModalVisible}
                setModalVisible={setModalVisible}
                title={localization.modals.roles.appointPentesterTitle}
            >
                <div className={styles['modal-users']}>
                    {pentesters.map(({ id, email, first_name }) => (
                        <div
                            key={id}
                            onClick={() => onPentesterClick(id)}
                            className={classNames(styles['modal-user'], { [styles['modal-user_active']]: pentesterIds.find(pentesterId => pentesterId === id) })}
                        >
                            <Text className={styles['modal-user-email']}>{`${first_name} (${email})`}</Text>
                        </div>
                    ))}
                </div>
                <div className={styles['modal-buttons']}>
                    <Button buttonText={localization.modals.appointButtonText} onClick={onAppointPentesterHandler}/>
                    {!isPentesterIdCorrect && (
                        <Text className={styles['modal-user-error']}>{error}</Text>
                    )}
                </div>
            </Modal>
            <ConfirmModal
                isModalVisible={isAppointPentesterModal}
                setModalVisible={setAppointPentesterModal}
                text={pentesterIds.length === 1 ?
                    localization.object.confirmPentesterText : localization.object.confirmPentestersText}
                onConfirmClick={changeObjectPentesters}
                type={ModalTypes.Appoint}
            />
        </>
    );
};

export default ChangePentester;
