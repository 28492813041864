import {createAsyncThunk} from '@reduxjs/toolkit';

import {IGetOptionalRequest, IThunkApiConfigProps} from '../storeTypes';
import {CUSTOMER_ROUTES} from '../../constants/customer';
import {INF_SYSTEMS_URL} from '../../constants/infSystems';

import {
    IChangeInfSystemRequest,
    ICreateInfSystemRequest,
    IDeleteInfSystemResponse,
    IGetInfSystemResponse,
    IInfSystem,
    IInfSystemByIdRequest,
    IInfSystemResponse,
    InfSystemsTypes,
} from './infSystemsTypes';

export const getInfSystems = createAsyncThunk<IGetInfSystemResponse, IGetOptionalRequest, IThunkApiConfigProps>(
    InfSystemsTypes.GET,
    async ({ id, filters , pagination, sortParams }, { rejectWithValue, extra }) => {
        try {
            const paginationString = pagination ? `offset=${pagination.offset}&limit=${pagination.limit}` : '';
            const response = await extra.api.get(
                `${CUSTOMER_ROUTES.FULL_URL}/${id}/${INF_SYSTEMS_URL}/?${paginationString}${filters ? `&${filters}` : ''}&${sortParams ? sortParams : ''}`,
            );

            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.status);
        }
    },
);

export const getAllInfSystems = createAsyncThunk<IGetInfSystemResponse, IGetOptionalRequest, IThunkApiConfigProps>(
    InfSystemsTypes.GET_ALL,
    async ({ filters, id }, { rejectWithValue, extra }) => {
        try {
            const response = await extra.api.get(
                `${CUSTOMER_ROUTES.FULL_URL}/${id}/${INF_SYSTEMS_URL}/${filters ? `?${filters}` : ''}`);

            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.status);
        }
    },
);

export const getInfSystemById = createAsyncThunk<IInfSystem, IInfSystemByIdRequest, IThunkApiConfigProps>(
    InfSystemsTypes.GET_ONE,
    async ({ customerId, infSystemId }, { rejectWithValue, extra }) => {
        try {
            const response = await extra.api.get(
                `${CUSTOMER_ROUTES.FULL_URL}/${customerId}/${INF_SYSTEMS_URL}/${infSystemId}`);

            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.status);
        }
    },
);

export const createInfSystem = createAsyncThunk<IInfSystemResponse, ICreateInfSystemRequest, IThunkApiConfigProps>(
    InfSystemsTypes.POST,
    async ({ customerId, infSystem }, { rejectWithValue, extra }) => {
        try {
            const response = await extra.api.post(
                `${CUSTOMER_ROUTES.FULL_URL}/${customerId}/${INF_SYSTEMS_URL}/`,
                infSystem,
            );

            return { infSystem: response.data, status: response.status };
        } catch (error: any) {
            return rejectWithValue(error.response.status);
        }
    },
);

export const deleteInfSystem = createAsyncThunk<IDeleteInfSystemResponse, IInfSystemByIdRequest, IThunkApiConfigProps>(
    InfSystemsTypes.DELETE,
    async ({ customerId, infSystemId }, { rejectWithValue, extra }) => {
        try {
            const response = await extra.api.delete(
                `${CUSTOMER_ROUTES.FULL_URL}/${customerId}/${INF_SYSTEMS_URL}/${infSystemId}`,
            );

            return { id: infSystemId, status: response.status };
        } catch (error: any) {
            return rejectWithValue(error.response.status);
        }
    },
);

export const changeInfSystem = createAsyncThunk<IInfSystemResponse, IChangeInfSystemRequest, IThunkApiConfigProps>(
    InfSystemsTypes.PATCH,
    async ({ customerId, infSystemId, infSystem }, { rejectWithValue, extra }) => {
        try {
            const response = await extra.api.patch(
                `${CUSTOMER_ROUTES.FULL_URL}/${customerId}/${INF_SYSTEMS_URL}/${infSystemId}`,
                infSystem,
            );

            return { infSystem: response.data, status: response.status };
        } catch (error: any) {
            return rejectWithValue(error.response.status);
        }
    },
);
