import React, {ComponentProps, FC} from 'react';
import {Link} from 'react-router-dom';

import cn from  'classnames/bind';

import styles from './TableComponent.module.scss';

interface TableComponentProps<T> extends ComponentProps<'table'>{
    sortParams?: sortListType;
    selectedRows?: {[id: string]: boolean};
    setSelectedRows?: (action: 'add' | 'del', id?: string,) => void;
    headersRow: {[headerName: string]: {
        label: string;
        isUnsorted?: boolean;
    };
    };
    handleClickSort: (param: string) => void;
    baseUrl?:  any;
    dataList?: T[] ;
    isCheckbox?: boolean;
    isNotLink?: boolean;
}

type sortListType = {[headerName: string]: 'none' | 'up' | 'down'}
type TableComponentType<T = any> = FC<TableComponentProps<T>>

export const TableComponent: TableComponentType = (props) => {
    const {
        sortParams,
        selectedRows,
        setSelectedRows,
        handleClickSort,
        headersRow,
        baseUrl = '',
        dataList,
        isCheckbox,
        isNotLink,
        ...otherProps
    } = props;


    const handleClickCheckbox = (event: React.MouseEvent<HTMLInputElement, MouseEvent>, id?: string) => {
        if(setSelectedRows){
            if(id === undefined){
                if(event.currentTarget.checked){
                    setSelectedRows('add');
                }else {
                    setSelectedRows('del');
                }
            }else {
                if(event.currentTarget.checked){
                    setSelectedRows('add', id);
                }else {
                    setSelectedRows('del', id);
                }
            }
        }
    };

    const cx = cn.bind(styles);
    return (
        <table {...otherProps} className={cx('table')}>
            <thead>
                <tr className={cx('table-row')}>
                    {isCheckbox &&
                <th key={'checkbox'} className={cx( 'table-cell',  'table-cell_checkbox', 'table-cell_header__background') }>
                    <input
                        onClick={ event => handleClickCheckbox(event) }
                        type="checkbox"
                        checked={selectedRows && dataList?.length != 0 ? Object.keys(selectedRows).filter(el => selectedRows[el]).length === dataList?.length : false}
                    />
                </th>
                    }
                    {Object.keys(headersRow).map(header => (
                        <th
                            key={header}
                            onClick={!headersRow[header].isUnsorted ? () => handleClickSort(header) : ()=>{}}
                            className={
                                cx(
                                    styles['table-cell'],
                                    styles['table-cell_header'],
                                    styles['table-cell_header__background'],
                                    {'table-cell_header__pointer' : !headersRow[header].isUnsorted},
                                )
                            }

                        >
                            {headersRow[header].label}
                            {
                                sortParams && (!sortParams[header] || sortParams[header] === 'none') && !headersRow[header].isUnsorted
                            && (<img src="/assets/icons/sort.svg" alt="<>" className={cx('table-cell_image')}/>)
                            }
                            {
                                sortParams && (sortParams[header] === 'up') && !headersRow[header].isUnsorted
                            && (<img src="/assets/icons/sort-up.svg" alt=">" className={cx('table-cell_image')}/>)
                            }
                            {
                                sortParams &&  (sortParams[header] === 'down') && !headersRow[header].isUnsorted
                            && (<img src="/assets/icons/sort-down.svg" alt=">" className={cx('table-cell_image')}/>)
                            }
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {dataList?.map((row, index) => (
                    <tr  key={row.id} className={cx('table-row', 'table-row__alert', {'table-cell__bg-dark': index % 2 !== 0})}>
                        {isCheckbox &&
                        <td className={cx('table-cell', 'table-cell_checkbox')}>
                            <input
                                checked={selectedRows ?  selectedRows[row.id] : false}
                                onClick={
                                    event => handleClickCheckbox(event, row.id)
                                }
                                type="checkbox"
                            />
                        </td>
                        }
                        {
                            Object.keys(headersRow).map(header => (
                                <td
                                    key={row.id + header}
                                    className={cx(
                                        'table-cell',
                                        {
                                            'table-cell__alert': row.is_important,

                                        }
                                        )
                                }
                                >
                                    {isNotLink ?
                                        <>
                                            { row[header] ? row[header] :
                                                typeof row[header] === 'number' ? '0': '-'
                                            }
                                        </>
                                        :
                                        <Link
                                            to={`${baseUrl ? baseUrl(row) + '/' : ''}${row.id}`}
                                            className={cx('table_link')}
                                        >
                                            { row[header] ? row[header] :typeof row[header] === 'number' ? '0': '-' }
                                        </Link>
                                    }
                                </td>
                            ))
                        }
                    </tr>
                ))}
            </tbody>
        </table>
    );
};


