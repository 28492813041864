import {WidthType} from 'docx';

import {ExportTypes, ITableBodyDocx, ITableCellDocx, ITableRowDocx} from '../../../utils/export/ExportTypes';
import {exportExcel} from '../../../utils/export/excel/exportExcel';
import {exportDOCX} from '../../../utils/export/docx/exportDOCX';
import {FULL_DATE_TODAY} from '../../../constants/date';
import {exportPDF} from '../../../utils/export/pdf/exportPDF';
import {prepareNegativeConsequencesToRu, vulnHeadCells} from '../../../constants/vulns';
import {IVuln, IVulnMini} from '../../../store/vulns/vulnsTypes';
import {preparedMultiSelectData} from '../../../utils/prepare/preparedMultiSelectData';
import {localization} from '../../../localization/localization';

/**
 *
 * @param {ExportTypes} type     Export type
 * @param {IVuln[]} objectVulns  Array of vulns to collect data
 */
export const exportVulns = (type: ExportTypes, objectVulns: IVulnMini[]) => {
    const fileName = `${localization.vuln.filename} (${FULL_DATE_TODAY})`;

    const vulnHeadersPDF = vulnHeadCells.map(customerCell => customerCell.text);
    const vulnHeaders: ITableCellDocx[] = vulnHeadCells.map(cell => {
        return {
            size: 1800,
            text: cell.text || '',
        };
    });

    vulnHeadersPDF.shift();
    vulnHeaders.shift();

    const prepareVulnsBodyPDF = objectVulns.map(({
        status,
        description,
        created_at,
        name,
    }) => {
        return  {
            name: name ? name : '-',
            description: description ? description : '-',
            status: status ? status : '-',
            created_at: created_at ? new Date(created_at).toLocaleDateString() : '-',
      
        };
    });

    const vulnsBodyPDF = prepareVulnsBodyPDF.map((vuln) => Object.values(vuln));

    const prepareVulnsBody: ITableRowDocx[] = objectVulns.map(({
        status,
        created_at,
        description,
        name,
    }) => {
        const vuln = {
            name: name ? name : '-',
            status: status ? status : '-',
            description: description ? description : '-',
            created_at: created_at ? new Date(created_at).toLocaleDateString() : '-',
      
        };

        return {
            cells: Object.values(vuln).map((text) => {
                return {
                    size: 900,
                    text,
                };
            }),
        };
    });

    const vulnsBody: ITableBodyDocx = {
        rows: prepareVulnsBody,
    };


    switch (type) {
    case ExportTypes.PDF: {
        return exportPDF('landscape', fileName,
            [{ title: localization.vuln.filename, headers: vulnHeadersPDF, body: vulnsBodyPDF }], 'auto',
        );
    }

    case ExportTypes.DOCX: {
        return exportDOCX(fileName, {
            title: localization.vuln.filename,
            headers: vulnHeaders,
            body: vulnsBody,
        }, WidthType.DXA);
    }

    case ExportTypes.EXCEL: {
        return exportExcel({
            headers: vulnHeaders,
            title: localization.vuln.filename,
            body: vulnsBody,
        }, 150);
    }
    }
};
