import { useRef, useEffect, ReactNode, ComponentProps, FC } from 'react';

import { Fancybox as NativeFancybox } from '@fancyapps/ui';
import { ComponentOptionsType as FancyboxOptionsType } from '@fancyapps/ui/types/Fancybox/options';
import '@fancyapps/ui/dist/fancybox/fancybox.css';

interface FancyboxProps extends ComponentProps<'div'> {
  children?: ReactNode;
  delegate?: string;
  options?: Partial<FancyboxOptionsType>;
}

const Fancybox: FC<FancyboxProps> = (props) => {
  const containerRef = useRef(null);
  const { children, ...otherProps
  } = props
  useEffect(() => {
    const container = containerRef.current;

    const delegate = props.delegate || '[data-fancybox]';
    const options = props.options || {};

    NativeFancybox.bind(container, delegate, options);

    return () => {
      NativeFancybox.unbind(container);
      NativeFancybox.close();
    };
  });

  return <div ref={containerRef} {...otherProps}>{children}</div>;
};

export default Fancybox;
